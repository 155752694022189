  import React, { useEffect, useState } from 'react';
  import Slider from "react-slick";
  import "slick-carousel/slick/slick.css";
  import "slick-carousel/slick/slick-theme.css";


  export default function ReviewsCounterSlider() {
      const settings = {
          infinite: false,
          autoplay: false,
          arrows: false,
          dots: false,
          autoplaySpeed: 4000,
          speed: 1000,
          slidesToShow: 6,
          slidesToScroll: 1,
          responsive: [
              {
                  breakpoint: 992,
                  settings: {
                      slidesToShow: 4,
                  }
              }, {
                  breakpoint: 768,
                  settings: {
                      slidesToShow: 4,
                  }
              }, {
                  breakpoint: 576,
                  settings: {
                      slidesToShow: 2,
                      dots: true,
                  }
              },
          ]
      };

      return (
          <Slider className='' {...settings}>

                <div className="counter-content-box">
                    <h3>85%</h3>
                    <p>Client Satisfaction</p>
                </div>

                <div className="counter-content-box">
                    <h3>720+</h3>
                    <p>Successfully Written Books</p>
                </div>

                <div className="counter-content-box">
                    <h3>260+</h3>
                    <p>Bestselling Novels</p>
                </div>

                <div className="counter-content-box">
                    <h3>400+</h3>
                    <p>Reviews from Goodreads</p>
                </div>

                <div className="counter-content-box">
                    <h3>700+</h3>
                    <p>Clients We’ve Helped</p>
                </div>

          </Slider>
      );
  }
