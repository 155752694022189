import React, { useState, useEffect } from 'react';
// import './index.css'; 

const CountDown = () => {
  const calculateTimeLeft = () => {
    const now = new Date();
    const endTime = new Date(localStorage.getItem('endTime') || now.getTime() + 2 * 60 * 60 * 1000);
    const difference = endTime - now;

    if (difference <= 0) {
      return { hours: 0, minutes: 0, seconds: 0 };
    }

    return {
      hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((difference % (1000 * 60)) / 1000),
    };
  };

  useEffect(() => {
    const now = new Date();
    const endTime = new Date(now.getTime() + 2 * 60 * 60 * 1000);
    localStorage.setItem('endTime', endTime);
  }, []);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const { hours, minutes, seconds } = timeLeft;

  return (
    <div className="countdown-container">
      <div className="time-unit">
        <div className="circle">
          <svg>
            <circle cx="50%" cy="50%" r="30"></circle>
            <circle
              cx="50%"
              cy="50%"
              r="30"
              className="hours-circle"
              style={{
                strokeDashoffset: `calc(189 - (189 * ${hours}) / 24)`,
              }}
            ></circle>
          </svg>
          <div className="time-value">{String(hours).padStart(2, '0')}</div>
          <div className="time-label">Hours</div>
        </div>
      </div>

      <div className="time-unit">
        <div className="circle">
          <svg>
            <circle cx="50%" cy="50%" r="30"></circle>
            <circle
              cx="50%"
              cy="50%"
              r="30"
              className="minutes-circle"
              style={{
                strokeDashoffset: `calc(189 - (189 * ${minutes}) / 60)`,
              }}
            ></circle>
          </svg>
          <div className="time-value">{String(minutes).padStart(2, '0')}</div>
          <div className="time-label">Minutes</div>
        </div>
      </div>

      <div className="time-unit">
        <div className="circle">
          <svg>
            <circle cx="50%" cy="50%" r="30"></circle>
            <circle
              cx="50%"
              cy="50%"
              r="30"
              className="seconds-circle"
              style={{
                strokeDashoffset: `calc(189 - (189 * ${seconds}) / 60)`,
              }}
            ></circle>
          </svg>
          <div className="time-value">{String(seconds).padStart(2, '0')}</div>
          <div className="time-label">Seconds</div>
        </div>
      </div>
    </div>
  );
};

export default CountDown;
