import { book1, book2, book3, book4, book5, book6, book7, cryptoinsights } from '../../assets/images'
import PortfolioSlider from '../../Components/PortfolioSlider'
// import './index.css'

export default function Portfolio() {
    return (
        <>
            <section className="portfolio-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <h2 className="font-weight-bold text-center">
                            We Have Helped Thousands Of Writers Get Their Work Published
                            </h2>
                            <p className="text-center">Our talented team has many skills, allowing us to work in different genres. We cater to the writing needs of clients from various fields and backgrounds, ensuring every writer gets the support they need to succeed.</p>
                        </div>
                    </div>
                    <PortfolioSlider/>
                </div>
            </section>
        </>
    )
}