import { WEBSITE_NAME } from '../../config';
import { 
    TrustPilotImg,
    ClutchImg,
    GoodfirmImg,
 } from './../../assets/images';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useChat } from '../../ChatContext';
import { useModal } from '../../ModalContext';
import './index.css';
import InnerBnrForm from '../../components/InnerBnrForm';
import HomeBnrForm from '../../components/HomeBnrForm';

export default function MainBanner({data}) {
    const { toggleChat } = useChat();
    const { openModal } = useModal();
    const actionHandlers = {
        openModal,
        toggleChat
    };
    const location = useLocation(); 
    const isHomePage = location.pathname === '/' || location.pathname === '';
    const replacePlaceholders = (text) => {
        if (!text || !text.includes('{{WEBSITE_NAME}}')) {
            // If text is undefined/null or does not contain the placeholder, return it as is
            return text;
        }
        // Replace {{WEBSITE_NAME}} if found in text
        return text.replace(/{{WEBSITE_NAME}}/g, WEBSITE_NAME);
    };
    const pageName = location.pathname.replace('/', '').replace(/\//g, '-');
    const bannerClassName = `main_banner${isHomePage ? '' : ` ${pageName}`}`;
    return (
        <section className={bannerClassName}>
            <div className='container'>
            <div className='row align-items-center'>
                <div className='col-lg-6 banner_content'>
                <h1 className='text_clr_light'>{replacePlaceholders(data.banner.title)}</h1>
                <p>{replacePlaceholders(data.banner.description)}</p>
                <div className='btn_wrap'>
                    {data.banner.ctaButtons.map((button, index) => {
                                const handleClick = actionHandlers[button.handleClick];
                                return (
                                    <a
                                        key={index}
                                        onClick={handleClick}
                                        href={button.link}
                                        className={`default_btn ${button.className}`}
                                    >
                                        {button.text}
                                    </a>
                                );
                            })}
                </div>
                <ul className='platforms_logo'>
                    <li><img src={TrustPilotImg} alt='Trustpilot' /></li>
                    <li><img src={GoodfirmImg} alt='GoodfirmImg' /></li>
                    <li><img src={ClutchImg} alt='ClutchImg' /></li>
                </ul>
                </div>
                <div className='col-lg-6'>
                    {isHomePage ? (
                        <HomeBnrForm />  // Display this component only on homepage
                    ) : (
                        <InnerBnrForm />  // Display this component only on other pages
                    )}
                </div>
            </div>
            </div>
        </section>
    );
}
