import { customerstisfaction, moneyback, mostsold } from '../../assets/images'
import PackagesSlider from '../../Components/PackagesSlider'
import { PHONE, PHONE_HREF } from '../../config'
// import './index.css'

export default function Packages() {
    return (
        <>
            <section className="packages-sec py-5">
                <div className="container">
                    <h2 className="text-center font-weight-bold">
                        <small className="d-block">OUR</small>BUNDLE PACKAGES
                    </h2>
                    <PackagesSlider/>   
                    {/* <div className='row packages-responsive-slider mt-5'>
                        <div className='col-md-4'>
                            <div className='package-box'>
                                <h3>Basic</h3>
                                <div class="price">
                                    <h2>$499</h2>
                                    <span><em>$1,000 </em> <br /> 30% off !</span>
                                </div>
                                <ul className='details'>
                                    <li>editing</li>
                                    <li>formatting</li>
                                    <li>typesetting</li>
                                    <li>proofreading</li>
                                    <li>layout adjustment</li>
                                    <li>100% ownership rights</li>
                                    <li>unlimited revisions</li>
                                    <li>publication on Kindle</li>
                                    <li>eBook Format</li>
                                </ul>
                                <div className='pkg-images mb-2'>
                                    <div className='pkg-img-1'>
                                        <img src={customerstisfaction} alt='' className='' />
                                    </div>
                                    <div className='pkg-img-2'>
                                        <img src={moneyback} alt='' className='' />
                                    </div>
                                </div>
                                <a href='Javascript:;' className='theme-btn'>
                                    ORDER NOW
                                </a>
                                <a href='Javascript:;' className='chat theme-btn'>
                                    LIVE CHAT
                                </a>
                                <div className='mt-4'>
                                    <a href={PHONE_HREF} className='pkg-phone-btn'>
                                        Call us at : {PHONE}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='package-box'>
                                <div className='best-seller-img'>
                                    <img src={mostsold} alt='' className='img-fluid lazyload' />
                                </div>
                                <h3>Startup</h3>
                                <div class="price">
                                    <h2>$999</h2>
                                    <span><em>$2,000 </em> <br /> 30% off !</span>
                                </div>
                                <ul className='details'>
                                    <li>editing</li>
                                    <li>formatting</li>
                                    <li>typesetting</li>
                                    <li>proofreading</li>
                                    <li>layout adjustment</li>
                                    <li>100% ownership rights</li>
                                    <li>unlimited revision</li>
                                    <li>publication on Amazon, Kindle</li>
                                    <li>Book cover design</li>
                                    <li>eBook, Paper back Format </li>
                                </ul>
                                <div className='pkg-images mb-2'>
                                    <div className='pkg-img-1'>
                                        <img src={customerstisfaction} alt='' className='' />
                                    </div>
                                    <div className='pkg-img-2'>
                                        <img src={moneyback} alt='' className='' />
                                    </div>
                                </div>
                                <a href='Javascript:;' className='theme-btn'>
                                    ORDER NOW
                                </a>
                                <a href='Javascript:;' className='chat theme-btn'>
                                    LIVE CHAT
                                </a>
                                <div className='mt-4'>
                                    <a href={PHONE_HREF} className='pkg-phone-btn'>
                                        Call us at : {PHONE}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='package-box'>
                                <h3>Standard</h3>
                                <div class="price">
                                    <h2>$1,799</h2>
                                    <span><em>$3,600 </em> <br /> 30% off !</span>
                                </div>
                                <ul className='details'>
                                    <li>editing</li>
                                    <li>formatting</li>
                                    <li>typesetting</li>
                                    <li>proofreading</li>
                                    <li>layout adjustment</li>
                                    <li>100% ownership rights</li>
                                    <li>unlimited revision</li>
                                    <li>ISBN</li>
                                    <li>Book cover design </li>
                                    <li>Publication on Amazon, Kindle, Barnes &amp; Noble &amp; Google Books</li>
                                    <li>eBook, Paper back, Hardcover (3 formats of your book)</li>
                                </ul>
                                <div className='pkg-images mb-2'>
                                    <div className='pkg-img-1'>
                                        <img src={customerstisfaction} alt='' className='' />
                                    </div>
                                    <div className='pkg-img-2'>
                                        <img src={moneyback} alt='' className='' />
                                    </div>
                                </div>
                                <a href='Javascript:;' className='theme-btn'>
                                    ORDER NOW
                                </a>
                                <a href='Javascript:;' className='chat theme-btn'>
                                    LIVE CHAT
                                </a>
                                <div className='mt-4'>
                                    <a href={PHONE_HREF} className='pkg-phone-btn'>
                                        Call us at : {PHONE}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row packages-responsive-slider'>
                        <div className='col-md-4'>
                            <div className='package-box'>
                                <div className='best-seller-img'>
                                    <img src={mostsold} alt='' className='img-fluid lazyload' />
                                </div>
                                <h3>Professional</h3>
                                <div class="price">
                                    <h2>$2,399</h2>
                                    <span><em>$4,800 </em> <br /> 30% off !</span>
                                </div>
                                <p>Easy Installments Available</p>
                                <ul className='details'>
                                    <li>editing</li>
                                    <li>formatting</li>
                                    <li>typesetting</li>
                                    <li>proofreading</li>
                                    <li>layout adjustment</li>
                                    <li>100% ownership rights</li>
                                    <li>unlimited revision</li>
                                    <li>Book cover design </li>
                                    <li>ISBN</li>
                                    <li>30-60 seconds book video trailer</li>
                                    <li>publication on Amazon, Kindle, Barnes &amp; Noble, Google Books &amp; KOBO</li>
                                    <li>eBook, Paper back, Hardcover (3 formats of your book)</li>
                                </ul>
                                <div className='pkg-images mb-2'>
                                    <div className='pkg-img-1'>
                                        <img src={customerstisfaction} alt='' className='' />
                                    </div>
                                    <div className='pkg-img-2'>
                                        <img src={moneyback} alt='' className='' />
                                    </div>
                                </div>
                                <a href='Javascript:;' className='theme-btn'>
                                    ORDER NOW
                                </a>
                                <a href='Javascript:;' className='chat theme-btn'>
                                    LIVE CHAT
                                </a>
                                <div className='mt-4'>
                                    <a href={PHONE_HREF} className='pkg-phone-btn'>
                                        Call us at : {PHONE}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='package-box'>
                                <h3>Brand</h3>
                                <div class="price">
                                    <h2>$7,699</h2>
                                    <span><em>$23,000</em> <br /> 65% off </span>
                                </div>
                                <p>Easy Installments Available</p>
                                <ul className='details'>
                                    <li>editing</li>
                                    <li>formatting</li>
                                    <li>typesetting</li>
                                    <li>proofreading</li>
                                    <li>layout adjustment</li>
                                    <li>100% ownership rights</li>
                                    <li>unlimited revision</li>
                                    <li>publication on Amazon, Kindle, Barnes &amp; Noble, Google Books &amp; Kobo </li>
                                    <li>2-4 pages author website</li>
                                    <li>1-year domain and hosting</li>
                                    <li>Dedicated team of editor’s</li>
                                    <li>ISBN</li>
                                    <li>Book cover design</li>
                                    <li>30-60 seconds book video trailer</li>
                                    <li>Brand Marketing</li>
                                    <li>eBook, Paper back, Hardcover (3 formats of your book)</li>
                                </ul>
                                <div className='pkg-images mb-2'>
                                    <div className='pkg-img-1'>
                                        <img src={customerstisfaction} alt='' className='' />
                                    </div>
                                    <div className='pkg-img-2'>
                                        <img src={moneyback} alt='' className='' />
                                    </div>
                                </div>
                                <a href='Javascript:;' className='theme-btn'>
                                    ORDER NOW
                                </a>
                                <a href='Javascript:;' className='chat theme-btn'>
                                    LIVE CHAT
                                </a>
                                <div className='mt-4'>
                                    <a href={PHONE_HREF} className='pkg-phone-btn'>
                                        Call us at : {PHONE}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='package-box'>
                                <h3>Bestseller</h3>
                                <div class="price">
                                    <h2>$10,000</h2>
                                    <span><em>$28,600</em> <br />65% off </span>
                                </div>
                                <p>Easy Installments Available</p>
                                <ul className='details'>
                                    <li>editing</li>
                                    <li>formatting</li>
                                    <li>typesetting</li>
                                    <li>proofreading</li>
                                    <li>layout adjustment</li>
                                    <li>100% ownership rights</li>
                                    <li>unlimited revision</li>
                                    <li>Publication on Amazon, Kindle and Barnes &amp; Noble </li>
                                    <li>2-4 pages author website</li>
                                    <li>2-year domain and hosting</li>
                                    <li>Dedicated team of bestselling marketers </li>
                                    <li>ISBN</li>
                                    <li>Book cover design</li>
                                    <li>60-90 seconds book video trailer</li>
                                    <li>Book Marketing (Advance Marketing)</li>
                                    <li>eBook, Paper back, Hardcover (3 formats of your book)</li>
                                </ul>
                                <div className='pkg-images mb-2'>
                                    <div className='pkg-img-1'>
                                        <img src={customerstisfaction} alt='' className='' />
                                    </div>
                                    <div className='pkg-img-2'>
                                        <img src={moneyback} alt='' className='' />
                                    </div>
                                </div>
                                <a href='Javascript:;' className='theme-btn'>
                                    ORDER NOW
                                </a>
                                <a href='Javascript:;' className='chat theme-btn'>
                                    LIVE CHAT
                                </a>
                                <div className='mt-4'>
                                    <a href={PHONE_HREF} className='pkg-phone-btn'>
                                        Call us at : {PHONE}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>
        </>
    )
}