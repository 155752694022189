// import './index.css';
import Header from './Layout/Header';
import MainBanner from './Layout/MainBanner';
import Services from './Layout/Services';
import AboutUs from './Layout/Aboutus';
import Portfolio from './Layout/Portfolio';
import PackagesSection from './Layout/PackagesSection';
import FeatureSection from './Layout/FeatureSection';
import CtaSection from './Layout/CtaSection';
import TestimonialSection from './Layout/TestimonialSection';
import FooterBrandLogo from './Layout/FooterBrandLogo';
import Footer from './Layout/Footer';

function Couponlp() {
  return (
    <div className="App">
      <Header/>
      <MainBanner/>
      <Services/>
      <AboutUs/>
      <Portfolio/>
      <PackagesSection/>
      <FeatureSection/>
      <CtaSection/>
      <TestimonialSection/>
      <FooterBrandLogo/>
      <Footer/>
    </div>
  );
}

export default Couponlp;
