import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "@fancyapps/fancybox/dist/jquery.fancybox.min.css";  // Import FancyBox CSS
import $ from 'jquery'; // Import jQuery
import "@fancyapps/fancybox"; // Import FancyBox JS
// import './index.css';

import {
    BookPortfolio01,
    BookPortfolio02,
    BookPortfolio03,
    BookPortfolio04,
    BookPortfolio05,
    BookPortfolio06,
    BookPortfolio07,
    BookPortfolio08,
    BookPortfolio09,
    BookPortfolio10,
    BookPortfolio11,
    BookPortfolio12,
    BookPortfolio13,
    BookPortfolio14,
    BookPortfolio15,
    BookPortfolio16,
    BookPortfolio17,
    BookPortfolio18,
    BookPortfolio19,
    BookPortfolio20,
} from '../../assets/images/';
import { useLocation } from 'react-router-dom';

export default function PortfolioSection() {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1199);
    const location = useLocation(); // Get the current location

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1199);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        // Ensure FancyBox is initialized properly
        if ($ && $.fn.fancybox) {
            $('[data-fancybox="gallery"]').fancybox({
                buttons: [
                    "zoom",
                    "slideShow",
                    "thumbs",
                    "close"
                ],
            });
        } else {
            console.error("Fancybox not found on jQuery object.");
        }
    }, []);

    const sliderSettings = {
        infinite: true,
        autoplay: false,
        arrows: false,
        dots: true,
        autoplaySpeed: 4000,
        speed: 1000,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
              breakpoint: 991,
              settings: {
                  slidesToShow: 3,
              }
          },
          
          {
            breakpoint: 576,
            settings: {
                slidesToShow: 2,
            }
        }
        ]
    };

    // BookPortfolio list
    const BookPortfolio = [
        BookPortfolio01,
        BookPortfolio02,
        BookPortfolio03,
        BookPortfolio04,
        BookPortfolio05,
        BookPortfolio06,
        BookPortfolio07,
        BookPortfolio08,
        BookPortfolio09,
        BookPortfolio10,
        BookPortfolio11,
        BookPortfolio12,
        BookPortfolio13,
        BookPortfolio14,
        BookPortfolio15,
        BookPortfolio16,
        BookPortfolio17,
        BookPortfolio18,
        BookPortfolio19,
        BookPortfolio20,
    ];

    // Determine the number of items to show based on the current page
    const isPortfolioPage = location.pathname.replace(/\/$/, '') === "/portfolio"; // Adjust the path as needed for your portfolio page
    const itemsToShow = isPortfolioPage ? BookPortfolio : BookPortfolio.slice(0, 10);
    
    return (
        <>
                          {isMobile ? (
                    // Slick Slider for mobile view
                    <Slider {...sliderSettings} className='portfolio_wrap'>
                        {itemsToShow.map((image, index) => (
                            <div key={index} className="portfolio-slide">
                                <a
                                    data-fancybox="gallery"
                                    className='portfolio_item'
                                    href={image}
                                    data-caption={`Portfolio ${index + 1}`}
                                >
                                    <img src={image} alt={`Portfolio ${index + 1}`} className="img-fluid" />
                                    <div className="facybox-hover">
                                        <i className="fa-solid fa-magnifying-glass"></i>
                                    </div>
                                </a>
                            </div>
                        ))}
                    </Slider>
                ) : (
                    // Bootstrap grid for desktop view with 5 columns
                    <div className="container">
                        <div className="row portfolio_wrap">
                            {itemsToShow.map((image, index) => (
                                <div key={index} className="custom-col-5 mb-4">
                                    <a
                                        data-fancybox="gallery"
                                        className='portfolio_item'
                                        href={image}
                                        data-caption={`Portfolio ${index + 1}`}
                                    >
                                        <img src={image} alt={`Portfolio ${index + 1}`} className="img-fluid" />
                                        <div className="facybox-hover">
                                            <i className="fa-solid fa-magnifying-glass"></i>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
        </>
    );
}
