import { chat } from '../../assets/images'
import { ModalSection } from '../../components/modalSection';
// import './index.css'
import React, { useState } from 'react'

export default function CtaSection() {
    const [open, setOpen] = useState(false);

    const onOpenModal = () => {
        setOpen(true);
    };

    const onCloseModal = () => {
        setOpen(false);
    };
    return (
        <>
            <section class="cta-sec">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-10 offset-1 text-center">
                            <h2>
                                Got a draft and are looking<br />
                                to get it published?
                            </h2>
                            <div class="btn-block">
                                <a href="javascript:;" class="cta-button" onClick={onOpenModal}>Get a Free Quote</a>
                                <a href="javascript:;" class="chat cta-chat">Live Chat</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ModalSection open={open} onCloseModal={onCloseModal} />
        </>
    )
}