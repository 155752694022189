import React,{useEffect} from 'react';
// import './index.css';
import Header from './layout/Header';
import MainBanner from './layout/MainBanner';
import BrandLogoSlider from './components/BrandlogoSlider';
import AboutUs from './layout/AboutUs';
import CtaSection from './layout/CtaSection';
import SecondCta from './layout/SecondCta';
import ServiceSection from './layout/ServiceSection';
import Footer from './layout/Footer';
import MainFooterForm from './layout/MainFooterForm';
import WhyChooseUs from './layout/WhyChooseUs';
import Testimonial from './layout/Testimonial';
import AOS from "aos";
import "aos/dist/aos.css";
import Portfolio from './layout/Portfolio';


function Marketinglp() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div className="App">
      <Header/>
      <MainBanner/>
      <BrandLogoSlider/>
      <AboutUs/>
      <CtaSection/>
      <ServiceSection/>
      <SecondCta/>
      <Portfolio/>
      <Testimonial/>
      <WhyChooseUs/>
      <MainFooterForm/>
      <Footer/>
    </div>
  );
}

export default Marketinglp;
