import { logoimg1, logoimg2, logoimg3, logoimg4 } from "../../assets/images";
import BrandLogoSlider from "../../components/BrandLogoSlider";
import CountDown from "../../components/CountDown";
import { ModalSection } from "../../components/modalSection";
// import './index.css'
import React, { useState, useEffect } from 'react';

export default function MainBanner() {
    useEffect(() => {
        // Function to open chat window
        const openChatWindow = () => {
            if (window.$zopim) {
                window.$zopim.livechat.window.show();
            }
        };

        // Function to toggle chat window
        const toggleChat = () => {
            if (window.$zopim) {
                window.$zopim.livechat.window.toggle();
            }
        };

        // Initialize chat and open by default
        const initializeChat = () => {
            const intervalId = setInterval(() => {
                if (window.$zopim && window.$zopim.livechat) {
                    clearInterval(intervalId);
                    openChatWindow();
                    listenForNewMessages();
                }
            }, 100); // Check every 100ms if chat widget is available
        };

        // Listen for new messages
        const listenForNewMessages = () => {
            if (window.$zopim && window.$zopim.livechat) {
                window.$zopim.livechat.setOnUnreadMsgs((numberOfUnreadMessages) => {
                    if (numberOfUnreadMessages > 0) {
                        openChatWindow();
                    }
                });
            }
        };

        initializeChat();
        const chatButtons = document.querySelectorAll('.chat'); // Select all buttons with the class 'chat'

        chatButtons.forEach(button => {
            button.addEventListener('click', toggleChat); // Add click event to each button
        });

        // Cleanup: Remove event listeners when the component unmounts
        return () => {
            chatButtons.forEach(button => {
                button.removeEventListener('click', toggleChat); // Remove click event from each button
            });
        };
    }, []);
    const [open, setOpen] = useState(false);

    const onOpenModal = () => {
        setOpen(true);
    };

    const onCloseModal = () => {
        setOpen(false);
    };
    return (
        <>
            <section className="banner-sec">
                <CountDown />
                <div className="container">
                    <h2 className="banner-heading">
                        Become the Next Best-Selling Author!
                    </h2>
                    <p className="banner-text">
                    Join thousands of successful authors who have trusted us to bring their stories to life. With a team of over 2,500 talented writers and editors, we guarantee your satisfaction as we craft your manuscript under expert supervision.
                    </p>
                    {/* <ul>
                        <li>93% of Customers Hire</li>
                        <li>2.5k+ WRITERS AND EDITORS</li>
                        <li>100% SATISFACTION GUARANTEED</li>
                        <li>GHOSTWRITTEN UNDER THE SUPERVISION OF Amazon Book Writers</li>
                    </ul> */}
                    <div className="banner-btn">
                        <a href="Javascript:;" className="btn coupon-btn" onClick={onOpenModal}>Active Your Coupon</a>
                        <a href="Javascript:;" className="btn chat">chat Now To Get 30% Off</a>
                    </div>
                    <BrandLogoSlider />
                </div>
            </section>
            <ModalSection open={open} onCloseModal={onCloseModal} />
        </>
    )
}