import { logo } from "../../assets/images";
import { EMAIL, EMAIL_HREF } from "../../config";
// import './index.css'

export default function Footer() {
    return (
        <>
            <footer>
                <div className='container'>
                    <div className='row'>
                        <div className="footer-text text-center">
                            <img src={logo} className="img-fluid" />
                            <p>Amazon Book Writers has brilliant writers to accommodate all genres, from fiction novels to business books, memoirs to self-help guides. We also provide turnkey publishing services for authors globally.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="copyright">
                    <div className="container">
                        <div className="copyright-text">
                            <p>Amazon Book Writers. All Rights Reserved.</p>
                            <div>
                                <a href="https://www.amazonbookwriters.com/privacy-policy" className="privacy">Privacy Policy</a> / <a href="https://www.amazonbookwriters.com/terms-and-conditions" className="terms">Terms & Conditions</a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}