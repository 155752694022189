import Header from './Layout/Header';
import Footer from './Layout/Footer';
import MainBanner from './Layout/MainBanner';
import BrandLogo from './Layout/BrandLogo';
import DraftSection from './Layout/DraftSection';
import Services from './Layout/Services';
import WhatWeDo from './Layout/WhatWeDo';
import CtaSection from './Layout/CtaSection';
import GetPublishedSection from './Layout/GetPublishedSection';
import Testimonial from './Layout/Testimonial';
import ContactSection from './Layout/ContactSection';
import Portfolio from './Layout/Portfolio';
import Packages from './Layout/Packages';

function BookEditingCompany() {
  return (
    <div className="App">
      <Header />
      <MainBanner />
      <BrandLogo />
      <DraftSection />
      <Services />
      <WhatWeDo />
      <Portfolio />
      <Packages />
      <CtaSection />
      <GetPublishedSection />
      <Testimonial />
      <ContactSection />
      <Footer />
    </div>
  );
}

export default BookEditingCompany;
