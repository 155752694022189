import {WEBSITE_NAME, PHONE, PHONE_HREF} from '../../config';
import axios from 'axios';
import {
    GiveStoryImg,
    WhyChooseBackImg
} from '../../assets/images';
import { useChat } from '../../ChatContext';
import { useModal } from '../../ModalContext';
import Breadcrumb from '../../components/Breadcrumb';
import CtaSection from '../../GlobalSections/Cta';
import FooterFormSec from '../../GlobalSections/FooterFormSec';
import aboutData from '../../data/about.json';
import InnerBanner from '../../GlobalSections/InnerBanner';

export default function AboutPage() {
    const { toggleChat } = useChat();
    const { openModal } = useModal();
    const actionHandlers = {
        openModal,
        toggleChat
    };
    const replacePlaceholders = (text) => {
        return text
            .replace(/{{WEBSITE_NAME}}/g, WEBSITE_NAME);
    };

    return (
        <>  
            <InnerBanner data={aboutData}/>
            <Breadcrumb/>
            <section className='simple_block white_bg sec_pt sec_pb'>
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-lg-6'>
                            <h2>{replacePlaceholders(aboutData.WhoWeAre.title)}</h2>
                            {aboutData.WhoWeAre.descriptions.map((description, index) => (
                                <p key={index}>{replacePlaceholders(description)}</p>
                            ))}
                            <div className='btn_wrap'>
                                 {aboutData.WhoWeAre.ctaButtons.map((button, index) => {
                                    const handleClick = actionHandlers[button.handleClick];
                                    return (
                                        <a
                                            key={index}
                                            onClick={handleClick}
                                            href={button.link}
                                            className={`default_btn ${button.className}`}
                                        >
                                            {button.text}
                                        </a>
                                    );
                                })}
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='why_choose_img'>
                                <img src={WhyChooseBackImg} className='WhyChooseBackImg' alt='WhyChooseBackImg'/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='simple_block grey_bg sec_pt sec_pb'>
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-lg-6'>
                            <img src={GiveStoryImg} className='img-fluid' alt='GiveStoryImg'/>
                        </div>
                        <div className='col-lg-6'>
                            <h2>{replacePlaceholders(aboutData.SecondSection.title)}</h2>
                            {aboutData.SecondSection.descriptions.map((description, index) => (
                                <p key={index}>{replacePlaceholders(description)}</p>
                            ))}
                            <div className='btn_wrap'>
                                 {aboutData.SecondSection.ctaButtons.map((button, index) => {
                                    const handleClick = actionHandlers[button.handleClick];
                                    return (
                                        <a
                                            key={index}
                                            onClick={handleClick}
                                            href={button.link}
                                            className={`default_btn ${button.className}`}
                                        >
                                            {button.text}
                                        </a>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <CtaSection data={aboutData}/>

            <FooterFormSec data={aboutData}/>
        </>
    );
}