import React, { useState, useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';

export default function TestimonialSlider() {

    const settings = {
        arrows: false,
        loop: false,
        margin: 10,
        nav: false,
        dots: false,
        responsive: {
            0: {
                items: 1,
                loop : true,
            },
            575: {
                items: 1,
                loop : true,
            },
            768: {
                items: 2,
                loop : true,
            },
            992: {
                items: 2,
            },
        },
    };
    return (
        <>
            <OwlCarousel className='testimonial-slider' {...settings}>
                <div className='testimonial-item'>
                    <p>
                    Amazon Book Writers made my dream of becoming an author come true. They are incredibly creative, managing everything from outlining the plot to designing and publishing. They revised my draft multiple times to ensure my satisfaction.
                    </p>
                    <h6>Martha Doyle <span>Educationist and Author</span></h6>
                </div>
                <div className='testimonial-item'>
                    <p>
                    I was referred to Amazon Book Writers by a friend, and I couldn’t be more grateful. The team took the time to understand my needs and truly cared about turning my book idea into a published reality.
                    </p>
                    <h6>Evan Bruce <span>Author</span></h6>
                </div>
            </OwlCarousel>
        </>
    )
}