import { icon1, icon2, icon3 } from "../../assets/images";
// import './index.css'

export default function Services() {
    return (
        <>
            <section className="services">
                <div className="row">
                    <div className="col-md-4">
                        <div className="service-item service-1">
                            <img src={icon2} alt="service" />
                            <h6>Affordable<br /> Prices</h6>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="service-item service-2">
                            <img src={icon1} alt="service" />
                            <h6>Quickest<br /> Turnaround Time</h6>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="service-item service-3">
                            <img src={icon3} alt="service" />
                            <h6>24/7 Professional<br /> Support</h6>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}