import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
import { svbimg1, svbimg2, svbimg3, svbimg4, svbimg5, svbimg6, svbimg7, svbimg8, svbimg9, svimg1, svimg2, svimg3, svimg4, svimg5, svimg6, svimg7, svimg8, svimg9 } from '../../assets/images';
import { Modal } from 'react-bootstrap';
import { ModalSection } from '../modalSection';

const ServicesSection = () => {
  const [open, setOpen] = useState(false);

  const onOpenModal = () => {
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 991);
  const [selectedService, setSelectedService] = useState(null);

  useEffect(() => {
    const handleResize = () => setIsLargeScreen(window.innerWidth > 991);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const services = [
    {
      className: "service-1",
      title: "Author Website<br /> Design & Development",
      image: svimg1,
      description: "Your website is your digital storefront, showcasing your brand, genre, and books to potential readers. It’s essential that your site effectively promotes your work, whether it’s simple or sophisticated. A well-designed website not only establishes your professionalism but also attracts a wider audience ready to discover and buy your books.",
      modalimage: svbimg1,
    },
    {
      className: "service-2",
      title: "Social<br /> Media Marketing",
      image: svimg2,
      description: "In today’s digital landscape, a strong social media presence is vital for authors. It helps increase website traffic, boost book sales, build brand recognition, and cultivate a dedicated fan base for your upcoming releases. Let us handle your social media management, and we’ll keep your followers engaged with creative and relevant content across platforms like Facebook, Twitter, and Instagram.",
      modalimage: svbimg2,
    },
    {
      className: "service-3",
      title: "Search Engine<br /> Optimization (SEO)",
      image: svimg3,
      description: "Driving traffic to your website is crucial, and search engines like Google play a significant role. Proper SEO ensures your site ranks well for popular search terms. We conduct thorough research to identify the best keywords for your book, combining your insights with data analysis to maximize your visibility and chances of success.",
      modalimage: svbimg3,
    },
    {
      className: "service-4",
      title: "Video<br /> Review",
      image: svimg4,
      description: "Media exposure can significantly enhance your credibility and improve book sales. However, standing out in a crowded market requires strategic planning. We’ll create a tailored video review strategy that aligns with your goals, providing you with impactful marketing materials that give your book a competitive edge.",
      modalimage: svbimg4,
      youtubeUrl: "https://www.youtube.com/watch?v=t5bOGAtmgOg",
    },
    {
      className: "service-5",
      title: "Online Independent<br /> Reviews",
      image: svimg5,
      description: "For new authors, securing unbiased professional reviews is essential. Positive reviews on platforms like Amazon build credibility and generate buzz around your book. We help facilitate these reviews, ensuring potential readers see valuable insights that inform their purchasing decisions.",
      modalimage: svbimg5,

    },
    {
      className: "service-6",
      title: "Book<br /> Trailer",
      image: svimg6,
      description: "A captivating book trailer can draw readers in by evoking emotions and conveying the essence of your story. Professionally crafted trailers are an innovative way to market your book, helping to create buzz and drive sales by giving potential readers a taste of what to expect.",
      modalimage: svbimg6,
    },
    {
      className: "service-7",
      title: "Editorial<br /> Critiques",
      image: svimg7,
      description: "Looking for an objective opinion on your completed manuscript? Our editorial critiques provide professional insights that go beyond what friends and family can offer. This feedback helps you understand how your book may be received in the market, guiding you toward improvement.",
      modalimage: svbimg7,
    },
    {
      className: "service-8",
      title: "Blogs for Book<br /> Promotion",
      image: svimg8,
      description: "Engaging with book bloggers can significantly impact your book’s visibility. These bloggers attract dedicated readers who are eager for new releases in their favorite genres. Partnering with influential review blogs can generate excitement and spread the word about your book at a grassroots level.",
      modalimage: svbimg8,
    },
    {
      className: "service-9",
      title: "Press<br /> Releases",
      image: svimg9,
      description: "Launching your book with a press release can amplify your reach. We distribute your announcement to numerous news agencies and media outlets, including your book cover and links to purchase. A compelling press release opens new channels of communication and connects you with more readers.",
      modalimage: svbimg9,
    },
  ];



  const settings = {
    loop: true,
    margin: 10,
    dots: true,
    items: 1,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      992: {
        items: 3,
      },
    },
  };

  // Close modal function
  const closeModal = () => setSelectedService(null);

  return (
    <div className="row" data-aos="zoom-in" data-aos-duration="1500">
      {isLargeScreen ? (
        services.map((service, index) => (
          <div className={`col-md-4 service-${index + 1}`} key={index}>
            <div className="service-box">
              <i><img src={service.image} alt={service.title} /></i>
              <h3 dangerouslySetInnerHTML={{ __html: service.title }}></h3>
              <p>{service.description}</p>
              <a href="Javascript:;" className="service-btn"onClick={onOpenModal}>Get Started</a>
              <a href="Javascript:;" className="service-modal" onClick={() => setSelectedService({ ...service, index })}>View Sample</a>

            </div>
          </div>
        ))
      ) : (
        <OwlCarousel className="owl-theme" {...settings}>
          {services.map((service, index) => (
            <div className={`service-${index + 1}`} key={index}>
              <div className="service-box">
                <i><img src={service.image} alt={service.title} /></i>
                <h3 dangerouslySetInnerHTML={{ __html: service.title }}></h3>
                <p>{service.description}</p>
                <a href="Javascript:;" className="service-btn"onClick={onOpenModal}>Get Started</a>
                <a href="Javascript:;" className="service-modal" onClick={() => setSelectedService({ ...service, index })}>View Sample</a>
              </div>
            </div>
          ))}
        </OwlCarousel>
      )}

      <Modal
        className='service-section'
        show={!!selectedService}
        onHide={closeModal}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body>
          {selectedService && (
            <div className="modal-content">
              <button type="button" className="close" onClick={closeModal}>
                <span aria-hidden="true">&times;</span>
              </button>
              <div className={`service-${selectedService.index + 1}`}>
                <div className="service-box">
                  <i><img src={selectedService.image} alt={selectedService.title} /></i>
                  <h3 dangerouslySetInnerHTML={{ __html: selectedService.title }}></h3>
                  {selectedService.className === "service-4" ? (
                    <a href={selectedService.youtubeUrl} target="_blank" rel="noopener noreferrer">
                      <img src={selectedService.modalimage} alt={selectedService.title} className='img-fluid' />
                    </a>
                  ) : (
                    <img src={selectedService.modalimage} alt={selectedService.title} className='img-fluid' />
                  )}
                  <p>{selectedService.description}</p>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
      <ModalSection open={open} onCloseModal={onCloseModal} />
    </div>

  );
};

export default ServicesSection;

