import './index.css'
import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
import { newsimg1, newsimg2, newsimg3, newsimg4, newsimg5, newsimg6, newsimg7 } from "../../assets/images";

const BrandLogoSlider = () => {
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1200);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 1200);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const responsiveOptions = {
        0: { items: 1 },
        425: { items: 2 },
        600: { items: 3 },
        991: { items: 5 },
    };

    return (
        <section className="banner-logo">
            <div className="container-fluid">
                {isSmallScreen ? (
                    <OwlCarousel
                        className="owl-theme"
                        loop
                        margin={10}
                        responsive={responsiveOptions}
                    >
                        <div className="brandlogo d-flex justify-content-center" data-aos="zoom-in-right" data-aos-duration="1500" data-aos-delay="200"><img src={newsimg1} alt="Logo 1" className='img-fluid' /></div>
                        <div className="brandlogo d-flex justify-content-center" data-aos="zoom-in-right" data-aos-duration="1500" data-aos-delay="400"><img src={newsimg2} alt="Logo 2" className='img-fluid' /></div>
                        <div className="brandlogo d-flex justify-content-center" data-aos="zoom-in-right" data-aos-duration="1500" data-aos-delay="600"><img src={newsimg3} alt="Logo 3" className='img-fluid' /></div>
                        <div className="brandlogo d-flex justify-content-center" data-aos="zoom-in-right" data-aos-duration="1500" data-aos-delay="800"><img src={newsimg4} alt="Logo 4" className='img-fluid' /></div>
                        <div className="brandlogo d-flex justify-content-center" data-aos="zoom-in-right" data-aos-duration="1500" data-aos-delay="1000"><img src={newsimg5} alt="Logo 5" className='img-fluid' /></div>
                        <div className="brandlogo d-flex justify-content-center" data-aos="zoom-in-right" data-aos-duration="1500" data-aos-delay="1200"><img src={newsimg6} alt="Logo 6" className='img-fluid' /></div>
                        <div className="brandlogo d-flex justify-content-center" data-aos="zoom-in-right" data-aos-duration="1500" data-aos-delay="1400"><img src={newsimg7} alt="Logo 7" className='img-fluid' /></div>
                    </OwlCarousel>
                ) : (
                    <div className="row brandlogo-slider justify-content-around align-items-center">
                        <div className="brandlogo" data-aos="zoom-in-right" data-aos-duration="1500" data-aos-delay="200"><img src={newsimg1} alt="Logo 1" className='img-fluid' /></div>
                        <div className="brandlogo" data-aos="zoom-in-right" data-aos-duration="1500" data-aos-delay="400"><img src={newsimg2} alt="Logo 2" className='img-fluid' /></div>
                        <div className="brandlogo" data-aos="zoom-in-right" data-aos-duration="1500" data-aos-delay="600"><img src={newsimg3} alt="Logo 3" className='img-fluid' /></div>
                        <div className="brandlogo" data-aos="zoom-in-right" data-aos-duration="1500" data-aos-delay="800"><img src={newsimg4} alt="Logo 4" className='img-fluid' /></div>
                        <div className="brandlogo" data-aos="zoom-in-right" data-aos-duration="1500" data-aos-delay="1000"><img src={newsimg5} alt="Logo 5" className='img-fluid' /></div>
                        <div className="brandlogo" data-aos="zoom-in-right" data-aos-duration="1500" data-aos-delay="1200"><img src={newsimg6} alt="Logo 6" className='img-fluid' /></div>
                        <div className="brandlogo" data-aos="zoom-in-right" data-aos-duration="1500" data-aos-delay="1400"><img src={newsimg7} alt="Logo 7" className='img-fluid' /></div>
                    </div>
                )}
            </div>
        </section>
    );
};

export default BrandLogoSlider;
