import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { chooseicon } from '../../assets/images';

const WhyChooseUsSlider = () => {
    const [isSliderActive, setIsSliderActive] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsSliderActive(window.innerWidth < 1440);
        };

        // Initial check
        handleResize();

        // Add event listener on resize
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const options = {
        loop: true,
        margin: 10,
        dots : true ,
        nav: false,
        responsive: {
            0: { items: 1 },
            421:{items : 2},
            768: { items: 3 },
            992: { items: 4 },
            1199 :{items :5 },
        }
    };

    return (
        <div className='choose-slider'>
            {isSliderActive ? (
                <OwlCarousel className='row no-gutters'{...options}>
                    <div className='choose-box' data-aos="zoom-in-right" data-aos-duration="1500" data-aos-delay="200">
                        <img src={chooseicon} alt="Icon" className='img-fluid'/>
                        <h3>Dedicated <br />Ghostwriters</h3>
                    </div>
                    <div className='choose-box' data-aos="zoom-in-right" data-aos-duration="1500" data-aos-delay="400">
                        <img src={chooseicon} alt="Icon" className='img-fluid'/>
                        <h3>Fast<br />Delivery</h3>
                    </div>
                    <div className='choose-box' data-aos="zoom-in-right" data-aos-duration="1500" data-aos-delay="600">
                        <img src={chooseicon} alt="Icon" className='img-fluid'/>
                        <h3>Creative <br />Plots</h3>
                    </div>
                    <div className='choose-box' data-aos="zoom-in-right" data-aos-duration="1500" data-aos-delay="800">
                        <img src={chooseicon} alt="Icon" className='img-fluid'/>
                        <h3>Non-Plagiarized<br />Content</h3>
                    </div>
                    <div className='choose-box' data-aos="zoom-in-right" data-aos-duration="1500" data-aos-delay="1000">
                        <img src={chooseicon} alt="Icon" className='img-fluid'/>
                        <h3>Unique Writing <br />Styles</h3>
                    </div>
                    <div className='choose-box' data-aos="zoom-in-right" data-aos-duration="1500" data-aos-delay="1200">
                        <img src={chooseicon} alt="Icon" className='img-fluid'/>
                        <h3>Fresh <br />Content</h3>
                    </div>
                    <div className='choose-box' data-aos="zoom-in-right" data-aos-duration="1500" data-aos-delay="1400">
                        <img src={chooseicon} alt="Icon" className='img-fluid'/>
                        <h3>Timely <br />Updates</h3>
                    </div>
                    <div className='choose-box' data-aos="zoom-in-right" data-aos-duration="1500" data-aos-delay="1600">
                        <img src={chooseicon} alt="Icon" className='img-fluid'/>
                        <h3>Unlimited <br />Revisions</h3>
                    </div>
                </OwlCarousel>
            ) : (
                <div className='row no-gutters choose-slider'>
                    <div className='col'>
                        <div className='choose-box' data-aos="zoom-in-right" data-aos-duration="1500" data-aos-delay="200">
                            <img src={chooseicon} className='img-fluid'/>
                            <h3>Dedicated <br />Ghostwriters</h3>
                        </div>
                    </div>
                    <div className='col'>
                        <div className='choose-box' data-aos="zoom-in-right" data-aos-duration="1500" data-aos-delay="400">
                            <img src={chooseicon} className='img-fluid'/>
                            <h3>Fast<br />Delivery</h3>
                        </div>
                    </div>
                    <div className='col'>
                        <div className='choose-box' data-aos="zoom-in-right" data-aos-duration="1500" data-aos-delay="600">
                            <img src={chooseicon} className='img-fluid'/>
                            <h3>Creative <br />Plots</h3>
                        </div>
                    </div>
                    <div className='col'>
                        <div className='choose-box' data-aos="zoom-in-right" data-aos-duration="1500" data-aos-delay="800">
                            <img src={chooseicon} className='img-fluid'/>
                            <h3>Non-Plagiarized<br />Conten</h3>
                        </div>
                    </div>
                    <div className='col'>
                        <div className='choose-box' data-aos="zoom-in-right" data-aos-duration="1500" data-aos-delay="1000">
                            <img src={chooseicon} className='img-fluid'/>
                            <h3>Unique Writing <br />Styles</h3>
                        </div>
                    </div>
                    <div className='col'>
                        <div className='choose-box' data-aos="zoom-in-right" data-aos-duration="1500" data-aos-delay="1200">
                            <img src={chooseicon} className='img-fluid'/>
                            <h3>Fresh <br />Content</h3>
                        </div>
                    </div>
                    <div className='col'>
                        <div className='choose-box' data-aos="zoom-in-right" data-aos-duration="1500" data-aos-delay="1400">
                            <img src={chooseicon} className='img-fluid'/>
                            <h3>Timely <br />Updates</h3>
                        </div>
                    </div>
                    <div className='col'>
                        <div className='choose-box' data-aos="zoom-in-right" data-aos-duration="1500" data-aos-delay="1600">
                            <img src={chooseicon} className='img-fluid'/>
                            <h3>Unlimited <br />Revisions</h3>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default WhyChooseUsSlider;
