import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { format, publish, researcicon, review, writing } from '../../assets/images'

export default function ServiceSlider() {
    const settings = {
        dots: false,
        arrows: true,
        infinite: false,
        autoplaySpeed: 2000,
        speed: 300,
        margin: 10,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [{
            breakpoint: 992,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                arrows: false
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                arrows: false
            }
        }
        ]
    };

    return (

        <>
            <Slider {...settings}>
                <div className="service-box">
                    <img src={researcicon} className="img-fluid lazyload" alt="Researching and Drafting The Outline" />
                    <h5>Assessing Your Manuscript</h5>
                    <p>We will start by reviewing your manuscript to understand your ideas, themes, and overall goals. This initial assessment helps identify areas for improvement.</p>
                </div>
                <div className="service-box">
                    <img src={writing} className="img-fluid lazyload" alt="Writing Genuine Content Based On Your Idea" />
                    <h5>Creating an Editing Plan</h5>
                    <p>After the assessment, We’ll develop a customized editing plan tailored to your manuscript’s needs, outlining the specific areas we will focus on.</p>
                </div>
                <div className="service-box">
                    <img src={review} className="img-fluid lazyload" alt="Critical Review, Editing, And Proofreading" />
                    <h5>Comprehensive Editing</h5>
                    <p>We’ll dive into the editing process, enhancing the structure, flow, and clarity of your writing. This includes content editing to strengthen your narrative and line editing for style and voice.</p>
                </div>
                <div className="service-box">
                    <img src={format} className="img-fluid lazyload" alt="Extensive Formatting, Typesetting, And Designing" />
                    <h5>Thorough Proofreading</h5>
                    <p>Once the edits are complete, We will meticulously proofread your manuscript to ensure it’s free of grammar, punctuation, and spelling errors.</p>
                </div>
                <div className="service-box">
                    <img src={publish} className="img-fluid lazyload" alt="Publishing, Branding, Marketing, And Promotions" />
                    <h5>Final Review and Formatting</h5>
                    <p>After proofreading, We’ll conduct a final review to ensure everything is polished. Then, We’ll format your manuscript for publication, preparing it in your preferred style</p>
                </div>
            </Slider>
        </>
    );
}
