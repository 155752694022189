// src/contexts/ModalContext.js
import React, { createContext, useState, useContext } from 'react';
// import Modal from './components/Modal';
import { ModalSection } from './components/Modal';

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [packageTitle, setPackageTitle] = useState(""); 

  const openModal = (title) => {
    setPackageTitle(title);  // Store the passed title
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setPackageTitle("");
  };

  return (
    <ModalContext.Provider value={{ isModalOpen, openModal, closeModal, packageTitle }}>
      {children}
      {/* <Modal open={isModalOpen} onCloseModal={closeModal} selectedPackage={packageTitle} /> */}
      <ModalSection open={isModalOpen} onCloseModal={closeModal} selectedPackage={packageTitle} />

    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);
