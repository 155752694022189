import { bluechat, chat, cta1 } from '../../assets/images'
import { ModalSection } from '../../components/modalSection'
// import './index.css'
import React,{useState} from 'react'

export default function CtaBanner() {
    const [open, setOpen] = useState(false);

    const onOpenModal = () => {
        setOpen(true);
    };

    const onCloseModal = () => {
        setOpen(false);
    };
    return (
        <>
            <div className="banner-cta">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5"></div>
                        <div className="col-lg-7">
                            <h4>In the world of book writing, we're here to <br />help you every step of the way!</h4>
                            <h3>BECOME A PUBLISHED <br />AUTHOR NOW</h3>
                            <h2>30</h2>
                            <div className='talk-btn'>
                                <a href='Javascript:;' className='chat'>
                                    <img src={cta1} alt='cta-img' />Lets Talk
                                </a>
                            </div>
                            <div className="cta-btn">
                                <a href='Javascript:;' className='cta-banner-btn'onClick={onOpenModal}>Get a Free Quote</a>
                                <a href='Javascript:;' className='chat cta-banner-chat'><img src={bluechat} alt='Chat' />chat with us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalSection open={open} onCloseModal={onCloseModal} />
        </>
    )
}