import React, { useState, useEffect } from "react";
import { logo } from "../../assets/images";
import { PHONE, PHONE_HREF } from "../../config";
// import './index.css'

export default function Header() {
    useEffect(() => {
        // Function to open chat window
        const openChatWindow = () => {
            if (window.$zopim) {
                window.$zopim.livechat.window.show();
            }
        };

        // Function to toggle chat window
        const toggleChat = () => {
            if (window.$zopim) {
                window.$zopim.livechat.window.toggle();
            }
        };

        // Initialize chat and open by default
        const initializeChat = () => {
            const intervalId = setInterval(() => {
                if (window.$zopim && window.$zopim.livechat) {
                    clearInterval(intervalId);
                    openChatWindow();
                    listenForNewMessages();
                }
            }, 100); // Check every 100ms if chat widget is available
        };

        // Listen for new messages
        const listenForNewMessages = () => {
            if (window.$zopim && window.$zopim.livechat) {
                window.$zopim.livechat.setOnUnreadMsgs((numberOfUnreadMessages) => {
                    if (numberOfUnreadMessages > 0) {
                        openChatWindow();
                    }
                });
            }
        };

        initializeChat();
        const chatButtons = document.querySelectorAll('.chat');

        chatButtons.forEach(button => {
            button.addEventListener('click', toggleChat);
        });

        return () => {
            chatButtons.forEach(button => {
                button.removeEventListener('click', toggleChat);
            });
        };
    }, []);
    return (
        <header>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 logo-main">
                        <a href="Javascript:;" className="logo">
                            <img src={logo} alt="Logo" />
                        </a>
                    </div>
                    <div className="col-lg-6">
                        <ul className="header-btn">
                            <li>
                                <a href={PHONE_HREF}>
                                    <i class="fas fa-phone-alt" aria-hidden="true"></i>
                                    {PHONE}
                                </a>

                            </li>
                            <li>
                                <a href="Javascript:;" className="chat">
                                    <i class="fas fa-comment-alt" aria-hidden="true"></i>
                                    Live Chat
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    )
}