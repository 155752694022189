// import './index.css'

export default function Footer() {
    return (
        <>
            <footer className="copyright">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center" >
                            <p>© 2024 Amazon Book Writers. All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}