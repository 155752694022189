import { b5, b6 } from '../../assets/images'
import Button from '../../Components/Button'
// import './index.css'

export default function DraftSection() {
    return (
        <>
            <section className="draft-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className='draft-text'>
                                <h2>Are You Struggling to Get Your Book Just Right?</h2>
                                <p>Have you hit a wall with your manuscript? You’re not alone! Many writers face common hurdles that can make the journey feel overwhelming:</p>
                                <ul class="mb-5">
                                    <li>Is your draft feeling incomplete or scattered?</li>
                                    <li>Are you unsure how to improve the flow and structure of your story?</li>
                                    <li>Do grammar and punctuation mistakes keep you up at night?</li>
                                    <li>Are you feeling lost in the editing maze?</li>
                                    <li>Have you received feedback but don’t know where to start with revisions?</li>
                                </ul>
                                <Button />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className='draft-image text-center'>
                                <img src={b5} alt='' className='img-fluid'></img>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="draft-section pt-0">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className='draft-image text-center'>
                                <img src={b6} alt='' className='img-fluid'></img>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className='draft-text'>
                                <h2>Let’s Work Together to Tackle These Challenges!</h2>
                                <p>With my professional book editing services, you’ll receive personalized support tailored to your unique needs. We will dive into your manuscript, helping you identify and resolve structural issues while enhancing your narrative's clarity and engagement. Together, we’ll refine your writing, ensuring it’s polished and ready for your readers.</p>
                                <Button />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}