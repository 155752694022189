import React, { useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './index.css';

import { biographyImages, informativeImages, fictionImages, nonFictionImages, memoirImages } from './../../assets/images/';

const CategoryPortfolio = () => {
  const [activeTab, setActiveTab] = useState('tab1');

  const handleTabChange = (event) => {
    setActiveTab(event.target.value);
  };

  const settings = {
    infinite: false,
    autoplay: false,
    arrows: true,
    dots: true,
    autoplaySpeed: 4000,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4
        }
      }, {
        breakpoint: 768,
        settings: {
          slidesToShow: 3
        }
      }, {
        breakpoint: 576,
        settings: {
          slidesToShow: 2
        }
      },
    ]
  };

  const renderSliderItems = (images) => {
    return images.map((imgSrc, index) => (
      <div className='item' key={index}>
        <img src={imgSrc} alt={`Portfolio ${index + 1}`} />
      </div>
    ));
  };

  return (
    <section className='category_portfolio_sec sec_pt sec_pb'>
      <div className='container'>
        <div className="dropdown-tabs">
          <select onChange={handleTabChange} className="dropdown-select" value={activeTab}>
            <option value="tab1">Biography</option>
            <option value="tab2">Informative</option>
            <option value="tab3">Fiction</option>
            <option value="tab4">Non-Fiction</option>
            <option value="tab5">Memoir</option>
          </select>

          {activeTab === 'tab1' && (
            <Slider {...settings} className='CatPortfolioSlider'>
              {renderSliderItems(biographyImages)}
            </Slider>
          )}

          {activeTab === 'tab2' && (
            <Slider {...settings} className='CatPortfolioSlider'>
              {renderSliderItems(informativeImages)}
            </Slider>
          )}

          {activeTab === 'tab3' && (
            <Slider {...settings} className='CatPortfolioSlider'>
              {renderSliderItems(fictionImages)}
            </Slider>
          )}

          {activeTab === 'tab4' && (
            <Slider {...settings} className='CatPortfolioSlider'>
              {renderSliderItems(nonFictionImages)}
            </Slider>
          )}

          {activeTab === 'tab5' && (
            <Slider {...settings} className='CatPortfolioSlider'>
              {renderSliderItems(memoirImages)}
            </Slider>
          )}
        </div>
      </div>
    </section>
  );
};

export default CategoryPortfolio;
