import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const usePageMeta = () => {
  const location = useLocation();

  useEffect(() => {
    const setMetaTags = () => {
      let description = 'Amazon Books Publishing Expert elevates your book with our comprehensive services. Expert ghostwriting, professional publishing, and meticulous editing.';
      const url = window.location.href;

      const normalizedPath = location.pathname.endsWith('/') ? location.pathname.slice(0, -1) : location.pathname;

      // Object to store different Bootstrap files for different routes
      const bootstrapConfig = {
        '/': {
          title: 'Book Writing Services | Award-Winning Book Writers for Hire',
          description: 'Looking for top-quality book writing services? Amazon Book Writers is a reputable book writing company in USA. Browse our book portfolio for quality service.',
          
        },
        '/about-us': {
          title: 'Explore About Our Team | Amazon Book Writers',
          description: 'Amazon Book Writers is a professional book writing firm in USA. Explore about our team, And know who we are. Get a free consultation + Quote.',
          
        },
        '/portfolio': {
          title: 'Explore Our Books Portfolio Now | Amazon Book Writers',
          description: 'Amazon Book Writers has been serving since a very long time, More than 75+ best-selling authors have been produced. Our Portfolio says it all.',
          
        },
        '/ghostwriting-services': {
          title: `USA's #1 Trusted Ghostwriting Services | Expert Ghostwriters`,
          description: `USA’s#1 Ghostwriting services agency with a team of experienced ghostwriters for hire. Get professional ghost written books by trusted ghost writing company.`,
          
        },
        '/non-fiction-writing': {
          title: 'Non Fiction Writing Services Company | Non-fiction Writers For Hire',
          description: ' Non fiction writing services company for hire. We offer top non-fiction writing services by our professional non fiction writers. Hire us for nonfiction writing.',
          
        },
        '/manuscript-writing-service': {
          title: 'Manuscript Writing Services | Manuscript Writers for hire',
          description: 'Our Professional Manuscript writers offer the best Manuscript writing services with various strategies to ensure you get an impactful Manuscript in many fields.',
          
        },
        '/action-adventure-writing': {
          title: 'Action & Adventure Writing Services Company for Hire - Expert Writerss',
          description: 'We provide the best action & adventure writing services. Hire our action & adventure fiction writers and become the best adventure author. Professional action writers.',
          
        },
        '/military-fiction-writing': {
          title: 'Military Fiction Writing Services By Expert Military Writers',
          description: 'Military writing services by expert military writers. You can hire our professional military writers for your military war books. We have the best writers.',
          
        },
        '/horror-writing': {
          title: 'Classic Horror Writers for Hire - Get Best Horror Writing Services',
          description: 'Get professional horror writing services by our expert horror writers. Our writers are known for writing horror novels in the best form. We are always available for you.',
          
        },
        '/screenplay-writing': {
          title: 'Screenplay Writing Services Company Online - Hire Screenwriters',
          description: 'We offer affordable screenplay writing services online. Hire our screenwriters online and get your professional screenplay written. We provide online screenwriting.',
          
        },
        '/autobiography-service': {
          title: 'Autobiography Writing Services | Expert Autobiography Writers',
          description: 'We offer professional autobiography writing services to help you capture your life experiences and leave a lasting legacy. Contact us today.',
          
        },
        '/childrens-book-writing': {
          title: `Hire Our Children's Book Writers For Writing Children's Books`,
          description: `We provide best children's book writing services by our expert kid's book writers. Hire our experts for writing children's books and stories. Childrens book writing company.`,
          
        },
        '/memoir-writing-services': {
          title: 'Expert Memoir Writing service | Memoir Ghostwriters for Hire',
          description: 'We are a team of talented Memoir writers for Hire. Amazon Book Writers offer top Memoir Writing services that can turn your autobiography into best-selling book',
          
        },
        '/comic-book-writing-services': {
          title: 'Top Comic Book Writing Services | Comic Book Writers for Hire',
          description: 'Looking for Award-winning comic book writing services? Get help with story development, character arcs, and dialogue from our expert comic book writers for hire',
          
        },
        '/biography-writing': {
          title: 'Hire Us for Writing a Biography By Professional Bio Writers',
          description: 'Get professional biography writing services by a trusted bio writing company. Writing biography by hiring expert bio writers will make your book a big success.',
          
        },
        '/fantasy-writing': {
          title: 'Fantasy Writing Services By Professional Fantasy Writers',
          description: 'Hire us for writing fantasy books by our best fantasy writers. We offer professional fantasy writing services as we are a trusted agency. Become the best fantasy author.',
          
        },
        '/rhyme-writing': {
          title: 'Rhyme Writing Services Company By Expert Rhyme Writers',
          description: 'Hire rhyme writers to get professional rhyme writing services by our trusted rhyme writing agency. You can hire a rhyme writers in affordable rates.',
          
        },
        '/story-writing': {
          title: 'Best Short Story Writers For Hire - Story Writing Services Company',
          description: 'Best short story writing services company for hire. We provide you a change to hire our creative story writers and become a known author within days. Get in touch.',
          
        },
        '/science-fiction-writing': {
          title: 'Science Fiction Writers for Hire - Expert Sci Fi Writers',
          description: 'We offer the best science fiction writing services by our expert sci-fi writers. Hire our sci fi writers for writing science fiction novels. Creative sci-fi agency.',
          
        },
        '/book-editing-services': {
          title: 'Top Book Editing Services in USA | Professional Book editors',
          description: 'Our Book editing services company offers top notch book editing by our professional book editors. Hire a book editor and get an error free book today!',
          
        },
        '/book-proofreading-services': {
          title: 'Top-notch Book Proofreading services | 100% Content Accuracy',
          description: 'Amazon Book Writers provide high-level errorless book proofreading services by our award-winning proofreading experts. Get in touch with our professionals now.',
          
        },
        '/book-cover-design-services': {
          title: 'Top-notch Book Cover Design Services | Amazon Book Writers',
          description: 'We offer most-experienced book cover designers for hire. Our team is dedicated to provide most professional book cover designing services as we are the best design agency.',
          
        },
        '/author-website-design': {
          title: 'Author Website Design Services By Creative Web Designers',
          description: 'Hire the best author website design services to get a book author website. Our author website designers will build an author website that will help you sell your book.',
          
        },
        '/book-illustration-design': {
          title: 'Book Illustration Design Services Company - Hire Illustrators',
          description: 'We provide book illustration design services and are considered as the best company. Hire our book illustrators and get exciting cover design for your book.',
          
        },
        '/book-marketing-services': {
          title: 'Top-Notch USA Book Marketing Company | Book Promotion',
          description: 'We are a professional book marketing company that aims to provide high-level book marketing services. Our marketing experts are award-winning author.',
          
        },
        '/book-video-trailer': {
          title: 'Book Trailers Creation Services Company - Video Production Agency',
          description: 'Video book trailers services by expert trailer video creators. We are equipped with professionals who can create video production trailers for your books.',
          
        },
        '/book-publishing': {
          title: `USA's Award-Winning leading Online Book Publishing Services`,
          description: 'We offer hassle-free book publishing services. Our Professional Book Publishers are award-winning authors dedicated to help you become a bestseller.',
          
        },
        '/book-printing-services': {
          title: 'Professional Book Printing Services | Book Printing Company',
          description: 'Need custom book printing services? Look no further than our book printing company! We provide fast, affordable, & high quality book printing services for any project',
          
        },
        '/marketing-lp': {
          title: 'Book Marketing Services | Amazon Book Writers',
          description: 'Are you looking for Amazon Book Writers for hire? Amazon Book Writers offering ghostwriting services from the best ghostwriting agency & company who can turn your idea into a book.',
          
        },
        '/coupon-lp': {
          title: 'Book Writing Services - Hire Top | Amazon Book Writers at 30% Off to Create Your Timeless Work',
          
        },
        '/lp-1': {
          title: 'Hire Professional Amazon Book Writers - US #1 Book Writing Company',
          description: 'Are you looking for Amazon Book Writers for hire? Amazon Book Writers offering ghostwriting services from the best ghostwriting agency & company who can turn your idea into a book.',
          
        },
        '/book-editing-company': {
          title: 'Book Editing Company - Amazon Book Writers',
          description: 'Book Editing Company - Amazon Book Writers',
          
        },
        '/contact-us': {
          title: 'Contact Amazon Book Writers | Get a Free Consultation Now',
          description: 'Amazon Book Writers are working 24/7 around the globe. Share your idea with us, And we will make sure to provide you the best plan. 100% free consultation.',
          
        },
        '/privacy-policy': {
          title: 'Privacy Policy',
          description: '',
          

        },
        '/terms-and-conditions': {
          title: 'Terms & Conditions',
          description: '',
          

        },
        '/thankyou': {
          title: 'Thank You | Amazon Book Writers',
          description: '',
          

        },
        '/reviews': {
          title: 'Thank You | Amazon Book Writers',
          description: '',
          
        }
      };

      const defaultConfig = {
        title: 'Alpha Design Crew | Leading web design & development company based in California',
        description,
      };

      const config = bootstrapConfig[normalizedPath] || bootstrapConfig[`${normalizedPath}/`] || defaultConfig;

      // Update the document title
      document.title = config.title;


     

      return (
        <Helmet>
          <title>{config.title}</title>
          <meta name="description" content={config.description} />
          <meta property="og:title" content={config.title} />
          <meta property="og:description" content={config.description} />
          <meta property="og:url" content={url} />
        </Helmet>
      );
    };

    setMetaTags();
  }, [location.pathname]);

  return null;
};

export default usePageMeta;