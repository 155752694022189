import React, { useState, useEffect } from 'react';
import './index.css'; // Import custom CSS for the countdown design

const CountDown = () => {
  const calculateTimeLeft = () => {
    const now = new Date();
    const endTime = new Date(localStorage.getItem('endTime') || now.getTime() + 2 * 60 * 60 * 1000);
    const difference = endTime - now;

    if (difference <= 0) {
      return { hours: 0, minutes: 0, seconds: 0 };
    }

    // Calculate total seconds
    const totalSeconds = Math.floor(difference / 1000);

    // Calculate hours, minutes, and seconds
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return {
      hours,
      minutes,
      seconds,
    };
  };

  useEffect(() => {
    const now = new Date();
    const endTime = new Date(now.getTime() + 2 * 60 * 60 * 1000);
    localStorage.setItem('endTime', endTime);
  }, []);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        const totalSeconds = prevTime.hours * 3600 + prevTime.minutes * 60 + prevTime.seconds + 1; // Increment total seconds
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        return { hours, minutes, seconds };
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const { hours, minutes, seconds } = timeLeft;

  return (
    <div className="countdown-container">
      <div className="time-unit">
        <div className="circle">
          <svg>
            <circle cx="50%" cy="50%" r="30"></circle>
            <circle
              cx="50%"
              cy="50%"
              r="30"
              className="hours-circle"
              style={{
                strokeDashoffset: `calc(189 - (189 * ${hours}) / 24)`,
              }}
            ></circle>
          </svg>
          <div className="time-value">{String(hours).padStart(2, '0')}</div>
          <div className="time-label">Hours</div>
        </div>
      </div>

      <div className="time-unit">
        <div className="circle">
          <svg>
            <circle cx="50%" cy="50%" r="30"></circle>
            <circle
              cx="50%"
              cy="50%"
              r="30"
              className="minutes-circle"
              style={{
                strokeDashoffset: `calc(189 - (189 * ${minutes}) / 60)`,
              }}
            ></circle>
          </svg>
          <div className="time-value">{String(minutes).padStart(2, '0')}</div>
          <div className="time-label">Minutes</div>
        </div>
      </div>

      <div className="time-unit">
        <div className="circle">
          <svg>
            <circle cx="50%" cy="50%" r="30"></circle>
            <circle
              cx="50%"
              cy="50%"
              r="30"
              className="seconds-circle"
              style={{
                strokeDashoffset: `calc(189 - (189 * ${seconds}) / 60)`,
              }}
            ></circle>
          </svg>
          <div className="time-value">{String(seconds).padStart(2, '0')}</div>
          <div className="time-label">Seconds</div>
        </div>
      </div>
    </div>
  );
};

export default CountDown;




// import React, { useState, useEffect } from 'react';
// import './index.css'; // Import custom CSS for the countdown design

// const CountDown = () => {
//   const calculateTimeLeft = () => {
//     const now = new Date();
//     const endTime = new Date(localStorage.getItem('endTime') || now.getTime() + 2 * 60 * 60 * 1000);
//     const difference = endTime - now;

//     if (difference <= 0) {
//       return { hours: 0, minutes: 0, seconds: 0 };
//     }

//     // Calculate hours, minutes, and seconds
//     const totalSeconds = Math.floor(difference / 1000);
//     const hours = Math.floor(totalSeconds / 3600);
//     const minutes = Math.floor((totalSeconds % 3600) / 60);
//     const seconds = 60 - (totalSeconds % 60); // Start from 1 and count up

//     return {
//       hours,
//       minutes: (minutes === 0) ? 59 : minutes - 1, // Start from 59 when a minute changes
//       seconds,
//     };
//   };

//   useEffect(() => {
//     const now = new Date();
//     const endTime = new Date(now.getTime() + 2 * 60 * 60 * 1000);
//     localStorage.setItem('endTime', endTime);
//   }, []);

//   const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

//   useEffect(() => {
//     const timer = setInterval(() => {
//       setTimeLeft(calculateTimeLeft());
//     }, 1000);

//     return () => clearInterval(timer);
//   }, []);

//   const { hours, minutes, seconds } = timeLeft;

//   return (
//     <div className="countdown-container">
//       <div className="time-unit">
//         <div className="circle">
//           <svg>
//             <circle cx="50%" cy="50%" r="30"></circle>
//             <circle
//               cx="50%"
//               cy="50%"
//               r="30"
//               className="hours-circle"
//               style={{
//                 strokeDashoffset: `calc(189 - (189 * ${hours}) / 24)`,
//               }}
//             ></circle>
//           </svg>
//           <div className="time-value">{String(hours).padStart(2, '0')}</div>
//           <div className="time-label">Hours</div>
//         </div>
//       </div>

//       <div className="time-unit">
//         <div className="circle">
//           <svg>
//             <circle cx="50%" cy="50%" r="30"></circle>
//             <circle
//               cx="50%"
//               cy="50%"
//               r="30"
//               className="minutes-circle"
//               style={{
//                 strokeDashoffset: `calc(189 - (189 * ${minutes}) / 60)`,
//               }}
//             ></circle>
//           </svg>
//           <div className="time-value">{String(minutes).padStart(2, '0')}</div>
//           <div className="time-label">Minutes</div>
//         </div>
//       </div>

//       <div className="time-unit">
//         <div className="circle">
//           <svg>
//             <circle cx="50%" cy="50%" r="30"></circle>
//             <circle
//               cx="50%"
//               cy="50%"
//               r="30"
//               className="seconds-circle"
//               style={{
//                 strokeDashoffset: `calc(189 - (189 * ${seconds}) / 60)`,
//               }}
//             ></circle>
//           </svg>
//           <div className="time-value">{String(seconds).padStart(2, '0')}</div>
//           <div className="time-label">Seconds</div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CountDown;