import { logowebp } from "../../assets/images";
import FooterForm from "../../components/FooterForm";
// import './index.css'

export default function MainFooterForm() {
    return (
        <>
            <section className="footer-form text-center">
                <div className="container">
                    <div data-aos="fade-down"  data-aos-duration="1500">
                        {/* <h2>Get In Touch</h2> */}
                        <h1>Get in touch <span>with us </span></h1>
                        <p>Have questions or need assistance? Fill out the form below, and our team will get back<br/> to you quickly to discuss how we can help you succeed!</p>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <FooterForm />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}