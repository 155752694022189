import { b2 } from "../../assets/images";
import Button from "../../Components/Button";
import { WEBSITE_NAME } from "../../config";

export default function GetPublishedSection() {
    return (
        <>
            <section className="draft-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className='draft-text'>
                                <h2>Excited to See Your Manuscript Published?</h2>
                                <p>We’re here to help! At {WEBSITE_NAME}, we provide all the services you need for successful book writing and publishing. Here’s how we can assist you:</p>
                                <ul class="mb-5">
                                    <li>E-book Publication</li>
                                    <li>Branding and Publicity</li>
                                    <li>Video Book Trailers</li>
                                    <li>Web Design and SEO</li>
                                    <li>Audiobooks</li>
                                    <li>Cover Design and Typesetting</li>
                                </ul>
                                <Button />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className='draft-image text-center'>
                                <img src={b2} alt='' className='img-fluid'></img>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}