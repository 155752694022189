
import TestimonialSlider from "../../components/TestimonialSlider";
// import './index.css'

export default function Testimonial() {
    return (
        <>
            <section className="review-sec">
                <div className="container">
                    <h2>Clients Testimonials </h2>
                    <h3>What Our Clients Are Saying</h3>
                    <TestimonialSlider/>
                </div>
            </section >
        </>
    )
}