import React, { Suspense, useState, useRef, useEffect } from 'react';
import { WEBSITE_NAME } from '../../config';
import {
    WhyChooseBackImg,
    WhyChooseBookImg,
    CtaSide1,
    PortfolioTop
} from '../../assets/images';

import { useChat } from '../../ChatContext';
import { useModal } from '../../ModalContext';
import homeData from '../../data/home.json';
import MainBanner from '../../GlobalSections/MainBanner';

// Lazy load all components that should load as you scroll
const PartnerLogosSlider = React.lazy(() => import('../../GlobalSections/PartnerLogos'));
const ProcessSlider = React.lazy(() => import('../../components/ProcessSlider'));
const AwardSlider = React.lazy(() => import('../../components/AwardSlider'));
const PortfolioSection = React.lazy(() => import('../../GlobalSections/PortfolioSection'));
const TestimonialSlider = React.lazy(() => import('../../GlobalSections/TestimonialSlider'));
const FaqsAccordion = React.lazy(() => import('../../GlobalSections/FaqsAccordion'));
const StepCardsSection = React.lazy(() => import('../../GlobalSections/StepCardsSection'));
const FooterFormSec = React.lazy(() => import('../../GlobalSections/FooterFormSec'));
const CounterCta = React.lazy(() => import('../../GlobalSections/CounterCta'));
const HomeServicesSec = React.lazy(() => import('../../GlobalSections/HomeServices'));
const BenefitSection = React.lazy(() => import('../../GlobalSections/BenefitsSection'));
const FeaturedBrandSlider = React.lazy(() => import('../../components/FeaturedBrandSlider'));

export default function Home() {
    const { toggleChat } = useChat();
    const { openModal } = useModal();

    // State to track when each section should be shown
    const [showComponents, setShowComponents] = useState({
        partnerLogos: false,
        featuredBrand: false,
        processSlider: false,
        stepCards: false,
        counterCta: false,
        awardSlider: false,
        benefitSection: false,
        portfolioSection: false,
        testimonialSlider: false,
        footerFormSec: false,
        faqsAccordion: false,
        homeServicesSec: false,
    });

    // Refs for each section that needs lazy loading
    const refs = {
        partnerLogos: useRef(null),
        featuredBrand: useRef(null),
        processSlider: useRef(null),
        stepCards: useRef(null),
        counterCta: useRef(null),
        awardSlider: useRef(null),
        benefitSection: useRef(null),
        portfolioSection: useRef(null),
        testimonialSlider: useRef(null),
        footerFormSec: useRef(null),
        faqsAccordion: useRef(null),
        homeServicesSec: useRef(null),
    };

    useEffect(() => {
        const observerCallback = (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const sectionName = entry.target.getAttribute('data-section');
                    setShowComponents(prevState => ({
                        ...prevState,
                        [sectionName]: true,
                    }));
                    observer.unobserve(entry.target); // Stop observing once component is shown
                }
            });
        };

        const observer = new IntersectionObserver(observerCallback, { threshold: 0.1 });

        Object.keys(refs).forEach((key) => {
            if (refs[key].current) {
                observer.observe(refs[key].current);
            }
        });

        return () => {
            Object.keys(refs).forEach((key) => {
                if (refs[key].current) {
                    observer.unobserve(refs[key].current);
                }
            });
        };
    }, []);

    const actionHandlers = {
        openModal,
        toggleChat,
    };

    const replacePlaceholders = (text) => {
        return text.replace(/{{WEBSITE_NAME}}/g, WEBSITE_NAME);
    };

    return (
        <>
            <MainBanner data={homeData} />

            {/* Lazy Load Partner Logos Slider */}
            <div ref={refs.partnerLogos} data-section="partnerLogos" />
            {showComponents.partnerLogos && (
                <Suspense fallback={<div>Loading Partner Logos...</div>}>
                    <PartnerLogosSlider />
                </Suspense>
            )}

            <section className='simple_block white_bg sec_pt sec_pb'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <p className='subTitle'>
                                <i className="fa-solid fa-bolt"></i>
                                {replacePlaceholders(homeData.whyChooseUs.subTitle)}
                            </p>
                            <h2>{homeData.whyChooseUs.title}</h2>
                            {homeData.whyChooseUs.descriptions.map((description, index) => (
                                <p key={index}>{replacePlaceholders(description)}</p>
                            ))}
                            <div className='btn_wrap'>
                                {homeData.whyChooseUs.ctaButtons.map((button, index) => {
                                    const handleClick = actionHandlers[button.handleClick];
                                    return (
                                        <a
                                            key={index}
                                            onClick={handleClick}
                                            href={button.link}
                                            className={`default_btn ${button.className}`}
                                        >
                                            {button.text}
                                        </a>
                                    );
                                })}
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='why_choose_img'>
                                <img src={WhyChooseBackImg} className='WhyChooseBackImg' alt='WhyChooseBackImg' />
                                <img src={WhyChooseBookImg} className='WhyChooseBookImg' alt='WhyChooseBookImg' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Lazy Load Featured Brand Slider */}
            <div ref={refs.featuredBrand} data-section="featuredBrand" />
            {showComponents.featuredBrand && (
                <Suspense fallback={<div>Loading Featured Brand Slider...</div>}>
                    <FeaturedBrandSlider />
                </Suspense>
            )}

            {/* Lazy Load Home Services Section */}
            <div ref={refs.homeServicesSec} data-section="homeServicesSec" />
            {showComponents.homeServicesSec && (
                <Suspense fallback={<div>Loading Home Services...</div>}>
                    <HomeServicesSec data={homeData} />
                </Suspense>
            )}

            {/* Add similar blocks for remaining components */}
            <div ref={refs.processSlider} data-section="processSlider" />
            {showComponents.processSlider && (
                <Suspense fallback={<div>Loading Process Slider...</div>}>
                    <ProcessSlider data={homeData} />
                </Suspense>
            )}

            {/* Add lazy loading for other components */}
            <div ref={refs.awardSlider} data-section="awardSlider" />
            {showComponents.awardSlider && (
                <Suspense fallback={<div>Loading Award Slider...</div>}>
                    <AwardSlider />
                </Suspense>
            )}

            <div ref={refs.portfolioSection} data-section="portfolioSection" />
            {showComponents.portfolioSection && (
                <Suspense fallback={<div>Loading Portfolio Section...</div>}>
                    <section className='portfolio_sec sec_pt sec_pb'>
                        <div className='container'>
                            <div className='row align-items-center justify-content-between'>
                                <div className='col-lg-6'>
                                    <h2>{replacePlaceholders(homeData.Portfolio.title)}</h2>
                                    <p>{replacePlaceholders(homeData.Portfolio.description)}</p>
                                    <div className='btn_wrap'>
                                        {homeData.Portfolio.ctaButtons.map((button, index) => {
                                            const handleClick = actionHandlers[button.handleClick];
                                            return (
                                                <a
                                                    key={index}
                                                    onClick={handleClick}
                                                    href={button.link}
                                                    className={`default_btn ${button.className}`}
                                                >
                                                    {button.text}
                                                </a>
                                            );
                                        })}
                                    </div>
                                </div>
                                <div className='col-lg-4 text-right'>
                                    <img src={PortfolioTop} className='img-fluid' alt='Portfolio' />
                                </div>
                            </div>
                            <PortfolioSection />
                        </div>
                    </section>
                </Suspense>
            )}

            <div ref={refs.testimonialSlider} data-section="testimonialSlider" />
            {showComponents.testimonialSlider && (
                <Suspense fallback={<div>Loading Testimonial Slider...</div>}>
                    <TestimonialSlider data={homeData} />
                </Suspense>
            )}

            <div ref={refs.footerFormSec} data-section="footerFormSec" />
            {showComponents.footerFormSec && (
                <Suspense fallback={<div>Loading Footer Form...</div>}>
                    <FooterFormSec data={homeData} />
                </Suspense>
            )}

            <div ref={refs.faqsAccordion} data-section="faqsAccordion" />
            {showComponents.faqsAccordion && (
                <Suspense fallback={<div>Loading FAQs...</div>}>
                    <FaqsAccordion data={homeData} />
                </Suspense>
            )}
        </>
    );
}
