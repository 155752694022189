import { m1, star, w1 } from '../../assets/images'
import TestimonialSlider from '../../Components/TestimonialSlider'
import { WEBSITE_NAME } from '../../config'
// import './index.css'

export default function Testimonial() {
    return (
        <>
            <section className="testimonial-section py-5">
                <div className="container">
                    <div className="text-center">
                        <h2>Reviews</h2>
                        <p></p>
                        <p>FEEDBACK FROM OUR CLIENTS.</p>
                    </div>
                    <div class="row">
                        <div class="col-md-8 offset-md-2">
                            <TestimonialSlider/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}