import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
// import './index.css'
import { loader, popupimg, popupsideimg } from '../../assets/images';
import { ModalBody } from 'react-bootstrap';


export const ModalSection = ({ open, onCloseModal }) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    phone: '',
  });

  const onSubmitFunc = async (e) => {
    e.preventDefault();

    console.log('formData', formData);
    if (formData.phone.length < 9) {
      setErrors(true);
    } else {
      const currentUrl = window.location.href;
      const protocol = window.location.protocol; // "https:"
      const hostname = window.location.hostname; // "creativeghostwriters.org"

      // Construct the base URL
      const baseUrl = `${protocol}//${hostname}`;
      const queryStringFormData = new URLSearchParams(formData).toString();

      let finalReq = {
        ...formData,
        source: `${currentUrl}${queryStringFormData}`,
        domain: baseUrl,
        lead_url: currentUrl,
        url: `${currentUrl}${queryStringFormData}`,
      };
      try {
        setLoading(true);
        const apiUrl = 'https://tgcrm.net/api/form_submission?brand_key=148453';
        const config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };

        const formDataString = new URLSearchParams(finalReq).toString();

        const response = await axios.post(apiUrl, formDataString, config);
        if (response.status == 200) {
          // toast.success("Thank you for filling out the information");
          // console.log(response.data.data.id);
          const queryString = new URLSearchParams(
            response.data.data
          ).toString();
          console.log('queryString', queryString);
          setLoading(false);
          setErrors(false);
          setFormData({
            name: '',
            email: '',
            message: '',
            phone: '',
          });
          // naviagte(`/thank-you?${queryString}`)
          window.location.href = `https://www.amazonbookwriters.com/thankyou?${queryString}`;
        }
        console.log('responseresponseresponse', response);
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  return (
    <Modal className='popup-wrapper'
      show={open} onHide={onCloseModal}
      size='md'
      aria-labelledby="contained-modal-title-vcenter"
      centered>

      <Modal.Body>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={onCloseModal}><span aria-hidden="true">&times;</span></button>
        <div className='modal-sec'>
          <h1 class="text-uppercase ">Activate Coupon Now</h1>
          <p>HIRE <strong>TOP GHOSTWRITERS AT 30% OFF</strong> TO CREATE YOUR TIMELESS WORK</p>
          <div class="row">
            <div class="col-sm-12">
              <br />
              <form class="form_submission" method="POST" onSubmit={onSubmitFunc}>
                <div class="form-group mb-3">
                  <input type="text" class="form-control" placeholder="Full Name *" name="name" required="" onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
                </div>
                <div class="row">
                  <div class="col-md-6 col-sm-6 col-xs-12">
                    <div class="form-group mb-3">
                      <input type="email" class="form-control" placeholder="Enter Address*" name="email" required="" onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-6 col-xs-12">
                    <div class="form-group mb-3">
                      <input type="number" minlength="10" maxlength="12" class="form-control" placeholder="Phone Number *" name="phone" required="" onChange={(e) => setFormData({ ...formData, phone: e.target.value })} />
                    </div>
                  </div>
                </div>
                <div class="form-group mb-3">
                  <textarea name="Message" class="form-control" placeholder="Enter a brief description about your book" cols="30" rows="5" onChange={(e) => setFormData({ ...formData, message: e.target.value })} ></textarea>
                </div>
                {loading == true ? (
                  <div class="loader">
                    <img alt="loader" src={loader} />
                  </div>
                ) : (
                  <div class="form-group">
                    <button type="submit" value="Submit Now" class="activate">Submit</button>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal >
  );
};
