import React, { useState, useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
import { btmlogo1, btmlogo2, btmlogo3, btmlogo4, btmlogo5, btmlogo6 } from "../../assets/images";

const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowWidth;
};

function BottomBrandLogoSlider() {
    const windowWidth = useWindowWidth();

    const options = {
        loop: true,
        margin: 25,
        responsiveClass: true,
        autoplay: true,
        autoplayHoverPause: true,
        autoplayTimeout: 8000,
        autoplaySpeed: 800,
        navText: [""],
        responsive: {
            0: {
                items: 1,
            },
            425: {
                items: 2,
            },
            575: {
                items: 3,
            },
            768: {
                items: 4,
            },
        }
    };

    return (
        <div className="bottom-brandlogo">
            {windowWidth < 992 ? (
                <OwlCarousel {...options}>
                    <div className="bottom-logo">
                        <img src={btmlogo1} />
                    </div>
                    <div className="bottom-logo">
                        <img src={btmlogo2} />
                    </div>
                    <div className="bottom-logo">
                        <img src={btmlogo3} />
                    </div>
                    <div className="bottom-logo">
                        <img src={btmlogo4} />
                    </div>
                    <div className="bottom-logo">
                        <img src={btmlogo5} />
                    </div>
                    <div className="bottom-logo">
                        <img src={btmlogo6} />
                    </div>
                </OwlCarousel>
            ) : (
                // Render static images for widths below 768px
                <>
                    <div className="bottom-logo">
                        <img src={btmlogo1} />
                    </div>
                    <div className="bottom-logo">
                        <img src={btmlogo2} />
                    </div>
                    <div className="bottom-logo">
                        <img src={btmlogo3} />
                    </div>
                    <div className="bottom-logo">
                        <img src={btmlogo4} />
                    </div>
                    <div className="bottom-logo">
                        <img src={btmlogo5} />
                    </div>
                    <div className="bottom-logo">
                        <img src={btmlogo6} />
                    </div>
                </>
            )}
        </div>
    );
}

export default BottomBrandLogoSlider;
