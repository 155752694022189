import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import AboutPage from './pages/About';
import ServiceLayout from './pages/ServiceLayout';
import PortfolioPage from './pages/Portfolio';
import ContactPage from './pages/contact';
import Marketinglp from './pages/LandingPages/Marketinglp';
import Couponlp from './pages/LandingPages/Couponlp';
import Lp1 from './pages/LandingPages/Lp1';
import BookEditingCompany from './pages/LandingPages/BookEditingCompany';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsConditions from './pages/TermsConditions';
import ReviewsPage from './pages/Reviews';
import ThankYou from './pages/Thankyou';



const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/about-us" element={<AboutPage />} />
    <Route path="/ghostwriting-services" element={<ServiceLayout />} />
    <Route path="/non-fiction-writing" element={<ServiceLayout />} />
    <Route path="/manuscript-writing-service" element={<ServiceLayout />} />
    <Route path="/action-adventure-writing" element={<ServiceLayout />} />
    <Route path="/military-fiction-writing" element={<ServiceLayout />} />
    <Route path="/horror-writing" element={<ServiceLayout />} />
    <Route path="/screenplay-writing" element={<ServiceLayout />} />
    <Route path="/autobiography-service" element={<ServiceLayout />} />
    <Route path="/childrens-book-writing" element={<ServiceLayout />} />
    <Route path="/memoir-writing-services" element={<ServiceLayout />} />
    <Route path="/comic-book-writing-services" element={<ServiceLayout />} />
    <Route path="/biography-writing" element={<ServiceLayout />} />
    <Route path="/fantasy-writing" element={<ServiceLayout />} />
    <Route path="/rhyme-writing" element={<ServiceLayout />} />
    <Route path="/story-writing" element={<ServiceLayout />} />
    <Route path="/science-fiction-writing" element={<ServiceLayout />} />
    <Route path="/book-editing-services" element={<ServiceLayout />} />
    <Route path="/book-proofreading-services" element={<ServiceLayout />} />
    <Route path="/book-cover-design-services" element={<ServiceLayout />} />
    <Route path="/author-website-design" element={<ServiceLayout />} />
    <Route path="/book-illustration-design" element={<ServiceLayout />} />
    <Route path="/book-marketing-services" element={<ServiceLayout />} />
    <Route path="/book-video-trailer" element={<ServiceLayout />} />
    <Route path="/book-publishing" element={<ServiceLayout />} />
    <Route path="/book-printing-services" element={<ServiceLayout />} />
    <Route path="/portfolio" element={<PortfolioPage />} />
    <Route path="/contact-us" element={<ContactPage />} />
    <Route path="/marketing-lp" element={<Marketinglp />} />
    <Route path="/coupon-lp" element={<Couponlp />} />
    <Route path="/lp-1" element={<Lp1 />} />
    <Route path="/book-editing-company" element={<BookEditingCompany />} />
    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    <Route path="/terms-and-conditions" element={<TermsConditions />} />
    <Route path="/reviews" element={<ReviewsPage />} />
    <Route path="/thankyou" element={<ThankYou />} />
  </Routes>
);

export default AppRoutes;
