import Portfoliotabs from '../../components/Portfoliotabs'
// import './index.css'

export default function Portfolio() {
    return (
        <>
            <section className="portfolio-sec">
                <div className="container">
                    <h2 className='portfolio-heading text-center'>
                    Your Journey to Becoming a Published Bestselling Author
                    </h2>
                    <p className='portfolio-text text-center'>Our committed team of skilled ghostwriters and editors will support your vision, while our publishing experts open the door to your new career as an author. Let us guide you on your journey to success!</p>
                    <Portfoliotabs />
                </div>
            </section>
        </>
    )
}