import React,{useState} from 'react'
import { chat } from "../../assets/images";
import { ModalSection } from "../../components/modalSection";
import { WEBSITE_NAME } from "../../config";
// import './index.css'

export default function AboutUs() {
    const [open, setOpen] = useState(false);

    const onOpenModal = () => {
        setOpen(true);
    };

    const onCloseModal = () => {
        setOpen(false);
    };
    return (
        <>
            <section className="about-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <h2 className="heading">Premium Ghostwriting, Editing, Proofreading, and Publishing Services</h2>
                            <p>At Amazon Book Writers, we provide a comprehensive range of services to help you become a successful bestselling author. Our dedicated team of professional ghostwriters is committed to delivering quality work across all book genres.<br/> We pride ourselves on our talented quality assurance team, which includes skilled editors and proofreaders who ensure every detail is perfect. From crafting your manuscript to editing and publishing, we’re here to support your journey every step of the way. Let us help you launch your career as a bestselling writer!</p>
                            <div className='block'>
                                <a href='Javascript:;' className='btn about-button' onClick={onOpenModal}>ACTIVATE YOUR COUPON</a>
                                <a href='Javascript:;' className='chat chat-button'><img src={chat} alt />CHAT WITH US</a>
                            </div>
                        </div>
                        <div className="col-lg-6">

                        </div>
                    </div>
                </div>
            </section>
            <ModalSection open={open} onCloseModal={onCloseModal} />
        </>
    )
}