import React, { useState, useEffect, Suspense, useRef } from 'react';
import { WEBSITE_NAME, PHONE, PHONE_HREF } from '../../config';
import { useLocation } from 'react-router-dom';
import { PortfolioTop } from '../../assets/images';
import { useChat } from '../../ChatContext';
import { useModal } from '../../ModalContext';

// Lazy load all components for performance optimization
const PartnerLogosSlider = React.lazy(() => import('../../GlobalSections/PartnerLogos'));
const PortfolioSection = React.lazy(() => import('../../GlobalSections/PortfolioSection'));
const TestimonialSlider = React.lazy(() => import('../../GlobalSections/TestimonialSlider'));
const FaqsAccordion = React.lazy(() => import('../../GlobalSections/FaqsAccordion'));
const BreadcrumbCustom = React.lazy(() => import('../../components/Breadcrumb'));
const GhostwritingServices = React.lazy(() => import('../../components/GhostwritingServices'));
const CtaSection = React.lazy(() => import('../../GlobalSections/Cta'));
const InnerServicesProcess = React.lazy(() => import('../../GlobalSections/InnerServicesProcess'));
const StepCardsSection = React.lazy(() => import('../../GlobalSections/StepCardsSection'));
const CounterCta = React.lazy(() => import('../../GlobalSections/CounterCta'));
const ServiceIntroSec = React.lazy(() => import('../../GlobalSections/ServiceIntroSec'));
const ServiceIntroSec2 = React.lazy(() => import('../../GlobalSections/ServiceIntroSec2'));
const ServiceIntroSec3 = React.lazy(() => import('../../GlobalSections/ServiceIntroSec3'));
const MainBanner = React.lazy(() => import('../../GlobalSections/MainBanner'));
const FooterFormSec = React.lazy(() => import('../../GlobalSections/FooterFormSec'));

export default function ServiceLayout() {
    const { toggleChat } = useChat();
    const { openModal } = useModal();
    const actionHandlers = {
        openModal,
        toggleChat,
    };
    const location = useLocation();

    const isGhostwritingServicesPage = location.pathname.replace(/\/$/, '') === '/ghostwriting';
    const [serviceData, setServiceData] = useState(null);
    const [showComponents, setShowComponents] = useState({}); // Track which components should be shown

    const replacePlaceholders = (text) => {
        return text
            .replace(/{{PHONE}}/g, PHONE)
            .replace(/{{PHONE_HREF}}/g, PHONE_HREF)
            .replace(/{{WEBSITE_NAME}}/g, WEBSITE_NAME);
    };

    // Load data dynamically based on the current route
    useEffect(() => {
        const loadData = async () => {
            try {
                let data;
                let servicesdata = await import('../../data/services/services.json');
                switch (location.pathname.replace(/\/$/, '')) {
                    case '/ghostwriting-services':
                        data = servicesdata.ghostwriting;
                        break;
                    case '/non-fiction-writing':
                        data = servicesdata.nonfiction;
                        break;
                    case '/memoir-writing-services':
                        data = servicesdata.memoirwriting;
                        break;
                    case '/manuscript-writing-service':
                        data = servicesdata.manuscript;
                        break;
                    case '/comic-book-writing-services':
                        data = servicesdata.comicbook;
                        break;
                    case '/action-adventure-writing':
                        data = servicesdata.actionadventure;
                        break;
                    case '/biography-writing':
                        data = servicesdata.biography;
                        break;
                    case '/military-fiction-writing':
                        data = servicesdata.militaryfiction;
                        break;
                    case '/fantasy-writing':
                        data = servicesdata.fantasy;
                        break;
                    case '/horror-writing':
                        data = servicesdata.horrorwriting;
                        break;
                    case '/rhyme-writing':
                        data = servicesdata.rhymewriting;
                        break;
                    case '/screenplay-writing':
                        data = servicesdata.screenplay;
                        break;
                    case '/story-writing':
                        data = servicesdata.storywriting;
                        break;
                    case '/autobiography-service':
                        data = servicesdata.autobiography;
                        break;
                    case '/science-fiction-writing':
                        data = servicesdata.sciencefiction;
                        break;
                    case '/childrens-book-writing':
                        data = servicesdata.childrensbook;
                        break;
                    case '/book-editing-services':
                        data = servicesdata.bookediting;
                        break;
                    case '/book-proofreading-services':
                        data = servicesdata.proofreading;
                        break;
                    case '/book-cover-design-services':
                        data = servicesdata.bookcover;
                        break;
                    case '/author-website-design':
                        data = servicesdata.authorwebsite;
                        break;
                    case '/book-illustration-design':
                        data = servicesdata.illustration;
                        break;
                    case '/book-marketing-services':
                        data = servicesdata.marketing;
                        break;
                    case '/book-video-trailer':
                        data = servicesdata.videotrailer;
                        break;
                    case '/book-publishing':
                        data = servicesdata.publishing;
                        break;
                    case '/book-printing-services':
                        data = servicesdata.printing;
                        break;
                    // Add cases for other paths as needed
                    default:
                        console.error("No data available for this route");
                        return;
                }
                setServiceData(data);
            } catch (error) {
                console.error("Error loading service data:", error);
            }
        };

        loadData();
    }, [location.pathname]);

    // Set up Intersection Observer to lazy load components
    const componentRefs = {
        ghostwritingServices: useRef(null),
        ctaSection: useRef(null),
        innerServicesProcess: useRef(null),
        stepCardsSection: useRef(null),
        counterCta: useRef(null),
        serviceIntroSec2: useRef(null),
        serviceIntroSec3: useRef(null),
        portfolioSection: useRef(null),
        testimonialSlider: useRef(null),
        faqsAccordion: useRef(null),
        footerFormSec: useRef(null),
    };

    useEffect(() => {
        const observerCallback = (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const componentName = entry.target.getAttribute('data-component');
                    setShowComponents((prev) => ({
                        ...prev,
                        [componentName]: true,
                    }));
                    observer.unobserve(entry.target); // Stop observing once loaded
                }
            });
        };

        const observerOptions = {
            root: null, // Observe relative to the viewport
            threshold: 0.1, // Trigger when 10% of the element is visible
        };

        const observer = new IntersectionObserver(observerCallback, observerOptions);

        Object.keys(componentRefs).forEach((key) => {
            if (componentRefs[key].current) {
                observer.observe(componentRefs[key].current);
            }
        });

        return () => {
            Object.keys(componentRefs).forEach((key) => {
                if (componentRefs[key].current) {
                    observer.unobserve(componentRefs[key].current);
                }
            });
        };
    }, [componentRefs]);

    if (!serviceData) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <MainBanner data={serviceData} />
            <BreadcrumbCustom />
            <PartnerLogosSlider />
            <ServiceIntroSec data={serviceData} />

            {/* Lazy Load Ghostwriting Services Section */}
            {isGhostwritingServicesPage && (
                <>
                    <div ref={componentRefs.ghostwritingServices} data-component="ghostwritingServices" />
                    {showComponents.ghostwritingServices && (
                        <Suspense fallback={<div>Loading Ghostwriting Services...</div>}>
                            <GhostwritingServices data={serviceData} />
                        </Suspense>
                    )}
                </>
            )}

            {/* Lazy Load CTA Section */}
            <div ref={componentRefs.ctaSection} data-component="ctaSection" />
            {showComponents.ctaSection && (
                <Suspense fallback={<div>Loading CTA Section...</div>}>
                    <CtaSection data={serviceData} />
                </Suspense>
            )}

            {/* Lazy Load Inner Services Process */}
            <div ref={componentRefs.innerServicesProcess} data-component="innerServicesProcess" />
            {showComponents.innerServicesProcess && (
                <Suspense fallback={<div>Loading Inner Services Process...</div>}>
                    <InnerServicesProcess data={serviceData} />
                </Suspense>
            )}

            {/* Lazy Load Step Cards Section */}
            {!isGhostwritingServicesPage && (
                <>
                    <div ref={componentRefs.stepCardsSection} data-component="stepCardsSection" />
                    {showComponents.stepCardsSection && (
                        <Suspense fallback={<div>Loading Step Cards Section...</div>}>
                            <StepCardsSection data={serviceData} />
                        </Suspense>
                    )}
                </>
            )}

            {/* Lazy Load Counter CTA Section */}
            <div ref={componentRefs.counterCta} data-component="counterCta" />
            {showComponents.counterCta && (
                <Suspense fallback={<div>Loading Counter CTA...</div>}>
                    <CounterCta data={serviceData} />
                </Suspense>
            )}

            {/* Lazy Load Service Intro Section 2 */}
            <div ref={componentRefs.serviceIntroSec2} data-component="serviceIntroSec2" />
            {showComponents.serviceIntroSec2 && (
                <Suspense fallback={<div>Loading Service Intro 2...</div>}>
                    <ServiceIntroSec2 data={serviceData} />
                </Suspense>
            )}

            {/* Lazy Load Service Intro Section 3 */}
            <div ref={componentRefs.serviceIntroSec3} data-component="serviceIntroSec3" />
            {showComponents.serviceIntroSec3 && (
                <Suspense fallback={<div>Loading Service Intro 3...</div>}>
                    <ServiceIntroSec3 data={serviceData} />
                </Suspense>
            )}

            {/* Lazy Load Portfolio Section */}
            <div ref={componentRefs.portfolioSection} data-component="portfolioSection" />
            {showComponents.portfolioSection && (
                <Suspense fallback={<div>Loading Portfolio Section...</div>}>
                    <section className='portfolio_sec sec_pt sec_pb'>
                        <div className='container'>
                            <div className='row align-items-center justify-content-between'>
                                <div className='col-lg-6'>
                                    <h2>{replacePlaceholders(serviceData.Portfolio.title)}</h2>
                                    <p>{replacePlaceholders(serviceData.Portfolio.description)}</p>
                                    <div className='btn_wrap'>
                                        {serviceData.Portfolio.ctaButtons.map((button, index) => {
                                            const handleClick = actionHandlers[button.handleClick];
                                            return (
                                                <a
                                                    key={index}
                                                    onClick={handleClick}
                                                    href={button.link}
                                                    className={`default_btn ${button.className}`}
                                                >
                                                    {button.text}
                                                </a>
                                            );
                                        })}
                                    </div>
                                </div>
                                <div className='col-lg-4 text-right'>
                                    <img src={PortfolioTop} className='img-fluid' alt='Portfolio' />
                                </div>
                            </div>
                            <PortfolioSection />
                        </div>
                    </section>
                </Suspense>
            )}

            {/* Lazy Load Testimonial Slider */}
            <div ref={componentRefs.testimonialSlider} data-component="testimonialSlider" />
            {showComponents.testimonialSlider && (
                <Suspense fallback={<div>Loading Testimonial Slider...</div>}>
                    <TestimonialSlider data={serviceData} />
                </Suspense>
            )}

            {/* Lazy Load FAQs Accordion */}
            <div ref={componentRefs.faqsAccordion} data-component="faqsAccordion" />
            {showComponents.faqsAccordion && (
                <Suspense fallback={<div>Loading FAQs...</div>}>
                    <FaqsAccordion data={serviceData} />
                </Suspense>
            )}

            {/* Lazy Load Footer Form Section */}
            <div ref={componentRefs.footerFormSec} data-component="footerFormSec" />
            {showComponents.footerFormSec && (
                <Suspense fallback={<div>Loading Footer Form Section...</div>}>
                    <FooterFormSec data={serviceData} />
                </Suspense>
            )}
        </>
    );
}
