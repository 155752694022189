import React, { useState,useEffect } from 'react';
// import './index.css'
import { ModalSection } from "../../Components/modalSection";


export default function Button() {

    const [open, setOpen] = useState(false);

    const onOpenModal = () => {
        setOpen(true);
    };

    const onCloseModal = () => {
        setOpen(false);
    };  

    return (
        <>
            <a href='Javascript:;' className='theme-btn' onClick={onOpenModal} > GET A QUOTE <i class="fas fa-arrow-right" aria-hidden="true"></i> </a>
            <a href='Javascript:;' className='chat theme-btn'> START LIVE CHAT <i class="fas fa-arrow-right" aria-hidden="true"></i> </a>
            <ModalSection open={open} onCloseModal={onCloseModal} />
        </>
    )
}