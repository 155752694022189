import { ghost1 } from '../../assets/images'
import MainButton from '../../components/MainButton'
import ServiceSlider from '../../components/ServiceSlider'
// import './index.css'

export default function Services() {
    return (
        <>
            <section className="service-sec">
                <div className="container">
                    <h2>Explore Our Comprehensive Services</h2>
                    <p>We offer a wide range of services to support your journey as an author.<br />
                    Check out what we can do for you:</p>
                    <ServiceSlider />
                    <MainButton position="center" />
                </div>
            </section>
        </>
    )
}