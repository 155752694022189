import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { EMAIL, EMAIL_HREF } from '../../config';
import './index.css';
import { Loader } from '../../assets/images';

export const SideContact = ({ open, onCloseModal }) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);

  const [isActive, setIsActive] = useState(false);

  const handleToggle = () => {
    setIsActive((prevState) => !prevState);
  };

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    phone: '',
  });



  const onSubmitFunc = async (e) => {
    e.preventDefault();

    console.log('formData', formData);
    if (formData.phone.length < 9) {
      setErrors(true);
    } else {
      const currentUrl = window.location.href;
      const protocol = window.location.protocol;
      const hostname = window.location.hostname;
      const baseUrl = `${protocol}//${hostname}`;
      const queryStringFormData = new URLSearchParams(formData).toString();

      let finalReq = {
        ...formData,
        source: `${currentUrl}${queryStringFormData}`,
        domain: baseUrl,
        lead_url: currentUrl,
        url: `${currentUrl}${queryStringFormData}`,
      };

      try {
        setLoading(true);
        const apiUrl = 'https://tgcrm.net/api/form_submission?brand_key=148453';
        const config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };
        const formDataString = new URLSearchParams(finalReq).toString();



        const response = await axios.post(apiUrl, formDataString, config);
        if (response.status === 200) {
          const queryString = new URLSearchParams(
            response.data.data
          ).toString();
          console.log('queryString', queryString);
          setLoading(false);
          setErrors(false);
          setFormData({
            name: '',
            email: '',
            message: '',
            phone: '',
          });
          window.location.href = `https://www.amazonbookwriters.com/thankyou?${queryString}`;
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  return ReactDOM.createPortal(
    <div className='side_floating'>
      <div class="side-contact">
        <a href={EMAIL_HREF}><i class="fa fa-envelope"></i><span>{EMAIL}</span></a>
        <a href="javascript:;" class="open_chat_btn chat"><i class="fa fa-comments"></i>Talk to us
        </a>
      </div>
      <div className={isActive ? 'side-form-wrapper open-side-form' : 'side-form-wrapper'} >
        <button class="side-form-btn" onClick={handleToggle}>Contact Our Book Writing Experts</button>
        <div class="side-form">
          <form class="form_submission" method="POST" onSubmit={onSubmitFunc}>
            <h3>Get your book writing started today.</h3>
            <input type="text" name="name" class="form-control m-2 c_field" placeholder="Name" required='' onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
            <input type="email" name="email" class="form-control m-2 c_field" placeholder="Email" required='' onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
            <input type="number" name="phone" class="form-control m-2 facility_phone_number c_field phone-number"
              minlength="10" maxlength="12" placeholder="Phone" required='' onChange={(e) => setFormData({ ...formData, phone: e.target.value })} />
            <textarea name="meassage" cols="30" class="form-control m-2 c_field" placeholder="Message" required='' onChange={(e) => setFormData({ ...formData, meassage: e.target.value })}></textarea>
            {loading == true ? (
              <div className="loader">
                <img alt="loader" src={Loader} />
              </div>
            ) : (
              <button data-nextFormID="secondFormSidebar" type="submit" class="default_btn white_btn w-100">Submit</button>
            )
            }
          </form>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default SideContact;
