import { chat, logo } from "../../assets/images";
import CountDown from "../../components/CountDown";
import { ModalSection } from "../../components/modalSection";
// import './index.css'
import React, { useState,useEffect } from "react";

export default function Header() {
    useEffect(() => {
        // Function to open chat window
        const openChatWindow = () => {
            if (window.$zopim) {
                window.$zopim.livechat.window.show();
            }
        };

        // Function to toggle chat window
        const toggleChat = () => {
            if (window.$zopim) {
                window.$zopim.livechat.window.toggle();
            }
        };

        // Initialize chat and open by default
        const initializeChat = () => {
            const intervalId = setInterval(() => {
                if (window.$zopim && window.$zopim.livechat) {
                    clearInterval(intervalId);
                    openChatWindow();
                    listenForNewMessages();
                }
            }, 100); // Check every 100ms if chat widget is available
        };

        // Listen for new messages
        const listenForNewMessages = () => {
            if (window.$zopim && window.$zopim.livechat) {
                window.$zopim.livechat.setOnUnreadMsgs((numberOfUnreadMessages) => {
                    if (numberOfUnreadMessages > 0) {
                        openChatWindow();
                    }
                });
            }
        };

        initializeChat();
        const chatButtons = document.querySelectorAll('.chat'); // Select all buttons with the class 'chat'

        chatButtons.forEach(button => {
            button.addEventListener('click', toggleChat); // Add click event to each button
        });

        // Cleanup: Remove event listeners when the component unmounts
        return () => {
            chatButtons.forEach(button => {
                button.removeEventListener('click', toggleChat); // Remove click event from each button
            });
        };
    }, []);
    const [open, setOpen] = useState(false);

    const onOpenModal = () => {
        setOpen(true);
    };

    const onCloseModal = () => {
        setOpen(false);
    };
    return (
        <>
            <header>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-4 ">
                            <a href="#" className="logo">
                                <img src={logo} alt="logo" />
                            </a>
                        </div>
                        <div className="col-md-8">
                            <ul className="text align-items-center">
                                <li>
                                    <img src={chat} alt="" />
                                    <a href="Javascript:;" className="chat">LIVE CHAT</a>
                                </li>
                                <li>
                                    <a href="Javascript:;" className="btn header-btn" onClick={onOpenModal}>
                                        ACTIVATE YOUR COUPON
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
            <ModalSection open={open} onCloseModal={onCloseModal} />
        </>
    )
}