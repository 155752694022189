import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { ADDRESS, EMAIL, EMAIL_HREF, PHONE, PHONE_HREF, WEBSITE_NAME } from '../../config';
import './index.css';
import ContactFlow from '../../components/ContactFlow';
import { Loader } from '../../assets/images';

const FooterFormSec = ({ data }) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const location = useLocation();
  const isContactPage = location.pathname.replace(/\/$/, '') === '/contact-us';

  const replacePlaceholders = (text) => {
    if (!text || !text.includes('{{WEBSITE_NAME}}')) {
      // If text is undefined/null or does not contain the placeholder, return it as is
      return text;
    }
    // Replace {{WEBSITE_NAME}} if found in text
    return text.replace(/{{WEBSITE_NAME}}/g, WEBSITE_NAME);
  };

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    phone: '',
  });
  console.log('Rendering Contact Info Boxes', isContactPage);

  const onSubmitFunc = async (e) => {
    e.preventDefault();

    console.log('formData', formData);
    if (formData.phone.length < 9) {
      setErrors(true);
    } else {
      const currentUrl = window.location.href;
      const protocol = window.location.protocol;
      const hostname = window.location.hostname;
      const baseUrl = `${protocol}//${hostname}`;
      const queryStringFormData = new URLSearchParams(formData).toString();

      let finalReq = {
        ...formData,
        source: `${currentUrl}${queryStringFormData}`,
        domain: baseUrl,
        lead_url: currentUrl,
        url: `${currentUrl}${queryStringFormData}`,
      };

      try {
        setLoading(true);
        const apiUrl = 'https://tgcrm.net/api/form_submission?brand_key=148453';
        const config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };
        const formDataString = new URLSearchParams(finalReq).toString();



        const response = await axios.post(apiUrl, formDataString, config);
        if (response.status === 200) {
          const queryString = new URLSearchParams(
            response.data.data
          ).toString();
          console.log('queryString', queryString);
          setLoading(false);
          setErrors(false);
          setFormData({
            name: '',
            email: '',
            message: '',
            phone: '',
          });
          window.location.href = `https://www.amazonbookwriters.com/thankyou?${queryString}`;
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  return (
    <section className='contact_sec sec_pt sec_pb'>
      <div className='container'>
        {data?.contactSection?.subTitle && (
          <p className='subTitle text_red_clr text-center'>{replacePlaceholders(data.contactSection.subTitle)}</p>
        )}
        <h2 className='text-center' dangerouslySetInnerHTML={{ __html: replacePlaceholders(data.contactSection.title) }}></h2>
        <p className='text-center'>{replacePlaceholders(data.contactSection.description)}</p>
        <div className='row contact_wrapper'>
          <div className='col-md-7'>
            <div className='footer_form_area'>
              <h3 className='text-center'>Tell Us About Your Project</h3>
              <form class="form_submission" method="POST" onSubmit={onSubmitFunc}>
                <input type="text" placeholder="Your Name" name="name" required="" onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
                <input type="email" placeholder="Your Email" name="email" required="" onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
                <input class="phone-number" type="number" placeholder="Your Phone Number" name="phone" required=""
                  minlength="10" maxlength="12" onChange={(e) => setFormData({ ...formData, phone: e.target.value })} />
                <textarea name="message" rows="5" placeholder="Enter Message" onChange={(e) => setFormData({ ...formData, message: e.target.value })}></textarea>
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" name="send-nda" value="yes" id="exampleCheck1" />
                  <label class="form-check-label" for="exampleCheck1">Send me your NDA</label>
                </div>
                {loading == true ? (
                  <div className="loader">
                    <img alt="loader" src={Loader} />
                  </div>
                ) : (
                  <button type="submit" className='default_btn white_btn'>Submit</button>
                )
                }
              </form>
            </div>
          </div>
          <div className='col-md-5'>
            {!isContactPage && <ContactFlow data={data} />}

            {isContactPage && (
              <>
                <div className='contact_info_box'>
                  <i class="fa-solid fa-phone"></i>
                  <h3>Call Us</h3>
                  <a href={PHONE_HREF}>{PHONE}</a>
                </div>
                <div className='contact_info_box'>
                  <i class="fa-solid fa-envelope"></i>
                  <h3>Mail Us</h3>
                  <a href={EMAIL_HREF}>{EMAIL}</a>
                </div>
                <div className='contact_info_box'>
                  <i class="fa-solid fa-location-dot"></i>
                  <h3>Head Office:</h3>
                  <p>{ADDRESS}</p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FooterFormSec;