import { greentick } from '../../assets/images'
import { ModalSection } from '../../components/modalSection';
import PackagesSlider from '../../components/PackagesSlider'
// import './index.css'
import React, { useState } from 'react';

export default function PackagesSection() {
    const [open, setOpen] = useState(false);

    const onOpenModal = () => {
        setOpen(true);
    };

    const onCloseModal = () => {
        setOpen(false);
    };
    return (
        <>
            <section className="packages-sec">
                <div className="container">
                    <h2 className="packages-heading">Our Pricing Plan </h2>
                    <p className="packages-text">Explore our comprehensive pricing plans that cater to every author's needs, ensuring a seamless path from idea to published book.</p>
                    <div className="row">
                        <PackagesSlider onOpenModal={onOpenModal} />
                    </div>
                </div>
                <ModalSection open={open} onCloseModal={onCloseModal} />
            </section>

        </>
    )
}