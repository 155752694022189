const Admin_Panel = 'info@amazonbookwriters.com';
const PHONE = '(855) 587-7622';
const PHONE_HREF = 'tel:8555877622';
const PHONE_TWO = '(855) 587-7622';
const PHONE_TWO_HREF = 'tel:8555877622';
const EMAIL = 'info@amazonbookwriters.com';
const EMAIL_HREF = 'mailto:info@amazonbookwriters.com';
const CONTACT_EMAIL = 'info@amazonbookwriters.com';
const CONTACT_EMAIL_HREF = 'mailto:info@amazonbookwriters.com';
const ADDRESS = '75 Broadway, San Francisco, CA 94111, United States';
const WEBSITE_NAME = 'Amazon Book Writers';
const SITE_CURRENCY_SYMBOLS = '$';
const WEBSITE_LOGO =
  'https://www.creativeghostwriters.org/logo.png';

export {
  Admin_Panel,
  PHONE,
  PHONE_HREF,
    PHONE_TWO,
    PHONE_TWO_HREF,
  EMAIL,
  EMAIL_HREF,
  CONTACT_EMAIL,
  CONTACT_EMAIL_HREF,
  ADDRESS,
  WEBSITE_NAME,
  SITE_CURRENCY_SYMBOLS,
  WEBSITE_LOGO,
};
