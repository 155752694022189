import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import { ModalImg, Loader } from '../../assets/images'
import { WEBSITE_NAME } from '../../config';
import './index.css';
export const ModalSection = ({ open, onCloseModal }) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    phone: '',
  });


  const onSubmitFunc = async (e) => {
    e.preventDefault();

    console.log('formData', formData);
    if (formData.phone.length < 9) {
      setErrors(true);
    } else {
      const currentUrl = window.location.href;
      const protocol = window.location.protocol;
      const hostname = window.location.hostname;
      const baseUrl = `${protocol}//${hostname}`;
      const queryStringFormData = new URLSearchParams(formData).toString();

      let finalReq = {
        ...formData,
        source: `${currentUrl}${queryStringFormData}`,
        domain: baseUrl,
        lead_url: currentUrl,
        url: `${currentUrl}${queryStringFormData}`,
      };

      try {
        setLoading(true);
        const apiUrl = 'https://tgcrm.net/api/form_submission?brand_key=148453';
        const config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };
        const formDataString = new URLSearchParams(finalReq).toString();



        const response = await axios.post(apiUrl, formDataString, config);
        if (response.status === 200) {
          const queryString = new URLSearchParams(
            response.data.data
          ).toString();
          console.log('queryString', queryString);
          setLoading(false);
          setErrors(false);
          setFormData({
            name: '',
            email: '',
            message: '',
            phone: '',
          });
          window.location.href = `https://www.amazonbookwriters.com/thankyou?${queryString}`;
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  return ReactDOM.createPortal(
    <Modal id="penguinBackdrop" className='brand-modal home'
      show={open}
      onHide={onCloseModal}
      size='xl'
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className=''>
        <div className="position-relative">
          <a href='javascript:;' onClick={onCloseModal} className="cross"><i class="fa-solid fa-xmark"></i></a>
        </div>
        {/* Static content goes here */}
        <div class="row align-items-center">
          <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
            <div class="popup-text-wrap">
              <h3>Start with <span>{WEBSITE_NAME}!</span></h3>
              <div class="popup-form-wrap">
                <form class="form_submission" method="POST" onSubmit={onSubmitFunc}>
                  <div class="popup-field">
                    <span><i class="fa fa-user"></i></span>
                    <input type="text" placeholder="Full Name*" name="name" required="" onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
                  </div>
                  <div class="popup-field">
                    <span><i class="fa fa-user"></i></span>
                    <input type="email" name="email" placeholder="Email Address*" required="" onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
                  </div>
                  <div class="popup-field">
                    <span class="popup-phone-icon"><i class="fa fa-phone"></i></span>
                    <input class="phone-number" type="number" placeholder="Phone Number*"
                      minlength="10" maxlength="12" name="phone" required="" onChange={(e) => setFormData({ ...formData, phone: e.target.value })} />
                  </div>
                  {loading == true ? (
                    <div className="loader">
                      <img alt="loader" src={Loader} />
                    </div>
                  ) : (
                    <button type="submit">Let's Start Your Project</button>
                  )
                  }
                </form>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 d-none d-lg-block">
            <img class="pop-img img-fluid" src={ModalImg} />
          </div>
        </div>
      </Modal.Body>
    </Modal>,
    document.body
  );
};

export default ModalSection;
