import { bbc1, bbc2, bbc3, bbc4, bbc5, bbc6, check, star } from '../../assets/images'
import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export default function TestimonialSlider() {
    const settings = {
        loop: true,
        nav: false,
        dots: true,
        margin: 10,
        responsiveClass: true,
        autoplay: true,
        autoplayHoverPause: true,
        autoplayTimeout: 8000,
        autoplaySpeed: 800,
        navText: [""],
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 1,
            },
            992: {
                items: 2,
            },
            360: {
                items: 1,
            }
        }
    }
    return (
        <OwlCarousel className='testimonial-slider owl-carousel owl-theme' {...settings}>
            <div className='item'>
                <img src={star} alt='stars' />
                <p >
                    "So far, I've experienced the best novel writing services. Their pricing is unbeatable. The team is knowledgeable and goes the extra mile when it comes to writing and publishing. Thanks to them, I was able to get my novel published in the best way possible. Their work stands out due to the high quality of writing, vocabulary choice, and thorough research."
                </p>
                <h4>
                    Moses Johnson
                </h4>
            </div>
            <div className='item'>
                <img src={star} alt='stars' />
                <p>
                    "The entire process was seamless and enjoyable. The team was attentive to my ideas and helped shape them into a polished manuscript. I couldn’t be happier with the results!"
                </p>
                <h4>
                    Jodi Carroll
                </h4>
            </div>
            <div className='item'>
                <img src={star} alt='stars' />
                <p>
                    "Everyone on the team worked closely with me and kept me updated. My book is so well written that it might become a bestseller. I highly recommend them if you're looking for a premium ebook writing company at great prices!"
                </p>
                <h4>
                    Monica Gibbs
                </h4>
            </div>
        </OwlCarousel>
    )
}