import React,{useState} from "react";
import { logowebp } from "../../assets/images";
import { ModalSection } from "../../components/modalSection";
import { PHONE, PHONE_HREF } from "../../config";
// import './index.css'

export default function Header() {
    const [open, setOpen] = useState(false);

    const onOpenModal = () => {
        setOpen(true);
    };

    const onCloseModal = () => {
        setOpen(false);
    };
    return (
        <>
            <header>
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-lg-3 col-md-5 col-sm-6">
                            <div className="header-logo">
                                <img src={logowebp} className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-6 col-md-7 col-sm-6">
                            <div className="tell-us">
                                <ul className="talk mb-0 pl-0">
                                    <li>
                                        <a href="Javascript:;" className="chat"><i class="fa-solid fa-message"></i>TALK TO US</a>
                                    </li>
                                    <li>
                                        <a href={PHONE_HREF} className="phone"><i class="fa-solid fa-phone-volume"></i>{PHONE}</a>
                                    </li>
                                </ul>
                                <a href="Javascript:;" className="header-button" onClick={onOpenModal}>Get a Free Quote</a>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <ModalSection open={open} onCloseModal={onCloseModal} />
        </>
    )
}