import {WEBSITE_NAME, PHONE, PHONE_HREF} from '../../config';
import axios from 'axios';
import {
    GiveStoryImg,
    WhyChooseBackImg
} from '../../assets/images';
import { useChat } from '../../ChatContext';
import { useModal } from '../../ModalContext';
import Breadcrumb from '../../components/Breadcrumb';
import CtaSection from '../../GlobalSections/Cta';
import FooterFormSec from '../../GlobalSections/FooterFormSec';
import PortfolioData from '../../data/portfolio.json';
import InnerBanner from '../../GlobalSections/InnerBanner';
import PortfolioSection from '../../GlobalSections/PortfolioSection';
import CategoryPortfolio from '../../components/CategoryPortfolio';
import PartnerLogosSlider from '../../GlobalSections/PartnerLogos';

export default function PortfolioPage() {
    const { toggleChat } = useChat();
    const { openModal } = useModal();
    const replacePlaceholders = (text) => {
        return text
            .replace(/{{WEBSITE_NAME}}/g, WEBSITE_NAME);
    };

    return (
        <>  
            <InnerBanner data={PortfolioData}/>
            <Breadcrumb/>

            <section className='portfolio_sec sec_pt sec_pb'>
                <div className='container'>
                    <h2 className='text-center'>{PortfolioData.PortfolioSecData.title}</h2>
                    <PortfolioSection/>
                </div>
            </section>

            <PartnerLogosSlider data={PortfolioData}/>

            <CategoryPortfolio/>
            
            
            <FooterFormSec data={PortfolioData}/>
        </>
    );
}