import { featuresimg } from "../../assets/images";
// import './index.css'

export default function FeatureSection() {
    return (
        <>
            <section className="feature-sec">
                <div className="container">
                    <h2>Our premium ghostwriting and publishing services are here to help you achieve your writing dreams with ease and expertise.</h2>
                    <img src={featuresimg} className="img-fluid"/>
                </div>
            </section>
        </>
    )
}