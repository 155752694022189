import BannerForm from '../../components/BannerForm'
// import './index.css'

export default function MainBanner() {
    return (
        <>
            <section className='marketing banner-sec'>
                <div className='container'>
                    <div className='banner-text' data-aos="zoom-in" data-aos-duration="1500" data-aos-delay='200'>
                        <h1 >
                            <span>Expert</span> Book <span>Marketing </span>Services
                        </h1>
                        <p>Are you struggling to sell more books? <br />Our marketing services are designed to help you reach a broader audience and maximize your book's potential. Whether you’re a first-time author or a seasoned writer, we have the tools and strategies to ensure your book gets noticed in a crowded market.</p>
                    </div>
                    <div className='marketing-banner-form'>
                        <BannerForm />
                    </div>
                </div>
            </section>
        </>
    )
}