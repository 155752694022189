import { format, publish, researcicon, review, writing } from '../../assets/images'
import ServiceSlider from '../../Components/ServiceSlider'
// import './index.css'

export default function Services() {
    return (
        <>
            <section class="service-sec py-md-5">
                <div className="container">
                    <ServiceSlider />
                </div>
            </section>
        </>
    )
}