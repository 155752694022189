import React, { useState } from 'react';
import axios from 'axios';
import {
    WEBSITE_NAME
} from '../../config/index'
import { loader } from '../../assets/images';

const BannerForm = () => {
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        phone: '',
    });

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(false);

    const nextPrev = (n) => {
        if (n === 1) {
            // Moving forward
            if (step === 1 && formData.name === '') {
                setStep(2);
            } else if (step === 2 && formData.email === '') {
                setStep(3);
            } else if (step === 3 && formData.phone === '') {
                //    setStep(4);
                // } else if (step === 4 && formData.message !== '') {
                //    submitForm();
            } else if (step < 3) {
                setStep(step + n);
            }
        } else {
            // Moving backward
            if (step > 1) {
                setStep(step + n);
            }
        }
    };

    const submitForm = async (e) => {
        e.preventDefault()
        // No event parameter here, so we don't call preventDefault
        console.log('formData', formData);
        if (formData.phone.length < 9) {
            setErrors(true);
        } else {
            const currentUrl = window.location.href;
            const protocol = window.location.protocol;
            const hostname = window.location.hostname;

            const baseUrl = `${protocol}//${hostname}`;
            const queryStringFormData = new URLSearchParams(formData).toString();

            let finalReq = {
                ...formData,
                source: `${currentUrl}${queryStringFormData}`,
                domain: baseUrl,
                lead_url: currentUrl,
                url: `${currentUrl}${queryStringFormData}`,
            };
            try {
                setLoading(true);
                const apiUrl = 'https://tgcrm.net/api/form_submission?brand_key=148453';
                const config = {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                };

                const formDataString = new URLSearchParams(finalReq).toString();

                const response = await axios.post(apiUrl, formDataString, config);
                if (response.status === 200) {
                    const queryString = new URLSearchParams(response.data.data).toString();
                    setLoading(false);
                    setErrors(false);
                    setFormData({
                        name: '',
                        email: '',
                        message: '',
                        phone: '',
                    });
                    window.location.href = `https://www.amazonbookwriters.com/thankyou?${queryString}`;
                }
                console.log('response', response);
            } catch (error) {
                console.error('Error:', error);
                setLoading(false);
            }
        }
    };



    return (
        <form class="form-submission" id="regForm" method="POST" onSubmit={submitForm}>
            <div class="input-group">
                <div className='col-md-6 me-3'>
                    <div class="form-group tab tab-1" >
                        {step === 1 && (
                            <input type="text" placeholder="Enter Your Name" name="name"
                                class="form-control" required="" onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
                        )}
                    </div>

                    {step === 2 && (
                        <div class="form-group tab tab-2">
                            <input type="email" placeholder="Enter Your E-mail" name="email"
                                class="form-control" required="" onChange={(e) => setFormData({ ...formData, email: e.target.value, })} />
                        </div>
                    )}
                    {step === 3 && (
                        <div class="form-group tab tab-3">
                            <input type="number" class="form-control" minlength="10"
                                maxlength="12" name="phone" placeholder="Enter Your Phone No"
                                required="" onChange={(e) => setFormData({ ...formData, phone: e.target.value, })} />
                        </div>
                    )}
                </div>
                <div class="form-btn-wrap">
                    {step > 1 && (
                        <button class="banner-form-btn prev" type="button" onClick={() => nextPrev(-1)}>
                            Previous
                        </button>
                    )}
                    {step < 3 && (
                        <button class="banner-form-btn next" type="button" onClick={() => nextPrev(1)}>
                            Next
                        </button>
                    )}
                </div>
                {step === 3 && (
                    loading ? (
                        <div className="loader">
                            <img alt="loader" src={loader} />
                        </div>
                    ) : (
                        <button type="submit" className="banner-form-btn submit" id="signupBtn2" value="1" name="signupForm">
                            submit
                        </button>
                    )
                )}
            </div>
        </form >
    );
}

export default BannerForm;