import React, { useState, useEffect } from 'react';
// import './index.css';
import OwlCarousel from 'react-owl-carousel';
import Fancybox from '../../components/Fancybox';

import {
    autobiographyimg1, autobiographyimg2, autobiographyimg3, autobiographyimg4,
    biographyimg1, biographyimg2, biographyimg3, biographyimg4,
    fictionimg1, fictionimg2, fictionimg3, fictionimg4,
    informativeimg1, informativeimg2, informativeimg3, informativeimg4,
    memoirimg1, memoirimg2, memoirimg3, memoirimg4,
    nonfictionimg1, nonfictionimg2, nonfictionimg3, nonfictionimg4
} from '../../assets/images';

const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowWidth;
};

function Portfoliotabs() {
    const [activeTab, setActiveTab] = useState('fiction');
    const windowWidth = useWindowWidth();
    const tabs = [
        { name: 'fiction', label: 'FICTION' },
        { name: 'nonfiction', label: 'NON-FICTION' },
        { name: 'biography', label: 'BIOGRAPHY' },
        { name: 'informative', label: 'INFORMATIVE' },
        { name: 'autobiography', label: 'AUTOBIOGRAPHY' },
        { name: 'memoir', label: 'MEMOIR' },
    ];
    const books = {
        fiction: [fictionimg1, fictionimg2, fictionimg3, fictionimg4],
        nonfiction: [nonfictionimg1, nonfictionimg2, nonfictionimg3, nonfictionimg4],
        biography: [biographyimg1, biographyimg2, biographyimg3, biographyimg4],
        informative: [informativeimg1, informativeimg2, informativeimg3, informativeimg4],
        autobiography: [autobiographyimg1, autobiographyimg2, autobiographyimg3, autobiographyimg4],
        memoir: [memoirimg1, memoirimg2, memoirimg3, memoirimg4],
    };

    const options = {
        loop: true,
        margin: 10,
        responsive: {
            0: { items: 1 },
            575: { items: 2 },
            768: { items: 3 },
        },
    };

    // return (
    //     <>
    //         <ul className="nav nav-tabs custom-tabs">
    //             {tabs.map((tab) => (
    //                 <li className="nav-item" key={tab.name}>
    //                     <button
    //                         className={`nav-link ${activeTab === tab.name ? 'active' : ''}`}
    //                         onClick={() => setActiveTab(tab.name)}
    //                     >
    //                         {tab.label}
    //                     </button>
    //                 </li>
    //             ))}
    //         </ul>

    //         <div className="tab-content mt-4">
    //             <Fancybox
    //                 options={{
    //                     Carousel: {
    //                         infinite: true,
    //                     },
    //                 }}
    //             >
    //                 {windowWidth <= 991 ? (
    //                     <OwlCarousel className="owl-theme" {...options}>
    //                         {books[activeTab].map((img, index) => (
    //                             <div className="item" key={index}>
    //                                 <a href={img} data-fancybox="gallery">
    //                                     <img src={img} className="card-img-top" alt="book" />
    //                                 </a>
    //                             </div>
    //                         ))}
    //                     </OwlCarousel>
    //                 ) : (
    //                     <div className="row">
    //                         {books[activeTab].map((img, index) => (
    //                             <div className="col-lg-3" key={index}>
    //                                 <div className="item">
    //                                     <a href={img} data-fancybox="gallery">
    //                                         <img src={img} className="card-img-top" alt="book" />
    //                                     </a>
    //                                 </div>
    //                             </div>
    //                         ))}
    //                     </div>
    //                 )}
    //             </Fancybox>
    //         </div>
    //     </>
    // );
    return (
        <>
            <ul className="nav nav-tabs custom-tabs">
                {tabs.map((tab) => (
                    <li className="nav-item" key={tab.name}>
                        <button
                            className={`nav-link ${activeTab === tab.name ? 'active' : ''}`}
                            onClick={() => setActiveTab(tab.name)}
                        >
                            {tab.label}
                        </button>
                    </li>
                ))}
            </ul>

            <div className="tab-content mt-4">
                <div>
                    {windowWidth <= 991 ? (
                        <OwlCarousel className="owl-theme" {...options}>
                            {books[activeTab].map((img, index) => (
                                <div className="item" key={index}>
                                    <a href={img} data-fancybox="gallery">
                                        <img src={img} className="card-img-top" alt="book" />
                                    </a>
                                </div>
                            ))}
                        </OwlCarousel>
                    ) : (
                        <div className="row">
                            {books[activeTab].map((img, index) => (
                                <div className="col-lg-3" key={index}>
                                    <div className="item">
                                        <a href={img} data-fancybox="gallery">
                                            <img src={img} className="card-img-top" alt="book" />
                                        </a>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default Portfoliotabs;
