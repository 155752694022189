import { BrowserRouter as Router, useLocation } from "react-router-dom";
import Header from "./GlobalSections/Header";
import Footer from "./GlobalSections/Footer";
import axios from 'axios';
import AppRoutes from "./routes";
import { ChatProvider } from "./ChatContext";
import { ModalProvider } from "./ModalContext";
import { useEffect } from "react";
import { HelmetProvider } from 'react-helmet-async';
import usePageMeta from './usePageMeta';
// import './App.css';
import { SideContactProvider } from "./SideContactContext";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ScrollToTop from "./components/ScrollToTop";

function App() {
  useEffect(() => {
    // Call the Laravel API using axios
    axios.get('https://serverlanes.com/api/chat-script')  // Update with the correct route
      .then(response => {
        const data = response.data;

        // Check if the chat script should be loaded
        if (data.enableChat) {
          // Dynamically create a script element and set its content
          const script = document.createElement('script');
          script.src = "https://static.zdassets.com/ekr/snippet.js?key=fdd3b7af-d0c3-4e8f-ae9b-4a1a74d6c23b";
          script.id = "ze-snippet";
          document.head.appendChild(script); // Append to head
        }
      })
      .catch(error => {
        console.error('Error loading chat script:', error);
      });
  }, []);
  return (
    <HelmetProvider>
      <ChatProvider>
        <ModalProvider>
            <Router>
              <MainContentWrapper />
            </Router>
        </ModalProvider>
      </ChatProvider>
    </HelmetProvider>
  );
}

function MainContentWrapper() {
  const location = useLocation();
  const isMarketingLp = ["/marketing-lp", "/marketing-lp/", "/coupon-lp", "/coupon-lp/", "/lp-1", "/lp-1/", "/book-editing-company", "/book-editing-company/"].includes(location.pathname);

  return (
    <>
      {isMarketingLp ? (
        <MainContent />
      ) : (
        <SideContactProvider>
          <MainContent />
        </SideContactProvider>
      )}
    </>
  );
}

function MainContent() {
  usePageMeta();
  const location = useLocation();

  useEffect(() => {
    let cssFile;

    const currentPath = location.pathname.replace(/\/+$/, '');

    if (currentPath === '/marketing-lp') {
      cssFile = import('./pages/LandingPages/Marketinglp/index.css');
    } else if (currentPath === '/coupon-lp') {
      cssFile = import('./pages/LandingPages/Couponlp/index.css');
    } else if (currentPath === '/lp-1') {
      cssFile = import('./pages/LandingPages/Lp1/index.css');
    } else if (currentPath === '/book-editing-company') {
      cssFile = import('./pages/LandingPages/BookEditingCompany/index.css');
    } else {
      cssFile = import('./App.css');
      cssFile = import('./GlobalSections/HomeServices/index.css');
    }

    cssFile
      .then(() => console.log('CSS loaded successfully'))
      .catch(err => console.error('Error loading CSS:', err));
  }, [location.pathname]);

  const noHeaderFooterRoutes = ["/marketing-lp", "/marketing-lp/", "/coupon-lp", "/coupon-lp/", "/lp-1", "/lp-1/", "/book-editing-company", "/book-editing-company/"];
  const shouldHideHeaderFooter = noHeaderFooterRoutes.includes(location.pathname);
  
  return (
    <>
      {!shouldHideHeaderFooter && <Header />}
      <ScrollToTop />
      <AppRoutes />
      {!shouldHideHeaderFooter && <Footer />}
    </>
  );
}

export default App;
