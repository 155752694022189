import { PHONE, PHONE_HREF } from '../../config';
import { Logo } from './../../assets/images';
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useChat } from '../../ChatContext';
import { useModal } from '../../ModalContext';
import './index.css'; // Custom CSS for hover behavior

export default function Header() {
    const { toggleChat } = useChat();
    const { openModal } = useModal();
    const [activeDropdown, setActiveDropdown] = useState(null);
    const [activeSubmenu, setActiveSubmenu] = useState(null);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 991);
    const [menuOpen, setMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false); // State to track if page is scrolled
    const location = useLocation();

    useEffect(() => {
        // Reset the menu state whenever the page changes
        setMenuOpen(false);
        setActiveDropdown(null);
        setActiveSubmenu(null);
    }, [location]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 991);
            if (window.innerWidth > 991) {
                setMenuOpen(false);
                setActiveDropdown(null);
                setActiveSubmenu(null);
            }
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleMenuToggle = () => {
        setMenuOpen((prev) => !prev);
    };

    const handleDropdownToggle = (dropdown) => {
        if (isMobileView) {
            setActiveDropdown(activeDropdown === dropdown ? null : dropdown);
            setActiveSubmenu(null);
        }
    };

    const handleSubmenuArrowToggle = (e, submenu) => {
        e.preventDefault();
        e.stopPropagation();
        if (isMobileView) {
            setActiveSubmenu(activeSubmenu === submenu ? null : submenu);
        }
    };

    const handleMouseOver = (dropdown) => {
        if (!isMobileView) {
            setActiveDropdown(dropdown);
        }
    };

    const handleMouseOut = (event, dropdown) => {
        if (!isMobileView) {
            const relatedTarget = event.relatedTarget;
            if (!relatedTarget || !document.getElementById(dropdown)?.contains(relatedTarget)) {
                setActiveDropdown(null);
                setActiveSubmenu(null);
            }
        }
    };

    const handleSubmenuMouseOver = (submenu) => {
        if (!isMobileView) {
            setActiveSubmenu(submenu);
        }
    };

    const handleSubmenuMouseOut = (event, submenu) => {
        if (!isMobileView) {
            const relatedTarget = event.relatedTarget;
            if (!relatedTarget || !document.getElementById(submenu)?.contains(relatedTarget)) {
                setActiveSubmenu(null);
            }
        }
    };

    return (
        <header className={`header ${isScrolled ? 'scrolled' : ''}`} id="header">
            <nav className="navbar navbar-expand-lg">
                <div className="container">
                    <Link className="navbar-brand header-logo" to="/">
                        <img src={Logo} alt="Logo" className="img-fluid" />
                    </Link>
                    <button
                        className="navbar-toggler"
                        type="button"
                        onClick={handleMenuToggle}
                        aria-controls="navbarSupportedContent"
                        aria-expanded={menuOpen}
                        aria-label="Toggle navigation"
                    >
                        <i className="fa-solid fa-bars"></i>
                    </button>
                    <div className={`collapse navbar-collapse menu_area ${menuOpen ? 'show' : ''}`} id="navbarSupportedContent">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <Link className="nav-link main_nav" to="/">Home</Link>
                            </li>
                            {/* Services Dropdown */}
                            <li
                                className="nav-item dropdown"
                                id="services-dropdown"
                                onMouseOver={() => handleMouseOver('services')}
                                onMouseOut={(e) => handleMouseOut(e, 'services-dropdown')}
                                onClick={() => handleDropdownToggle('services')}
                            >
                                <span className="nav-link main_nav" role="button">
                                    Services
                                </span>
                                {activeDropdown === 'services' && (
                                    <div className="dropdown-menu show">
                                        <ul>
                                            {/* Ghostwriting Dropdown with Submenu */}
                                            <li
                                                className="nav-item dropdown"
                                                id="ghostwriting-dropdown"
                                                onMouseOver={() => handleSubmenuMouseOver('ghostwriting')}
                                                onMouseOut={(e) => handleSubmenuMouseOut(e, 'ghostwriting-dropdown')}
                                            >
                                                <a className="nav-link b-none" href="/ghostwriting-services">
                                                    Ghostwriting
                                                    <i
                                                        onClick={(e) => handleSubmenuArrowToggle(e, 'ghostwriting')}
                                                        className="fa-solid fa-chevron-right"
                                                        role="button"
                                                    ></i>
                                                </a>
                                                {activeSubmenu === 'ghostwriting' && (
                                                    <ul className="dropdown-two sub_dropdown show" id="ghostwriting-submenu">
                                                        <li><Link className="nav-link" to="/non-fiction-writing">Non-Fiction Writing</Link></li>
                                                        <li><Link className="nav-link" to="/memoir-writing-services">Memoir Writing</Link></li>
                                                        <li><Link className="nav-link" to="/manuscript-writing-service">Manuscript Writing</Link></li>
                                                        <li><Link className="nav-link" to="/comic-book-writing-services">Comic Book Writing</Link></li>
                                                        <li><Link className="nav-link" to="/action-adventure-writing">Action Adventure</Link></li>
                                                        <li><Link className="nav-link" to="/biography-writing">Biography Writing</Link></li>
                                                        <li><Link className="nav-link" to="/military-fiction-writing">Military Fiction Writing</Link></li>
                                                        <li><Link className="nav-link" to="/fantasy-writing">Fantasy Writing</Link></li>
                                                        <li><Link className="nav-link" to="/horror-writing">Horror Writing</Link></li>
                                                        <li><Link className="nav-link" to="/rhyme-writing">Rhyme Writing</Link></li>
                                                        <li><Link className="nav-link" to="/screenplay-writing">Screenplay Writing</Link></li>
                                                        <li><Link className="nav-link" to="/story-writing">Story Writing</Link></li>
                                                        <li><Link className="nav-link" to="/autobiography-service">Autobiography</Link></li>
                                                        <li><Link className="nav-link" to="/science-fiction-writing">Sci-Fi Writing</Link></li>
                                                        <li><Link className="nav-link" to="/childrens-book-writing">Children's Book Writing</Link></li>
                                                    </ul>
                                                )}
                                            </li>

                                            {/* Book Editing Dropdown */}
                                            <li
                                                className="nav-item dropdown"
                                                id="book-editing-dropdown"
                                                onMouseOver={() => handleSubmenuMouseOver('book-editing')}
                                                onMouseOut={(e) => handleSubmenuMouseOut(e, 'book-editing-dropdown')}
                                            >
                                                <a className="nav-link" href="/book-editing-services">
                                                    Book Editing
                                                    <i
                                                        onClick={(e) => handleSubmenuArrowToggle(e, 'book-editing')}
                                                        className="fa-solid fa-chevron-right"
                                                        role="button"
                                                    ></i>
                                                </a>
                                                {activeSubmenu === 'book-editing' && (
                                                    <ul className="dropdown-single sub_dropdown show" id="book-editing-submenu">
                                                        <li><Link className="nav-link" to="/book-proofreading-services">Proofreading</Link></li>
                                                    </ul>
                                                )}
                                            </li>

                                            {/* Book Design Dropdown */}
                                            <li
                                                className="nav-item dropdown"
                                                id="book-design-dropdown"
                                                onMouseOver={() => handleSubmenuMouseOver('book-design')}
                                                onMouseOut={(e) => handleSubmenuMouseOut(e, 'book-design-dropdown')}
                                            >
                                                <a className="nav-link" href="#">
                                                    Book Design
                                                    <i
                                                        onClick={(e) => handleSubmenuArrowToggle(e, 'book-design')}
                                                        className="fa-solid fa-chevron-right"
                                                        role="button"
                                                    ></i>
                                                </a>
                                                {activeSubmenu === 'book-design' && (
                                                    <ul className="dropdown-single sub_dropdown show" id="book-design-submenu">
                                                        <li><Link className="nav-link" to="/book-cover-design-services">Book Cover Design</Link></li>
                                                        <li><Link className="nav-link" to="/author-website-design">Author Website Design</Link></li>
                                                        <li><Link className="nav-link" to="/book-illustration-design">Book Illustration Design</Link></li>
                                                    </ul>
                                                )}
                                            </li>

                                            {/* Book Marketing Dropdown */}
                                            <li
                                                className="nav-item dropdown"
                                                id="book-marketing-dropdown"
                                                onMouseOver={() => handleSubmenuMouseOver('book-marketing')}
                                                onMouseOut={(e) => handleSubmenuMouseOut(e, 'book-marketing-dropdown')}
                                            >
                                                <a className="nav-link" href="/book-marketing-services">
                                                    Book Marketing
                                                    <i
                                                        onClick={(e) => handleSubmenuArrowToggle(e, 'book-marketing')}
                                                        className="fa-solid fa-chevron-right"
                                                        role="button"
                                                    ></i>
                                                </a>
                                                {activeSubmenu === 'book-marketing' && (
                                                    <ul className="dropdown-single sub_dropdown show" id="book-marketing-submenu">
                                                        <li><Link className="nav-link" to="/book-video-trailer">Book Video Trailer</Link></li>
                                                    </ul>
                                                )}
                                            </li>

                                            {/* Single Links */}
                                            <li className='nav-item'><Link className="nav-link" to="/book-publishing">Book Publishing</Link></li>
                                            <li className='nav-item'><Link className="nav-link" to="/book-printing-services">Book Printing</Link></li>
                                        </ul>
                                    </div>
                                )}
                            </li>
                            {/* Other Menu Items */}
                            <li className="nav-item">
                                <Link className="nav-link main_nav" to="/about-us">About Us</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link main_nav" to="/portfolio">Portfolio</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link main_nav" to="/contact-us">Contact Us</Link>
                            </li>
                        </ul>
                        <div className="header-btn">
                            <a href="javascript:;" className="default_btn white_btn" onClick={openModal}>
                                Let's Get Started
                            </a>
                            <a href={PHONE_HREF} className="default_btn red_btn">{PHONE}</a>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    );
}
