// src/contexts/SideContactContext.js
import React, { createContext, useState, useContext } from 'react';
// import Modal from './components/Modal';
import { SideContact } from './components/SideContact';

const SideContactContext = createContext();

export const SideContactProvider = ({ children }) => {

  return (
    <SideContactContext.Provider>
      {children}
      <SideContact  />

    </SideContactContext.Provider>
  );
};

export const useSideContact = () => useContext(SideContactContext);
