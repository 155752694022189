import React, { useState } from 'react'
import { bbc1, bbc2, bbc3, bbc4, bbc5, bbc6, check, guarante1, guarante2, guarante3, guarante4 } from '../../assets/images'
import BannerForm from '../../components/BannerForm'
import CountDown from '../../components/CountDown'
import { ModalSection } from '../../components/modalSection'
import TopLogo from '../../components/TopLogo'
// import './index.css'

export default function MainBanner() {
    const [open, setOpen] = useState(false);

    const onOpenModal = () => {
        setOpen(true);
    };

    const onCloseModal = () => {
        setOpen(false);
    };
    return (
        <>
            <section className="banner-sec">
                <CountDown />
                <div className="container">
                    <h1>Turn Your Manuscript into a Bestseller!<br /> <span> Let Us Help You Publish </span> </h1>
                    <h2>
                        Enjoy <span> 30% OFF </span>on our expert services and take the first step towards becoming a published author today!
                    </h2>
                    <ul>
                        <li>
                            <img src={check} />
                            Trusted By 400+ Authors Globally
                        </li>
                        <li> <img src={check} />
                            2.5k+ Writers And Editors
                        </li>
                        <li> <img src={check} />
                            86% Of Customers Rehire
                        </li>
                        <li> <img src={check} />
                            100% Satisfaction Guaranteed
                        </li>
                    </ul>
                    <div className='btn-block'>
                        <a href='Javascript:;' className='btn banner-btn' onClick={onOpenModal}>ACTIVATE YOUR COUPON</a>
                        <a href='Javascript:;' className='chat chat-btn'>LIVE CHAT</a>
                    </div>
                    <div className='form-wrap'>
                        <BannerForm />
                    </div>
                </div>
                <div class="top-logo">
                    <div class="container">
                        <TopLogo />
                    </div>
                </div>
                <div className='our-guarante'>
                    <div className='container'>
                        <ul>
                            <li>
                                <img src={guarante1} /><br />
                                <span>100% Guaranteed <br /> customer satisfaction</span>
                            </li>
                            <li>
                                <img src={guarante2} /><br />
                                <span>Qualified Experts <br />Around the Globe</span>
                            </li>
                            <li>
                                <img src={guarante3} /><br />
                                <span>Secure <br />Ownership</span>
                            </li>
                            <li>
                                <img src={guarante4} /><br />
                                <span>Reasonable <br /> Pricing</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <ModalSection open={open} onCloseModal={onCloseModal} />
        </>
    )
}