import React, { useState, useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
import { greentick } from '../../assets/images';

export default function PackagesSlider({onOpenModal}){

    const settings = {
        arrows: false,
        loop: true,
        margin: 10,
        nav: false,
        dots: true,
        responsive: {
            0: {
                items: 1,
            },
            575: {
                items: 2,
            },
            992: {
                items: 3,
            },
        },
    };
    return (
        <>
            <OwlCarousel className='package-slider owl-carousel owl-theme' {...settings}>
                <div className=''>
                    <div className='package-item'>
                        <div className='package-title'>
                            <h3>Amazon's Best Seller</h3>
                            <h4>$5,999<span>$12,000</span></h4>
                        </div>
                        <ul>
                            <li><img src={greentick} />
                                Writing & Rewriting of up to 500 Pages
                            </li>
                            <li><img src={greentick} />
                                Planning / Creating/updating the outline
                            </li>
                            <li><img src={greentick} />
                                Ghostwriting original content based on interviews or research
                            </li>
                            <li><img src={greentick} />
                                Completion of your book as per the requirements/outline.
                            </li>
                            <li><img src={greentick} />
                                Revisions to our writing per your feedback and guidance.
                            </li>
                            <li><img src={greentick} />
                                Meticulous editing to ensure your text is free of error.
                            </li>
                            <li><img src={greentick} />
                                Adjusting the layout and presentation for the publishing standards.
                            </li>
                            <li><img src={greentick} />
                                Add Up to 20-30 Custom Illustrations.
                            </li>
                            <li><img src={greentick} />
                                Adjusting the layout and presentation for the publishing standards.
                            </li>
                            <li><img src={greentick} />
                                Unlimited revisions.
                            </li>
                            <li><img src={greentick} />
                                An attention-grabbing Book Cover.
                            </li>
                            <li><img src={greentick} />
                                Turnaround Time of 60-80 Days.
                            </li>
                            <li><img src={greentick} />
                                Rights to make changes to your book.
                            </li>
                            <li><img src={greentick} />
                                Editing, Proofreading, and formatting.
                            </li>
                            <li><img src={greentick} />
                                Basic Author’s Website
                            </li>
                            <li><img src={greentick} />
                                Authors Bio.
                            </li>
                            <li><img src={greentick} />
                                Publishing on Amazon Kindle, Barnes & Noble, Kobo, Ingram Spark, E-Book Publishing (Any 3)
                            </li>
                            <li><img src={greentick} />
                                Guaranteed 2000 Reads in 12 months
                            </li>
                        </ul>
                        <h5>
                            Note: The Amazon Best Seller Package guarantees you at least $20,000 in revenue in the first year after the book is published, and if we fail to meet this goal, we will fully refund your money.
                        </h5>
                        <a href='Javascript:;' className='package-btn' onClick={onOpenModal}>Let's Get Started</a>
                    </div>
                </div>
                <div className=''>
                    <div className='package-item'>
                        <div className='package-title pkg'>
                            <h3>Amazon Prime Package</h3>
                            <h4>$9,899<span>$19,798</span></h4>
                        </div>
                        <ul>
                            <li><img src={greentick} />
                                Writing & Rewriting of up to 500 Pages
                            </li>
                            <li><img src={greentick} />
                                Unlimited Revisions
                            </li>
                            <li><img src={greentick} />
                                3 Months of Paid Marketing on Google, Facebook and Amazon
                            </li>
                            <li><img src={greentick} />
                                Book Launch Globally
                            </li>
                            <li><img src={greentick} />
                                Full Ownership Rights
                            </li>
                            <li><img src={greentick} />
                                Planning / Creating/updating the outline
                            </li>
                            <li><img src={greentick} />
                                Ghostwriting original content based on interviews or research
                            </li>
                            <li><img src={greentick} />
                                Completion of your book as per the requirements/outline.
                            </li>
                            <li><img src={greentick} />
                                Revisions to our writing per your feedback and guidance.
                            </li>
                            <li><img src={greentick} />
                                Meticulous editing to ensure your text is free of error.
                            </li>
                            <li><img src={greentick} />
                                Adjusting the layout and presentation for the publishing standards
                            </li>
                            <li><img src={greentick} />
                                Add Up to 50-60 Custom Illustrations.
                            </li>
                            <li><img src={greentick} />
                                Adjusting the layout and presentation for the publishing standards.
                            </li>
                            <li><img src={greentick} />
                                Unlimited revisions.
                            </li>
                            <li><img src={greentick} />
                                An attention-grabbing Book Cover.
                            </li>
                            <li><img src={greentick} />
                                Turnaround Time of 60 -80 Days.
                            </li>
                            <li><img src={greentick} />
                                Rights to make changes to your book.
                            </li>
                            <li><img src={greentick} />
                                Editing, Proofreading, and formatting.
                            </li>
                            <li><img src={greentick} />
                                Author Website
                            </li>
                            <li><img src={greentick} />
                                Intro Book Video(Video Trailer)
                            </li>
                            <li><img src={greentick} />
                                Authors Bio.
                            </li>
                            <li><img src={greentick} />
                                Pitching article ideas to the press.
                            </li>
                            <li><img src={greentick} />
                                Publishing on Amazon Kindle, Barnes & Noble, Kobo, Ingram Spark, iBook, and Lulu (Any 5 Platforms)
                            </li>
                            <li><img src={greentick} />
                                Available in 3 different formats E-Book, and Paper Back, Hardcover
                            </li>
                            <li><img src={greentick} />
                                Amazon will be responsible for printing and shipping your book globally
                            </li>
                            <li><img src={greentick} />
                                Guaranteed 4000 Reads in 12 months
                            </li>
                        </ul>
                        <h5>
                            Note: The Amazon Prime Package guarantees you at least $40,000 in revenue in the first year after the book is published, and if we fail to meet this goal, we will fully refund your money
                        </h5>
                        <a href='Javascript:;' className='package-btn' onClick={onOpenModal}>Let's Get Started</a>
                    </div>
                </div>
                <div className=''>
                    <div className='package-item'>
                        <div className='package-title'>
                            <h3>Amazon Diamond Package</h3>
                            <h4>$17,500<span>$35,000</span></h4>
                        </div>
                        <ul>
                            <li><img src={greentick} />
                                Writing & Rewriting of up to 500 Pages
                            </li>
                            <li><img src={greentick} />
                                Unlimited Revisions
                            </li>
                            <li><img src={greentick} />
                                6 Months of Marketing, Including SEO (Search Engine Optimization)
                            </li>
                            <li><img src={greentick} />
                                6 Months of Social Media Marketing (Facebook, Twitter, Instagram)
                            </li>
                            <li><img src={greentick} />
                                Audio Book up to 3 hours
                            </li>
                            <li><img src={greentick} />
                                Audio Book Publishing on Audible.
                            </li>
                            <li><img src={greentick} />
                                Book Launch Globally
                            </li>
                            <li><img src={greentick} />
                                Full Ownership Rights
                            </li>
                            <li><img src={greentick} />
                                Planning / Creating/updating the outline
                            </li>
                            <li><img src={greentick} />
                                Ghostwriting original content based on interviews or research
                            </li>
                            <li><img src={greentick} />
                                Completion of your book as per the requirements/outline.
                            </li>
                            <li><img src={greentick} />
                                Revisions to our writing per your feedback and guidance.
                            </li>
                            <li><img src={greentick} />
                                Meticulous editing to ensure your text is free of error.
                            </li>
                            <li><img src={greentick} />
                                Adjusting the layout and presentation for the publishing standards
                            </li>
                            <li><img src={greentick} />
                                Add Up to 70-60 Custom Illustrations.
                            </li>
                            <li><img src={greentick} />
                                Adjusting the layout and presentation for the publishing standards.
                            </li>
                            <li><img src={greentick} />
                                Unlimited revisions.
                            </li>
                            <li><img src={greentick} />
                                An attention-grabbing Book Cover.
                            </li>
                            <li><img src={greentick} />
                                Turnaround Time of 60 -80 Days.
                            </li>
                            <li><img src={greentick} />
                                Rights to make changes to your book.
                            </li>
                            <li><img src={greentick} />
                                Editing, Proofreading, and formatting.
                            </li>
                            <li><img src={greentick} />
                                Author Website
                            </li>
                            <li><img src={greentick} />
                                Intro Book Video(Video Trailer)
                            </li>
                            <li><img src={greentick} />
                                Authors Bio.
                            </li>
                            <li><img src={greentick} />
                                Pitching article ideas to the press.
                            </li>
                            <li><img src={greentick} />
                                Publishing on Amazon Kindle, Barnes & Noble, Kobo, Ingram Spark, iBook, and Lulu (All Platforms)
                            </li>
                            <li><img src={greentick} />
                                Available in 3 different formats E-Book, and Paper Back, Hardcover
                            </li>
                            <li><img src={greentick} />
                                Amazon will be responsible for printing and shipping your book globally
                            </li>
                            <li><img src={greentick} />
                                Guaranteed 6000 Reads in 12 months
                            </li>
                        </ul>
                        <h5>
                            Note: The Amazon Diamond Package guarantees you at least $60,000 in revenue in the first year after the book is published, and if we fail to meet this goal, we will fully refund your money.
                        </h5>
                        <a href='Javascript:;' className='package-btn' onClick={onOpenModal}>Let's Get Started</a>
                    </div>
                </div>
                <div className=''>
                    <div className='package-item'>
                        <div className='package-title pkg'>
                            <h3>Amazon Platinum Package</h3>
                            <h4>$37,500<span>$70,000</span></h4>
                        </div>
                        <ul>
                            <li><img src={greentick} />
                                Unlimited Pages
                            </li>
                            <li><img src={greentick} />
                                Unlimited Revisions
                            </li>
                            <li><img src={greentick} />
                                12 Months of Marketing, Including SEO (Search Engine Optimization)
                            </li>
                            <li><img src={greentick} />
                                12 Months of Social Media Marketing (Facebook, Twitter, Instagram)
                            </li>
                            <li><img src={greentick} />
                                Audio Book up to 6 hours
                            </li>
                            <li><img src={greentick} />
                                Audio Book Publishing on Audible etc
                            </li>
                            <li><img src={greentick} />
                                Book Launch Globally
                            </li>
                            <li><img src={greentick} />
                                Full Ownership Rights
                            </li>
                            <li><img src={greentick} />
                                Planning / Creating/updating the outline
                            </li>
                            <li><img src={greentick} />
                                Ghostwriting original content based on interviews or research
                            </li>
                            <li><img src={greentick} />
                                Completion of your book as per the requirements/outline.
                            </li>
                            <li><img src={greentick} />
                                Revisions to our writing per your feedback and guidance.
                            </li>
                            <li><img src={greentick} />
                                Meticulous editing to ensure your text is free of error.
                            </li>
                            <li><img src={greentick} />
                                Adjusting the layout and presentation for the publishing standards
                            </li>
                            <li><img src={greentick} />
                                Add Up to 100 Custom Illustrations.
                            </li>
                            <li><img src={greentick} />
                                Adjusting the layout and presentation for the publishing standards.
                            </li>
                            <li><img src={greentick} />
                                Unlimited revisions.
                            </li>
                            <li><img src={greentick} />
                                An attention-grabbing Book Cover.
                            </li>
                            <li><img src={greentick} />
                                Turnaround Time of 60 -80 Days.
                            </li>
                            <li><img src={greentick} />
                                Rights to make changes to your book.
                            </li>
                            <li><img src={greentick} />
                                Editing, Proofreading, and formatting.
                            </li>
                            <li><img src={greentick} />
                                Author Website
                            </li>
                            <li><img src={greentick} />
                                Intro Book Video(Video Trailer)
                            </li>
                            <li><img src={greentick} />
                                Authors Bio.
                            </li>
                            <li><img src={greentick} />
                                Pitching article ideas to the press.
                            </li>
                            <li><img src={greentick} />
                                Publishing on Amazon Kindle, Barnes & Noble, Kobo, Ingram Spark, iBook, and Lulu (All Platforms)
                            </li>
                            <li><img src={greentick} />
                                Available in 3 different formats E-Book, and Paper Back, Hardcover
                            </li>
                            <li><img src={greentick} />
                                Amazon will be responsible for printing and shipping your book globally
                            </li>
                            <li><img src={greentick} />
                                Guaranteed 12,000 Reads in 12 months
                            </li>
                        </ul>
                        <h5>
                            Note: The Amazon Platinum Package guarantees you at least $120,000 in revenue in the first year after the book is published, and if we fail to meet this goal, we will fully refund your money.
                        </h5>
                        <a href='Javascript:;' className='package-btn' onClick={onOpenModal}>Let's Get Started</a>
                    </div>
                </div>
            </OwlCarousel>
        </>
    )
}