import { serviceicon2, serviceicon2dark, serviceicon3, serviceicon3dark, serviceicon4, serviceicon4dark, serviceicon5, serviceicon5dark, serviceicon6, serviceicon6dark, servicesicon1, servicesicon1dark, servicesicon2, servicesicon2dark, servicesicon3, servicesicon3dark, servicesicon4, servicesicon4dark, servicesicon5, servicesicon5dark } from '../../assets/images'
import WhatWeDoSlider from '../../Components/WhatWeDoSlider'
// import './index.css'

export default function WhatWeDo() {
    return (
        <section className="what-we-do py-md-5">
            <div className="container">
                <h2 className="text-white text-center font-weight-bold mb-5">What We Do For You?</h2>
                <WhatWeDoSlider />
            </div>
        </section>
    )
}