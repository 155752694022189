import { logowebp, trust } from "../../assets/images";
import { EMAIL, EMAIL_HREF, PHONE, PHONE_HREF, WEBSITE_NAME } from "../../config";
// import './index.css'

export default function Footer() {
    return (
        <>
            <footer>
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-lg-7">
                            <div className="footer-text"  data-aos="fade-right" data-aos-duration="1500">
                                <img src={logowebp} className="img-fluid" />
                                <p>{WEBSITE_NAME} are here to deliver instant help for any type of Writing, Design and Marketing Service. We are a team of highly creative, active and dedicated people.</p>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-5">
                            <div className="footer-contact" data-aos="fade-right" data-aos-duration="1500">
                                <ul className="footer-address">
                                    <li>
                                        <a href={PHONE_HREF}><i class="fa-solid fa-phone-volume"></i>{PHONE}</a>
                                    </li>
                                    <li>
                                        <a href={EMAIL_HREF}><i class="fa-solid fa-envelope"></i>{EMAIL}</a>
                                    </li>
                                </ul>
                                <ul className="footer-social">
                                    <li>
                                        <a href="Javascript:;" className="facebook"><i class="fa-brands fa-facebook"></i></a>
                                    </li>
                                    <li>
                                        <a href="Javascript:;" className="youtube"><i class="fa-brands fa-youtube"></i></a>
                                    </li>
                                    <li>
                                        <a href="Javascript:;" className="star"><img src={trust} /></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright-sec">
                    <div className="container">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-lg-6">
                                <p>Copyright © 2024 Amazon Book Writers. All rights reserved.</p>
                            </div>
                            <div className="col-xl-4 col-lg-5">
                                <ul >
                                    <li>
                                        <a href="https://www.amazonbookwriters.com/terms-and-conditions" className="terms">Terms & Conditions</a> |
                                    </li>
                                    <li>
                                        <a href="https://www.amazonbookwriters.com/privacy-policy" className="privacy">Privacy Policy</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </>
    )
}