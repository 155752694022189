import React, { useRef, useEffect, useState } from "react";
import Tick from "@pqina/flip";
import "@pqina/flip/dist/flip.min.css";

export const FlipClock = ({ value }) => {
  const divRef = useRef();
  const tickRef = useRef();
  const [tickValue, setTickValue] = useState(value);

  useEffect(() => {
    const didInit = (tick) => {
      tickRef.current = tick;
    };

    const currDiv = divRef.current;
    const tickInstance = Tick.DOM.create(currDiv, {
      value,
      didInit,
    });

    return () => Tick.DOM.destroy(tickInstance);
  }, [value]);

  useEffect(() => {
    const offset = new Date();
    const timeDuration = Tick.helper.duration(2, "hours");

    // Set a deadline 2 hours from now
    const deadline = new Date(
      offset.setMilliseconds(offset.getMilliseconds() + timeDuration)
    );

    const counter = Tick.count.down(deadline, {
      format: ["h", "m", "s"],
      transform: (value) => {
        // Pad each time unit to ensure two digits
        return value.map((val) => String(val).padStart(2, "0"));
      },
    });

    // Update React state when counter changes
    counter.onupdate = function (value) {
      setTickValue(value);
    };

    return () => {
      if (counter && counter.timer) {
        counter.timer.stop();
      }
    };
  }, [value]);

  useEffect(() => {
    if (tickRef.current) {
      tickRef.current.value = tickValue;
    }
  }, [tickValue]);

  return (
    <div ref={divRef} className="tick">
      <div data-repeat="true">
        <span data-view="flip" />
      </div>
    </div>
  );
};

export default FlipClock;
