import FooterForm from '../../Components/FooterForm'
import { PHONE } from '../../config'
// import './index.css'

export default function ContactSection() {
    return (
        <>
            <section className="contact-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <h2 className='text-center font-weight-bold'>Let’s Get Started </h2>
                            <p>Fill out the form now, and let’s make your publishing dreams a reality! 
                            </p>
                            <FooterForm/>
                        </div>
                    </div>
                </div >
            </section >
        </>
    )
} 