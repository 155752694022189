import React, { useState, useEffect } from 'react';
import { Nav } from 'react-bootstrap';
import {
    annaGray,
    jaredGreen,
    heraldWright,
    jerryChristian,
    nelsonBale,
    kevinAdams,
    dustinStevens,
    adrianaBanks,
    gregoryDiehl,
    anaHuang,
    anaBlakely,
    joshuaHood,
    beswick,
    danJones,
    robinJones,
    karenCasey,
    juliaZheng,
    angieThomas,
    andrewMorton,
    heatherBurch,
    kellyHarms,
    roberPeecher,
    traciWooden,
    ryanKurr,
} from "./../../assets/images";
import { WEBSITE_NAME } from '../../config';

function ReviewsTabs() {
    const [activeTab, setActiveTab] = useState('tab1');
  return (
    <div className='row'>

        <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
            <div class="tab-agencies-btns">
                <Nav className="nav flex-column nav-pills" variant="tabs" aria-orientation="vertical" activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>   
                    <Nav.Item>
                        <Nav.Link className="tab" eventKey="tab1">Ghostwriting</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link className="tab" eventKey="tab2">Book Editing</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link className="tab" eventKey="tab3">Book Marketing</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link className="tab" eventKey="tab4">Book Publishing</Nav.Link>
                    </Nav.Item>
                </Nav>
            </div>
        </div>

        <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12">
            <div class="tab-agencies-item">
            {activeTab === 'tab1' && (
                <div class="tab-item-content">
                    <h2>Ghostwriting</h2>
                    <div class="row align-items-center reviews-slider">
                        <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                            <div class="tab-item-box-left">
                                <img src={annaGray} alt="img" class="img-fluid"/>
                                <div class="item-box-left-inner">
                                    <p>Hiring {WEBSITE_NAME} was a hasty decision but
                                        proved to be
                                        the very best one of my life.
                                    </p>
                                    <h4>Anna Grey</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-xxl-7 col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12">
                            <div class="tab-item-box-right">
                                <div class="item-box-right-inner order-two">
                                    <p>I wished to share my experience as an entrepreneur, which is
                                        why
                                        I hired {WEBSITE_NAME}. No regrets at all!
                                    </p>
                                    <h4>Jared Green</h4>
                                </div>
                                <img src={jaredGreen} alt="img" class="img-fluid order-one"/>
                            </div>
                            <div class="tab-item-box-right">
                                <div class="item-box-right-inner order-two">
                                    <p>{WEBSITE_NAME} helped me write the background and
                                        history
                                        of my journey as an entrepreneur.
                                    </p>
                                    <h4>Herald Wright</h4>
                                </div>
                                <img src={heraldWright} alt="img" class="img-fluid order-one"/>
                            </div>
                        </div>
                    </div>
                    <div class="row reviews-slider">
                        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                            <div class="tab-item-box">
                                <img src={jerryChristian} alt="img" class="img-fluid"/>
                                <div class="item-box-inner">
                                    <p>The ability to create various kinds of books is surely
                                        intriguing. {WEBSITE_NAME} can do all types of
                                        books. I’ve
                                        experienced their services first-hand.
                                    </p>
                                    <h4>Jerry Christian</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                            <div class="tab-item-box">
                                <img src={nelsonBale} alt="img" class="img-fluid"/>
                                <div class="item-box-inner">
                                    <p>Never have I ever seen such brilliant ghostwriting experts.
                                    </p>
                                    <h4>Nelson Bale</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                            <div class="tab-item-box">
                                <img src={kevinAdams} alt="img" class="img-fluid"/>
                                <div class="item-box-inner">
                                    <p>Highly appreciate such stellar ghostwriting services.
                                    </p>
                                    <h4>Kevin Adams</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {activeTab === 'tab2' && (
                <div class="tab-item-content">
                    <h2>Book Editing</h2>
                    <div class="row align-items-center reviews-slider">
                        <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                            <div class="tab-item-box-left">
                                <img src={dustinStevens} alt="img" class="img-fluid"/>
                                <div class="item-box-left-inner">
                                    <p>Hiring {WEBSITE_NAME} has been one of the best
                                        decisions
                                        I’ve ever made. Their team comprises professional and
                                        experienced proofreaders who reviewed and edited my book
                                        expertly.
                                    </p>
                                    <h4>Dustin Stevens</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-xxl-7 col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12">
                            <div class="tab-item-box-right">
                                <div class="item-box-right-inner order-two">
                                    <p>{WEBSITE_NAME} proofread my content of 300 pages
                                        within two
                                        weeks. The results convinced me of their professionalism!
                                    </p>
                                    <h4>Adrienne Banks</h4>
                                </div>
                                <img src={adrianaBanks} alt="img" class="img-fluid order-one"/>
                            </div>
                            <div class="tab-item-box-right">
                                <div class="item-box-right-inner order-two">
                                    <p>{WEBSITE_NAME} has some of the best book editors
                                        for hire
                                        in the book industry. Their services are professional,
                                        unique,
                                        and affordable.
                                    </p>
                                    <h4>Gregory Diehl</h4>
                                </div>
                                <img src={gregoryDiehl} alt="img" class="img-fluid order-one"/>
                            </div>
                        </div>
                    </div>
                    <div class="row reviews-slider">
                        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                            <div class="tab-item-box">
                                <img src={anaHuang} alt="img" class="img-fluid"/>
                                <div class="item-box-inner">
                                    <p>{WEBSITE_NAME} is truly a one-stop solution for
                                        all your
                                        book publishing needs. Their experts reviewed my draft,
                                        performed developmental editing, and published the book
                                        within
                                        two to three weeks.
                                    </p>
                                    <h4>Ana Huang</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                            <div class="tab-item-box">
                                <img src={anaBlakely} alt="img" class="img-fluid"/>
                                <div class="item-box-inner">
                                    <p>I hired {WEBSITE_NAME} when I was halfway through
                                        writing
                                        my first book. My job and family responsibilities kept me
                                        from
                                        pursuing my dream of becoming an author. PBW completed my
                                        draft
                                        and edited my book professionally. Highly recommend their
                                        services.
                                    </p>
                                    <h4>Ana Blakely</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                            <div class="tab-item-box">
                                <img src={joshuaHood} alt="img" class="img-fluid"/>
                                <div class="item-box-inner">
                                    <p>I hired {WEBSITE_NAME} to edit and proofread my
                                        nursing
                                        journal. Their team accepted the task, conducted their own
                                        research, and fact-checked everything I had written. Loved
                                        working with them.
                                    </p>
                                    <h4>Joshua Hood</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {activeTab === 'tab3' && (
                <div class="tab-item-content">
                    <h2>Book Marketing</h2>
                    <div class="row align-items-center reviews-slider">
                        <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                            <div class="tab-item-box-left">
                                <img src={beswick} alt="img" class="img-fluid"/>
                                <div class="item-box-left-inner">
                                    <p>{WEBSITE_NAME} did a fantastic job designing the
                                        cover for
                                        my book. It helped increase my number of potential buyers.
                                        So
                                        happy with their work!
                                    </p>
                                    <h4>A.P Beswick</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-xxl-7 col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12">
                            <div class="tab-item-box-right">
                                <div class="item-box-right-inner order-two">
                                    <p>I contacted {WEBSITE_NAME} to help develop a
                                        marketing
                                        strategy for my book. Their team is highly professional and
                                        experienced. Highly recommended!
                                    </p>
                                    <h4>Dan Jones</h4>
                                </div>
                                <img src={danJones} alt="img" class="img-fluid order-one"/>
                            </div>
                            <div class="tab-item-box-right">
                                <div class="item-box-right-inner order-two">
                                    <p>{WEBSITE_NAME} offers exceptional marketing
                                        strategies.
                                        They have all the right sources and techniques to get a book
                                        the
                                        attention it requires.
                                    </p>
                                    <h4>Robin Jones Gunn</h4>
                                </div>
                                <img src={robinJones} alt="img" class="img-fluid order-one"/>
                            </div>
                        </div>
                    </div>
                    <div class="row reviews-slider">
                        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                            <div class="tab-item-box">
                                <img src={karenCasey} alt="img" class="img-fluid"/>
                                <div class="item-box-inner">
                                    <p>I wrote a book on self-meditation and
                                        {WEBSITE_NAME} did an
                                        amazing job promoting it on different social media
                                        platforms.
                                    </p>
                                    <h4>Karen Casey</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                            <div class="tab-item-box">
                                <img src={juliaZheng} alt="img" class="img-fluid"/>
                                <div class="item-box-inner">
                                    <p>{WEBSITE_NAME} did a fantastic job promoting my
                                        children’s
                                        book. It definitely boosted my book’s sales.
                                    </p>
                                    <h4>Julia Zheng</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                            <div class="tab-item-box">
                                <img src={angieThomas} alt="img" class="img-fluid"/>
                                <div class="item-box-inner">
                                    <p>{WEBSITE_NAME} has an expert book marketing team
                                        that
                                        helped increase my book’s sales. Their team is highly
                                        proficient
                                        and reliable.
                                    </p>
                                    <h4>Angie Thomas</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {activeTab === 'tab4' && (
                <div class="tab-item-content">
                    <h2>Book Publishing</h2>
                    <div class="row align-items-center reviews-slider">
                        <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                            <div class="tab-item-box-left">
                                <img src={andrewMorton} alt="img" class="img-fluid"/>
                                <div class="item-box-left-inner">
                                    <p>They have the best book publishing team in the market!
                                    </p>
                                    <h4>Andrew Morton</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-xxl-7 col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12">
                            <div class="tab-item-box-right">
                                <div class="item-box-right-inner order-two">
                                    <p>{WEBSITE_NAME} reviewed and published my book,
                                        and made
                                        sure it reached its target audience.
                                    </p>
                                    <h4>Heather Burch</h4>
                                </div>
                                <img src={heatherBurch} alt="img" class="img-fluid order-one"/>
                            </div>
                            <div class="tab-item-box-right">
                                <div class="item-box-right-inner order-two">
                                    <p>{WEBSITE_NAME} enabled me to share my story with
                                        the
                                        world.Thank you so much.
                                    </p>
                                    <h4>Kelly Harms</h4>
                                </div>
                                <img src={kellyHarms} alt="img" class="img-fluid order-one"/>
                            </div>
                        </div>
                    </div>
                    <div class="row reviews-slider">
                        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                            <div class="tab-item-box">
                                <img src={roberPeecher} alt="img" class="img-fluid"/>
                                <div class="item-box-inner">
                                    <p>Experts at {WEBSITE_NAME} delivered what they
                                        promised. I
                                        was able to sell over a hundred copies of my book thanks to
                                        their publishing team.
                                    </p>
                                    <h4>Robert Peecher</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                            <div class="tab-item-box">
                                <img src={traciWooden} alt="img" class="img-fluid"/>
                                <div class="item-box-inner">
                                    <p>{WEBSITE_NAME} have connections with some of the
                                        most
                                        reputable publishing houses in the industry. They reviewed
                                        my
                                        work and ensured it was fit for publication before launching
                                        it.
                                    </p>
                                    <h4>Traci Wooden</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                            <div class="tab-item-box">
                                <img src={ryanKurr} alt="img" class="img-fluid"/>
                                <div class="item-box-inner">
                                    <p>{WEBSITE_NAME} did a fantastic job publishing my
                                        book. It
                                        helped increase my number of potential buyers. So happy with
                                        their work!
                                    </p>
                                    <h4>Ryan Kurr</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            </div>
        </div>
    </div>
  );
}

export default ReviewsTabs;
