  import React from 'react';
  import Slider from "react-slick";
  import "slick-carousel/slick/slick.css";
  import "slick-carousel/slick/slick-theme.css";
  import { useLocation } from 'react-router-dom';
  
  import './index.css';
  import {
    PartnerLogo01,
    PartnerLogo02,
    PartnerLogo03,
    PartnerLogo04,
    PartnerLogo05,
    PartnerLogo06,
    PartnerLogo07,
    PartnerLogo08,
    PartnerLogo09,
    PartnerLogo10,
    PartnerLogo11,
    PartnerLogo12,
    PartnerLogo13,
    PartnerLogo14,
    PartnerLogo15,
    PartnerLogo16,
    PartnerLogo17,
    PartnerLogo18,
} from '../../assets/images';
  export default function PartnerLogosSlider({data = {}}) {
    const location = useLocation(); 
    const settings = {
      infinite: true,
      autoplay: true,
      arrows: false,
      dots: !1,
      autoplaySpeed: 4e3,
      speed: 1e3,
      slidesToShow: 6,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 4
          }
        }, {
          breakpoint: 768,
          settings: {
            slidesToShow: 3
          }
        }, {
          breakpoint: 576,
          settings: {
            slidesToShow: 2
          }
        },
      ]
    };
    return (
      <section className='partners_logo'>
          <div className='container'>
            {(location.pathname.replace(/\/$/, '') === '/portfolio' || location.pathname.replace(/\/$/, '') === '/reviews') && (
              <h2 className='text_clr_light text-center'>{data?.PartnerSliderData?.title}</h2>
            )}
              <Slider {...settings}>
                <div class="brand-logo opacity-07">
                    <img class="img-fluid" src={PartnerLogo01} alt="brand-logo"/>
                </div>
                <div class="brand-logo opacity-07">
                    <img class="img-fluid" src={PartnerLogo02} alt="brand-logo"/>
                </div>
                <div class="brand-logo opacity-07">
                    <img class="img-fluid" src={PartnerLogo03} alt="brand-logo"/>
                </div>
                <div class="brand-logo opacity-07">
                    <img class="img-fluid" src={PartnerLogo04} alt="brand-logo"/>
                </div>
                <div class="brand-logo opacity-07">
                    <img class="img-fluid" src={PartnerLogo05} alt="brand-logo"/>
                </div>
                <div class="brand-logo opacity-07">
                    <img class="img-fluid" src={PartnerLogo06} alt="brand-logo"/>
                </div>
                <div class="brand-logo opacity-07">
                    <img class="img-fluid" src={PartnerLogo07} alt="brand-logo"/>
                </div>
                <div class="brand-logo opacity-07">
                    <img class="img-fluid" src={PartnerLogo08} alt="brand-logo"/>
                </div>
                <div class="brand-logo opacity-07">
                    <img class="img-fluid" src={PartnerLogo09} alt="brand-logo"/>
                </div>
                <div class="brand-logo opacity-07">
                    <img class="img-fluid" src={PartnerLogo10} alt="brand-logo"/>
                </div>
                <div class="brand-logo opacity-07">
                    <img class="img-fluid" src={PartnerLogo11} alt="brand-logo"/>
                </div>
                <div class="brand-logo opacity-07">
                    <img class="img-fluid" src={PartnerLogo12} alt="brand-logo"/>
                </div>
                <div class="brand-logo opacity-07">
                    <img class="img-fluid" src={PartnerLogo13} alt="brand-logo"/>
                </div>
                <div class="brand-logo opacity-07">
                    <img class="img-fluid" src={PartnerLogo14} alt="brand-logo"/>
                </div>
                <div class="brand-logo opacity-07">
                    <img class="img-fluid" src={PartnerLogo15} alt="brand-logo"/>
                </div>
                <div class="brand-logo opacity-07">
                    <img class="img-fluid" src={PartnerLogo16} alt="brand-logo"/>
                </div>
                <div class="brand-logo opacity-07">
                    <img class="img-fluid" src={PartnerLogo17} alt="brand-logo"/>
                </div>
                <div class="brand-logo opacity-07">
                    <img class="img-fluid" src={PartnerLogo18} alt="brand-logo"/>
                </div>
              </Slider>
        </div>
      </section>
    );
  }
