import React, { useState, useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';

import {
    logoimg1, logoimg2, logoimg3, logoimg4
} from '../../assets/images';

// Hook to track window width
const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowWidth;
};

function BrandLogoSlider() {
    const windowWidth = useWindowWidth();

    const options = {
        loop: true,
        nav: false,
        dots: false,
        margin: 25,
        responsiveClass: true,
        autoplay: true,
        autoplayHoverPause: true,
        autoplayTimeout: 8000,
        autoplaySpeed: 800,
        navText: [""],
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 3,
            },
            1000: {
                items: 6,
            },
            360: {
                items: 2,
            }
        }
    };

    return (
        <div className="brand-logo">
            {windowWidth < 768 ? (
                <OwlCarousel className="custom-owl-theme" {...options}>
                    <div className="item"><img src={logoimg1} alt="brand-logo" /></div>
                    <div className="item"><img src={logoimg2} alt="brand-logo" /></div>
                    <div className="item"><img src={logoimg3} alt="brand-logo" /></div>
                    <div className="item"><img src={logoimg4} alt="brand-logo" /></div>
                </OwlCarousel>
            ) : (
                // Render static images for widths below 768px
                <>
                    <img src={logoimg1} alt="brand-logo" />
                    <img src={logoimg2} alt="brand-logo" />
                    <img src={logoimg3} alt="brand-logo" />
                    <img src={logoimg4} alt="brand-logo" />
                </>
            )}
        </div>
    );
}

export default BrandLogoSlider;
