import {PHONE,PHONE_HREF,WEBSITE_NAME,EMAIL,EMAIL_HREF,ADDRESS} from '../../config';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './index.css';
import {
    WhiteLogo,
    CtaSide1,
    googlereviews,
    trustpilot,
    amazonkindle,
    dmc2,
    dmc1,
} from '../../assets/images';
import { useChat } from '../../ChatContext';
import CookieBar from '../../components/CookieBar';

export default function Footer() {
    const year = new Date().getFullYear();

    
    return (
        <>
        <footer class="footer-main-sec">
                <div class="footer-top">
                    <div class="container">
                        <div class="row">
                            <div class="col-xxl-4 col-xl-4 col-lg-8 col-md-8 col-sm-12 col-12">
                                <div class="footer-links-area">
                                    <h4>Ghostwriting</h4>
                                    <div class="footer-services-nav-links">
                                        <ul class="footer-nav-links">
                                            <li><a href="/non-fiction-writing">Non-Fiction Writing</a></li>
                                            <li><a href="/memoir-writing-services">Memoir Writing</a></li>
                                            <li><a href="/manuscript-writing-service">Manuscript Writing</a></li>
                                            <li><a href="/comic-book-writing-services">Comic Book Writing</a></li>
                                            <li><a href="/action-adventure-writing">Action Adventure</a></li>
                                            <li><a href="/biography-writing">Biography Writing</a></li>
                                            <li><a href="/military-fiction-writing">Military Fiction Writing</a></li>
                                            <li><a href="/fantasy-writing">Fantasy Writing</a></li>
                                        </ul>
                                        <ul class="footer-nav-links">
                                            <li><a href="/horror-writing">Horror Writing</a></li>
                                            <li><a href="/rhyme-writing">Rhyme Writing</a></li>
                                            <li><a href="/screenplay-writing">Screenplay Writing</a></li>
                                            <li><a href="/story-writing">Story Writing</a></li>
                                            <li><a href="/autobiography-service">Autobiography</a></li>
                                            <li><a href="/science-fiction-writing">Sci Fi Writing</a></li>
                                            <li><a href="/childrens-book-writing">Children's Book Writing</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-2 col-xl-2 col-lg-4 col-md-4 col-sm-12 col-12">
                                <div class="footer-links-area">
                                    <h4>Book Design</h4>
                                    <ul class="footer-nav-links">
                                        <li><a href="/book-cover-design-services">Book Cover Design</a></li>
                                        <li><a href="/author-website-design">Author Website Design</a></li>
                                        <li><a href="/book-illustration-design">Book Illustration Design</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-xxl-2 col-xl-2 col-lg-4 col-md-4 col-sm-12 col-12">
                                <div class="footer-links-area">
                                    <h4>Other Services</h4>
                                    <ul class="footer-nav-links">
                                        <li><a href="/ghostwriting-services">Ghostwriting</a></li>
                                        <li><a href="/book-editing-services">Book Editing</a></li>
                                        <li><a href="/book-proofreading-services">Proofreading</a></li>
                                        <li><a href="/book-marketing-services">Book Marketing</a></li>
                                        <li><a href="/book-video-trailer">Book Video Trailer</a></li>
                                        <li><a href="/book-publishing">Book Publishing</a></li>
                                        <li><a href="/book-printing-services">Book Printing</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-xxl-2 col-xl-2 col-lg-4 col-md-4 col-sm-12 col-12">
                                <div class="footer-links-area">
                                    <h4>Company</h4>
                                    <ul class="footer-nav-links">
                                        <li><a href="/">Home</a></li>
                                        <li><a href="/about-us">About Us</a></li>
                                        <li><a href="/portfolio">Portfolio</a></li>

                                        <li><a href="/reviews">Reviews</a></li>
                                        <li><a href="/contact-us">Contact Us</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-xxl-2 col-xl-2 col-lg-4 col-md-4 col-sm-12 col-12">
                                <div class="footer-links-area footer-links-area-last">
                                    <h4>Policies</h4>
                                    <ul class="footer-nav-links">
                                        <li><a href="/privacy-policy">Privacy Policy</a></li>
                                        <li><a href="/terms-and-conditions">Terms & Conditions</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div class="footer-brands-area">
                                    <div class="footer-brands-logos">
                                        <img src={googlereviews} alt="img" class="img-fluid"/>
                                        <img src={trustpilot} alt="img" class="img-fluid"/>
                                        <img src={amazonkindle} alt="img" class="img-fluid"/>
                                    </div>
                                    <div class="footer-dmc-logos">
                                        <img src={dmc2} alt="img" class="img-fluid"/>
                                        <img src={dmc1} alt="img" class="img-fluid"/>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="footer-disclaimer-area">
                                    <p><b>Disclaimer:</b> {WEBSITE_NAME} owns the trademarks for its logo, name, and
                                        graphics associated with its products and services. All other business titles, brands,
                                        trademarks, and logos stated on this website belong to their rightful owners and do not
                                        encompass or imply endorsement, sponsorship, or recommendation by
                                        {WEBSITE_NAME},
                                        nor do they represent or imply endorsement, sponsorship, or recommendation by the
                                        respective trademark holder of {WEBSITE_NAME}. After successful completion of
                                        service, the client bears the right to ownership and {WEBSITE_NAME} is entitled
                                        to
                                        revoking all ownership rights.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer-middle">
                    <div class="container">
                        <div class="footer-logo-area">
                            <div class="footer-logo">
                                <img src={WhiteLogo} alt="logo" class="img-fluid"/>
                            </div>
                            <div class="footer-info">
                                <ul>
                                    <li>
                                        <a href={EMAIL_HREF}>{EMAIL}</a>
                                    </li>
                                    <li>
                                        <a href={PHONE_HREF}>{PHONE}</a>
                                    </li>
                                    <li>
                                        <p>{ADDRESS}</p>
                                    </li>
                                </ul>
                            </div>
                            <ul class="footer-social-links">
                                <li><a href="https://www.facebook.com/amazonbookwriters/"><i class="fa-brands fa-square-facebook"></i></a></li>
                                {/* <li><a href="javascript:;"><i class="fa-brands fa-twitter"></i></a></li>
                                <li><a href="javascript:;"><i class="fab fa-pinterest"></i></a></li> */}
                                <li><a href="https://www.instagram.com/amazonbookwriters/"><i class="fa-brands fa-instagram"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="footer-bottom">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="footer-copyright">
                                    {/* <ul class="footer-nav-links" >
                                        <li><a href="/privacy-policy">Privacy Policy</a></li>
                                        <li><a href="/terms-and-conditions ">Terms & Conditions</a></li>
                                    </ul> */}
                                    <p>Copyright © 2020-{year} {WEBSITE_NAME} All Rights Reserved.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
    
    </>
    );
  }