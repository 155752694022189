// import './App.css';
import Header from './layout/Header';
import MainBanner from './layout/MainBanner';
import AboutUs from './layout/AboutUs';
import CtaSection from './layout/CtaSection';
import Portfolio from './layout/Portfolio';
import OurSponser from './layout/OurSponser';
import Services from './layout/Services';
import CtaBanner from './layout/CtaBanner';
import Testimonial from './layout/Testimonial';
import Footer from './layout/Footer';

function Lp1() {
  return (
    <div className="App">
      <Header/>
      <MainBanner/>
      <AboutUs/>
      <CtaSection/>
      <Portfolio/>
      <OurSponser/>
      <Services/>
      <CtaBanner/>
      <Testimonial/>
      <Footer/>
    </div>
  );
}

export default Lp1;
