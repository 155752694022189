import { serviceicon2, serviceicon2dark, serviceicon3, serviceicon3dark, serviceicon4, serviceicon4dark, serviceicon5, serviceicon5dark, serviceicon6, serviceicon6dark, servicesicon1, servicesicon1dark, servicesicon2, servicesicon2dark, servicesicon3, servicesicon3dark, servicesicon4, servicesicon4dark, servicesicon5, servicesicon5dark } from '../../assets/images';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function WhatWeDoSlider() {
    const settings = {
        dots: false,
        arrows: true,
        infinite: false,
        margin: 10,
        autoplaySpeed: 2000,
        speed: 300,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false,
                }
            }
        ]
    };

    return (
        <Slider className='row justify-content-between what-we-do-slider' {...settings}>
            <div className='col-md-1-5'>
                <div className='service-icon text-center'>
                    <a href='Javascript:;'>
                        <img src={servicesicon1dark} alt='' />
                        <img src={servicesicon1} alt='' className='d-none' />
                        <h6 className='text-white'>BookPublishing</h6>
                    </a>
                </div>
            </div>
            <div className='col-md-1-5'>
                <div className='service-icon text-center'>
                    <a href='Javascript:;'>
                        <img src={servicesicon2dark} alt='' />
                        <img src={servicesicon2} alt='' className='d-none' />
                        <h6 className='text-white'>GhostWriting</h6>
                    </a>
                </div>
            </div>
            <div className='col-md-1-5'>
                <div className='service-icon text-center'>
                    <a href='Javascript:;'>
                        <img src={servicesicon3dark} alt='' />
                        <img src={servicesicon3} alt='' className='d-none' />
                        <h6 className='text-white'>Proofreading</h6>
                    </a>
                </div>
            </div>
            <div className='col-md-1-5'>
                <div className='service-icon text-center'>
                    <a href='Javascript:;'>
                        <img src={servicesicon4dark} alt='' />
                        <img src={servicesicon4} alt='' className='d-none' />
                        <h6 className='text-white'>Publication</h6>
                    </a>
                </div>
            </div>
            <div className='col-md-1-5'>
                <div className='service-icon text-center'>
                    <a href='Javascript:;'>
                        <img src={servicesicon5dark} alt='' />
                        <img src={servicesicon5} alt='' className='d-none' />
                        <h6 className='text-white'>BookWriting</h6>
                    </a>
                </div>
            </div>
            <div className='col-md-1-5'>
                <div className='service-icon text-center'>
                    <a href='Javascript:;'>
                        <img src={serviceicon2dark} alt='' />
                        <img src={serviceicon2} alt='' className='d-none' />
                        <h6 className='text-white'>NovelWriting</h6>
                    </a>
                </div>
            </div>
            <div className='col-md-1-5'>
                <div className='service-icon text-center'>
                    <a href='Javascript:;'>
                        <img src={serviceicon3dark} alt='' />
                        <img src={serviceicon3} alt='' className='d-none' />
                        <h6 className='text-white'>EBookWriting</h6>
                    </a>
                </div>
            </div>
            <div className='col-md-1-5'>
                <div className='service-icon text-center'>
                    <a href='Javascript:;'>
                        <img src={serviceicon4dark} alt='' />
                        <img src={serviceicon4} alt='' className='d-none' />
                        <h6 className='text-white'>BookPromotion</h6>
                    </a>
                </div>
            </div>
            <div className='col-md-1-5'>
                <div className='service-icon text-center'>
                    <a href='Javascript:;'>
                        <img src={serviceicon5dark} alt='' />
                        <img src={serviceicon5} alt='' className='d-none' />
                        <h6 className='text-white'>BookMarketing</h6>
                    </a>
                </div>
            </div>
            <div className='col-md-1-5'>
                <div className='service-icon text-center'>
                    <a href='Javascript:;'>
                        <img src={serviceicon6dark} alt='' />
                        <img src={serviceicon6} alt='' className='d-none' />
                        <h6 className='text-white'>BookEditing</h6>
                    </a>
                </div>
            </div>
        </Slider>
    );
}
