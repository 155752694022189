import React, { useState, useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { ghost1 } from '../../assets/images';

const ServiceSlider = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        // Check the window width and update isMobile state
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 991);
        };
        // Set initial state
        handleResize();
        // Add event listener
        window.addEventListener('resize', handleResize);
        // Clean up event listener on unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const servicesContent = [
        {
            imgSrc: ghost1,
            title: "Ghostwriting",
            description: "Our team of professional writers is versatile and ready to bring your ideas to life.",
            number: "01"
        },
        {
            imgSrc: ghost1,
            title: "Cover <br/>Design",
            description: "Get an eye-catching cover design that perfectly represents your immersive book.",
            number: "02"
        },
        {
            imgSrc: ghost1,
            title: "Audio<br/> Book",
            description: "Enjoy our talented narrators who will bring your book to life in audio format.",
            number: "03"
        },
        {
            imgSrc: ghost1,
            title: "Book <br/>Trailer",
            description: "We’ll create an impressive book trailer to help market your book effectively",
            number: "04"
        },
        {
            imgSrc: ghost1,
            title: "Author <br/>Website",
            description: "Have your own website developed to showcase all your books and connect with readers.",
            number: "05"
        },
        {
            imgSrc: ghost1,
            title: "Publishing & <br/>Printing",
            description: "Once your book is finished, we’ll help you publish it and bring it to life in print.",
            number: "06"
        },
        {
            imgSrc: ghost1,
            title: "Book<br/> Marketing",
            description: "For maximum reach, we’ll implement effective marketing strategies to promote your book.",
            number: "07"
        },
        {
            imgSrc: ghost1,
            title: "Proofreading & <br/>Formatting",
            description: "We ensure your book is polished and ready for publication with our proofreading and formatting services",
            number: "08"
        }
    ];

    return (
        <div className='services'>
            {isMobile ? (
                <OwlCarousel
                    className='owl-theme'
                    loop={false}
                    margin={10}
                    nav={false}
                    dots={true}
                    responsive={{
                        0: {
                            items: 1,
                        },
                        600: {
                            items: 2,
                        },
                        768: {
                            items: 3,
                        }
                    }}
                >
                    {servicesContent.map((service, index) => (
                        <div key={index} className='box'>
                            <img src={service.imgSrc} alt='service-img' />
                            <h4 dangerouslySetInnerHTML={{ __html: service.title }}></h4>
                            <p>{service.description}</p>
                            <h6>{service.number}</h6>
                        </div>
                    ))}
                </OwlCarousel>
            ) : (
                <div className='row'>
                    {servicesContent.map((service, index) => (
                        <div key={index} className='col-lg-3'>
                            <div className='box'>
                                <img src={service.imgSrc} alt='service-img' />
                                <h4 dangerouslySetInnerHTML={{ __html: service.title }}></h4>
                                <p>{service.description}</p>
                                <h6>{service.number}</h6>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ServiceSlider;
