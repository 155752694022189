import { bbc1, bbc2, bbc3, bbc4, bbc5, bbc6, check } from '../../assets/images'
import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export default function TopLogo() {
    const settings = {
        loop: true,
        nav: false,
        dots: false,
        margin: 10,
        responsiveClass: true,
        autoplay: true,
        autoplayHoverPause: true,
        autoplayTimeout: 8000,
        autoplaySpeed: 800,
        navText: [""],
        responsive: {
            0: {
                items: 3,
            },
            600: {
                items: 3,
            },
            1000: {
                items: 6,
            },
            360: {
                items: 3,
            }
        }
    }
    return (
        <OwlCarousel class="owl-carousel owl-site-logo" {...settings}>
            <div class="item">
                <img src={bbc1} alt="" />
            </div>
            <div class="item">
                <img src={bbc2} alt="" />
            </div>
            <div class="item">
                <img src={bbc3} alt="" />
            </div>
            <div class="item">
                <img src={bbc4} alt="" />
            </div>
            <div class="item">
                <img src={bbc5} alt="" />
            </div>
            <div class="item">
                <img src={bbc6} alt="" />
            </div>
        </OwlCarousel>
    )
}