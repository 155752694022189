import { star } from '../../assets/images'
import TestimonialSlider from '../../components/Testimonial-Slider'
// import './index.css'

export default function Testimonial() {
    return (
        <section className="testimonial-sec">
            <div className="container">
                <h2 className="testimonial-heading">Our Clients' Feedback<br /> Means Everything to Us</h2>
                <p className="testimonial-text">We love hearing from our clients! Their positive feedback <br />inspires us and affirms our commitment to excellence.</p>
                <TestimonialSlider/>
            </div>
        </section>
    )
}