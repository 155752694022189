// import React, { useState, useEffect } from 'react';
// import OwlCarousel from 'react-owl-carousel';
// import Fancybox from '../../components/Fancybox';

// import {
//     book1,
//     book10,
//     book11,
//     book12,
//     book13,
//     book14,
//     book15,
//     book16,
//     book17,
//     book18,
//     book19,
//     book2,
//     book20,
//     book21,
//     book22,
//     book23,
//     book24,
//     book3,
//     book4,
//     book5,
//     book6,
//     book7,
//     book8,
//     book9,
// } from '../../assets/images';

// const useWindowWidth = () => {
//     const [windowWidth, setWindowWidth] = useState(window.innerWidth);
//     useEffect(() => {
//         const handleResize = () => setWindowWidth(window.innerWidth);
//         window.addEventListener('resize', handleResize);
//         return () => window.removeEventListener('resize', handleResize);
//     }, []);
//     return windowWidth;
// };

// function Portfoliotabs() {
//     const [activeTab, setActiveTab] = useState('fiction');
//     const windowWidth = useWindowWidth();
//     const tabs = [
//         { name: 'fiction', label: 'FICTION' },
//         { name: 'nonfiction', label: 'NON-FICTION' },
//         { name: 'biography', label: 'BIOGRAPHY' },
//         { name: 'informative', label: 'INFORMATIVE' },
//         { name: 'autobiography', label: 'AUTOBIOGRAPHY' },
//         { name: 'memoir', label: 'MEMOIR' },
//     ];
//     const books = {
//         fiction: [book9, book10, book11, book12],
//         nonfiction: [book21, book22, book23, book24],
//         biography: [book5, book6, book7, book8],
//         informative: [book13, book14, book15, book16],
//         autobiography: [book1, book2, book3, book4],
//         memoir: [book17, book18, book19, book20],
//     };

//     const options = {
//         loop: true,
//         margin: 10,
//         responsive: {
//             0: { items: 1 },
//             575: { items: 2 },
//             768: { items: 3 },
//         },
//     };

//     return (
//         <>
//             <ul className="nav nav-tabs custom-tabs">
//                 {tabs.map((tab) => (
//                     <li className="nav-item" key={tab.name}>
//                         <button
//                             className={`nav-link ${activeTab === tab.name ? 'active' : ''}`}
//                             onClick={() => setActiveTab(tab.name)}
//                         >
//                             {tab.label}
//                         </button>
//                     </li>
//                 ))}
//             </ul>

//             <div className="tab-content mt-4">
//                 <Fancybox
//                     options={{
//                         Carousel: {
//                             infinite: true,
//                         },
//                     }}
//                 >
//                     {windowWidth <= 991 ? (
//                         <OwlCarousel className="owl-theme" {...options}>
//                             {books[activeTab].map((img, index) => (
//                                 <div className="item" key={index}>
//                                     <a href={img} data-fancybox="gallery">
//                                         <img src={img} className="card-img-top" alt="book" />
//                                     </a>
//                                 </div>
//                             ))}
//                         </OwlCarousel>
//                     ) : (
//                         <div className="row">
//                             {books[activeTab].map((img, index) => (
//                                 <div className="col-lg-3" key={index}>
//                                     <div className="item">
//                                         <a href={img} data-fancybox="gallery">
//                                             <img src={img} className="card-img-top" alt="book" />
//                                         </a>
//                                     </div>
//                                 </div>
//                             ))}
//                         </div>  
//                     )}
//                 </Fancybox>
//             </div>
//         </>
//     );
// }

// export default Portfoliotabs;


import React, { useState, useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Fancybox } from '@fancyapps/ui'; // Import Fancybox

import {
    book1, book10, book11, book12, book13, book14, book15, book16, book17, book18, book19,
    book2, book20, book21, book22, book23, book24, book3, book4, book5, book6, book7, book8, book9,
} from '../../assets/images';

const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowWidth;
};

function Portfoliotabs() {
    const [activeTab, setActiveTab] = useState('fiction');
    const windowWidth = useWindowWidth();
    const tabs = [
        { name: 'fiction', label: 'FICTION' },
        { name: 'nonfiction', label: 'NON-FICTION' },
        { name: 'biography', label: 'BIOGRAPHY' },
        { name: 'informative', label: 'INFORMATIVE' },
        { name: 'autobiography', label: 'AUTOBIOGRAPHY' },
        { name: 'memoir', label: 'MEMOIR' },
    ];
    const books = {
        fiction: [book9, book10, book11, book12],
        nonfiction: [book21, book22, book23, book24],
        biography: [book5, book6, book7, book8],
        informative: [book13, book14, book15, book16],
        autobiography: [book1, book2, book3, book4],
        memoir: [book17, book18, book19, book20],
    };

    const options = {
        loop: true,
        margin: 10,
        responsive: {
            0: { items: 1 },
            575: { items: 2 },
            768: { items: 3 },
        },
    };

    return (
        <>
            <ul className="nav nav-tabs custom-tabs">
                {tabs.map((tab) => (
                    <li className="nav-item" key={tab.name}>
                        <button
                            className={`nav-link ${activeTab === tab.name ? 'active' : ''}`}
                            onClick={() => setActiveTab(tab.name)}
                        >
                            {tab.label}
                        </button>
                    </li>
                ))}
            </ul>

            <div className="tab-content mt-4">
                <div>
                    {windowWidth <= 991 ? (
                        <OwlCarousel className="owl-theme" {...options}>
                            {books[activeTab].map((img, index) => (
                                <div className="item" key={index}>
                                    <a href={img} data-fancybox="gallery">
                                        <img src={img} className="card-img-top" alt="book" />
                                    </a>
                                </div>
                            ))}
                        </OwlCarousel>
                    ) : (
                        <div className="row">
                            {books[activeTab].map((img, index) => (
                                <div className="col-lg-3" key={index}>
                                    <div className="item">
                                        <a href={img} data-fancybox="gallery">
                                            <img src={img} className="card-img-top" alt="book" />
                                        </a>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default Portfoliotabs;
