import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { book1, book2, book3, book4, book5, book6, book7, cryptoinsights } from '../../assets/images'

export default function PortfolioSlider() {
    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        //   autoplay: true,
        autoplaySpeed: 2000,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [{
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                dots: true,
                arrows: false
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 2000,
                dots: false,
                arrows: false
            }
        }
        ]
    }
    return (
        <>
            <Slider class="row porfolio-responsive-slider" {...settings}>
                <div class="col-lg-12">
                    <div class="port-img">
                        <img src={book7} class="img-fluid lazyload" alt="" />
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="port-img">
                        <img src={cryptoinsights} class="img-fluid lazyload" alt="" />
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="port-img">
                        <img src={book4} class="img-fluid lazyload" alt="" />
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="port-img">
                        <img src={book3} class="img-fluid lazyload" alt="" />
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="port-img">
                        <img src={book1} class="img-fluid lazyload" alt="" />
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="port-img">
                        <img src={book6} class="img-fluid lazyload" alt="" />
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="port-img">
                        <img src={book5} class="img-fluid lazyload" alt="" />
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="port-img">
                        <img src={book2} class="img-fluid lazyload" alt="" />
                    </div>
                </div>
            </Slider>
        </>
    )
}