import { ctabook } from "../../assets/images";
import { ModalSection } from "../../components/modalSection";
import { EMAIL, EMAIL_HREF } from "../../config";
// import './index.css'
import React, { useState } from 'react';

export default function CtaSection() {
    const [open, setOpen] = useState(false);

    const onOpenModal = () => {
        setOpen(true);
    };

    const onCloseModal = () => {
        setOpen(false);
    };
    return (
        <>
            <section className="cta-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="cta-img">
                                <img src={ctabook} className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="cta-text">
                                <h2>Ready to Start Your Writing Journey?</h2>
                                <p className="text"> Get started now and enjoy <span> 30% OFF </span>your journey to becoming a published author! Don't miss this chance to bring your story to life!</p>
                                <div className="cta-email">
                                    <p>DISCUSS YOUR IDEAS</p>
                                    <a href={EMAIL_HREF}>{EMAIL}</a>
                                </div>
                                <a href="Javascript:;" className="cta-btn" onClick={onOpenModal}>Activate Coupon Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ModalSection open={open} onCloseModal={onCloseModal} />
        </>
    )
}