import {WEBSITE_NAME, PHONE, PHONE_HREF} from '../../config';
import './index.css'; 
import axios from 'axios';
import {
    GiveStoryImg,
    WhyChooseBackImg
} from '../../assets/images';
import ReviewsData from '../../data/reviews.json';
import { useChat } from '../../ChatContext';
import { useModal } from '../../ModalContext';
import ReviewsCounterSlider from '../../components/ReviewsComponents/ReviewsCounterSlider';
import ReviewBannerSlider from '../../components/ReviewsComponents/ReviewBannerSlider';
import ReviewsTabs from '../../components/ReviewsComponents/ReviewsTabs';
import CtaSection from '../../GlobalSections/Cta';
import PartnerLogosSlider from '../../GlobalSections/PartnerLogos';
import SmmReviewSlider from '../../components/ReviewsComponents/SmmReviewSlider';
import FooterFormSec from '../../GlobalSections/FooterFormSec';

export default function ReviewsPage() {
    const { toggleChat } = useChat();
    const { openModal } = useModal();
    const actionHandlers = {
        openModal,
        toggleChat
    };
    const replacePlaceholders = (text) => {
        return text
            .replace(/{{WEBSITE_NAME}}/g, WEBSITE_NAME);
    };

    return (
        <>  
            <section className='review_banner'>
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-md-6'>
                            <h1 className='text_clr_light'>Reviews of Amazon Book Writers and Their Long-Lasting Customer Experiences</h1>
                            <p className='text_clr_light'>Testimonials from numerous potential authors highlight the reliance on Amazon Book Writers due to their punctual deliveries, distinctive content, and expert book writing services. Explore what previous customers have to say about the quality of their work, the professionalism of their team, and their customer service in these Amazon Book Writers reviews.</p>
                        </div>
                        <div className='col-md-6'>
                            <ReviewBannerSlider/>
                        </div>
                    </div>
                </div>
            </section>

        <section class="tab-agencies-wrap">
            <div class="container">
                <div className='counter_wrap'>
                    <ReviewsCounterSlider/>
                </div>
                <ReviewsTabs/>
            </div>
        </section>
        <CtaSection data={ReviewsData}/>

        <section class="testi-sec-wrap">
            <div class="container-fluid">
                <div class="testi-sec-head">
                    <h2>Social Media Is Buzzing With Positive Client Reviews: <br/>Discover What People Have to Say About {WEBSITE_NAME}</h2>
                    <p>{WEBSITE_NAME} receives positive feedback from followers
                        around the world.</p>
                </div>
                <div class="testi-sec-inner">
                    <SmmReviewSlider/>
                </div>
            </div>
        </section>
        <PartnerLogosSlider data={ReviewsData}/>

        <FooterFormSec data={ReviewsData} />
        </>
    );
}