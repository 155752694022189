import { spon1, spon2, spon3, spon4 } from "../../assets/images";
import MainButton from "../../components/MainButton";
import SponserSlider from "../../components/SponserSlider";
// import './index.css'

export default function OurSponser() {
    return (
        <>
            <section className="sponser-sec">
                <div className="container">
                    <SponserSlider/>
                    <MainButton position="center" />
                </div>
            </section>
        </>
    )
}