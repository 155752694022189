import TestimonialSlider from '../../components/TestimonialSlider'
// import './index.css'


export default function TestimonialSection() {
    return (
        <>
            <section className="testimonial-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <h2>What Our Clients Say</h2>
                            <p>Our dedication to your success shines through the voices of those we've helped. Here’s what our valued clients have to say about their experiences with us</p>
                        </div>
                        <div className="col-lg-7">
                            <TestimonialSlider />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}