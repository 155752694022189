import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { clientlogo4, clientlogo6, image2021, imagespark } from "../../assets/images";

const BrandLogoSlider = () => {
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 1023);

    const handleResize = () => {
        setIsMobileView(window.innerWidth <= 1023);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows : false ,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,

                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    return (
        <div className="brands">
            {isMobileView ? (
                <Slider {...settings}>
                    <div className="item">
                        <img src={clientlogo6} alt="Client Logo 6" />
                    </div>
                    <div className="item">
                        <img src={clientlogo4} alt="Client Logo 4" />
                    </div>
                    <div className="item">
                        <img src={imagespark} alt="Image Spark" className='img-gray'/>
                    </div>
                    <div className="item">
                        <img src={image2021} alt="Image 2021" className='ml-md-5'/>
                    </div>
                </Slider>
            ) : (
                <div>
                    <div className="item">
                        <img src={clientlogo6} alt="Client Logo 6" />
                    </div>
                    <div className="item">
                        <img src={clientlogo4} alt="Client Logo 4" />
                    </div>
                    <div className="item">
                        <img src={imagespark} alt="Image Spark" className='img-gray'/>
                    </div>
                    <div className="item">
                        <img src={image2021} alt="Image 2021"/>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BrandLogoSlider;
