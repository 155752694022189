import React, { useState } from 'react';
import axios from 'axios';
import { WEBSITE_NAME } from '../../config';
import { Loader } from '../../assets/images';
import './index.css';

const InnerBnrForm = () => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    phone: '',
  });

  
  const onSubmitFunc = async (e) => {
    e.preventDefault();

    console.log('formData', formData);
    if (formData.phone.length < 9) {
      setErrors(true);
    } else {
      const currentUrl = window.location.href;
      const protocol = window.location.protocol;
      const hostname = window.location.hostname;
      const baseUrl = `${protocol}//${hostname}`;
      const queryStringFormData = new URLSearchParams(formData).toString();

      let finalReq = {
        ...formData,
        source: `${currentUrl}${queryStringFormData}`,
        domain: baseUrl,
        lead_url: currentUrl,
        url: `${currentUrl}${queryStringFormData}`,
      };

      try {
        setLoading(true);
        const apiUrl = 'https://tgcrm.net/api/form_submission?brand_key=148453';
        const config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };
        const formDataString = new URLSearchParams(finalReq).toString();

        

        const response = await axios.post(apiUrl, formDataString, config);
        if (response.status === 200) {
          const queryString = new URLSearchParams(
            response.data.data
        ).toString();
        console.log('queryString', queryString);
          setLoading(false);
          setErrors(false);
          setFormData({
            name: '',
            email: '',
            message: '',
            phone: '',
          });
          window.location.href = `https://www.amazonbookwriters.com/thankyou?${queryString}`;
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  return (
    <div class="inner-banner-form">
        <h3><span>Let's Work Together </span></h3>
        <p>Tell us about your project</p>

        <form class="serv-banner-form form_submission" method="POST" onSubmit={onSubmitFunc}>
            <div class="row">
                <div class="col-sm-12">
                    <input type="text" name="name" placeholder="First Name" required="" onChange={(e) =>setFormData({ ...formData, name: e.target.value })}/>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <input name="phone" type="number" placeholder="Phone Number" required="" onChange={(e) =>setFormData({ ...formData, phone: e.target.value })}/>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <input type="email" name="email" placeholder="Enter email here *" required="" onChange={(e) =>setFormData({ ...formData, email: e.target.value })}/>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <textarea placeholder="About Your Project" name="message" onChange={(e) =>setFormData({ ...formData, message: e.target.value })}></textarea>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    {loading == true ? (
                          <div className="loader">
                          <img alt="loader" src={Loader}/>
                          </div>
                      ) : (
                        <button class="default_btn red_btn" type="submit">Submit</button>
                      )
                    }
                </div>
            </div>
        </form>
    </div>
  );
};

export default InnerBnrForm;