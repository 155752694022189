import { PHONE, PHONE_HREF, WEBSITE_NAME } from '../../config';
import { DiscountCtaImg } from './../../assets/images';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useChat } from '../../ChatContext';
import { useModal } from '../../ModalContext';
import './index.css'; // Custom CSS for hover behavior

export default function CtaSection({data}) {
    const { toggleChat } = useChat();
    const { openModal } = useModal();
    const actionHandlers = {
        openModal,
        toggleChat
    };

    const replacePlaceholders = (text) => {
        if (!text || !text.includes('{{WEBSITE_NAME}}')) {
            // If text is undefined/null or does not contain the placeholder, return it as is
            return text;
        }
        // Replace {{WEBSITE_NAME}} if found in text
        return text.replace(/{{WEBSITE_NAME}}/g, WEBSITE_NAME);
    };

    return (
        <section class="sec_cta sec_pt sec_pb">
            <div class="container">
                <div class="row cta_inner">
                    <div class="col-md-5 col-sm-12">
                        <div class="cta_left wow fadeInUp animate__animated">
                            <img loading="lazy" src={DiscountCtaImg} alt="discount" width="206" height="211"
                                class="img-fluid"/>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="cta_right wow fadeInRight animate__animated">
                            <h3>{replacePlaceholders(data.GlobalCta.title)}</h3>
                            <p>{replacePlaceholders(data.GlobalCta.description)}</p>
                            <div class="btn_wrap">
                                {data.GlobalCta.ctaButtons.map((button, index) => {
                                    const handleClick = actionHandlers[button.handleClick];
                                    return (
                                        <a
                                            key={index}
                                            onClick={handleClick}
                                            href={button.link}
                                            className={`default_btn ${button.className}`}
                                        >
                                            {button.text}
                                        </a>
                                    );
                                })}
                            </div>
                            <div class="number">
                                <a href={PHONE_HREF}>{PHONE}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
