// import './index.css'

export default function CtaSection(){
    return(
        <>
        <section className="cta-sec">
            <div className="container">
                <div className="cta-text">
                    <h1 data-aos="fade-up" data-aos-duration="1500">Start Marketing Your Book Today!</h1>
                    <h5 data-aos="fade-down" data-aos-duration="1500">Take the Next Step Toward Your Book's Success!</h5>
                    <p data-aos="fade-up" data-aos-duration="1500">Reach out now to discover how our expert book marketing services can help you connect <br/>with more readers and achieve your publishing goals!</p>
                    <a href="Javascript:;" className="cta-chat chat" data-aos="fade-down" data-aos-duration="1500" >I HAVE A BOOK, LET'S CHAT</a>
                </div>
            </div>
        </section>
        </>
    )
}