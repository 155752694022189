import { bestcontentmarketing, clutchbadge, designrushbadge, topmarkeintgagencies } from '../../assets/images'
import BannerForm from '../../Components/BannerForm'
import Button from '../../Components/Button'
// import './index.css'

export default function MainBanner() {
    return (
        <section className="banner-section">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="banner-text">
                            <p>RANKED #1 GHOSTWRITING COMPANY</p>
                            <h2>Refine Your Manuscript <br /> with Expert Editing!</h2>
                            <p>You’ve poured your heart into your manuscript, now it’s time to ensure it stands out! With our book editing services, we are here to help you polish your writing and make it clear and engaging.</p>
                            <Button/>
                            <ul className='p-0 mt-4'>
                                <li>
                                    <img src={bestcontentmarketing} alt='lazyload' />
                                </li>
                                <li>
                                    <img src={clutchbadge} alt='lazyload' />
                                </li>
                                <li>
                                    <img src={designrushbadge} alt='lazyload' />
                                </li>
                                <li>
                                    <img src={topmarkeintgagencies} alt='lazyload' />
                                </li>
                            </ul>
                            <p>
                                Rated 9 out of 10
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                based on 3,543 satisfied customers.
                            </p>
                        </div>
                    </div>
                    <div className='col-lg-4 offset-lg-2'>
                        <div className='book-editing-form'>
                            <BannerForm/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}