import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { WEBSITE_NAME } from '../../config';
import { services } from './../../data/services/services';
import './index.css';

const BreadcrumbCustom = () => {
  const location = useLocation();
  console.log('location', location);
  const pathSegments = location.pathname.split('/').filter((path) => path && path !== 'revamp');
  console.log('pathSegments: ', pathSegments[0]);

  const formatBreadcrumbName = (name) => {
    return name
      .replace(/-/g, ' ') // Replace dashes with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
  };

  const breadcrumbs = [{ path: "/", name: WEBSITE_NAME }];

  Object.keys(services).forEach(key => {
    const mainService = services[key];
    console.log(`Main Service: ${mainService.name}`);

    if (mainService.name === pathSegments[0]) {
      console.log(`Match found at Main Service: ${mainService.name}`);

      breadcrumbs.push({ path: mainService.path, name: mainService.name });
      return;
    }

    if (mainService.subServices && mainService.subServices.length > 0) {
      mainService.subServices.forEach(subService => {
        if (subService.name === pathSegments[0]) {
          console.log(`Match found! Main Service: ${mainService.name}`);

          breadcrumbs.push({ path: mainService.path, name: mainService.name });
          breadcrumbs.push({ path: subService.path, name: subService.name });
        } else {
          console.log(`  Sub-Service: ${subService.name}`);
        }
      });
    } else {
      console.log('  No sub-services');
    }
  });

  console.log('Breadcrumbs List:', breadcrumbs);

  return (
    <div className='breadcrumb'>
      <div className='container'>
        <ul>
          {breadcrumbs.map((crumb, index) => (
            <li key={index}>
              <Link to={crumb.path}>{formatBreadcrumbName(crumb.name)}</Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default BreadcrumbCustom;
