import {PHONE, WEBSITE_NAME, EMAIL, EMAIL_HREF} from '../../config';
import React from 'react';
import InnerBanner from '../../GlobalSections/InnerBanner';
import TermsConditionsData from '../../data/TermsConditionsData.json';

export default function TermsConditions() {
    return (
        <>
        <InnerBanner data={TermsConditionsData}/>
        <section class="main-privacy sec_pt sec_pb">
        <div class="container">
            
            <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12 terms">
                    <div class="term-text">
                            <h2>OVERVIEW</h2>
                            <p>The use of this website and/or our services constitutes your agreement to the following
                                terms
                                and conditions:If you do not agree with any of these terms and conditions, please do not
                                use
                                this Website or Our services.If you are under legal age of consent for your respective
                                district than you are not allowed to access or use this Website or Our services.
                                You acknowledge and agree that you must be of legal age to purchase any of our products
                                or
                                services available on this Website or otherwise.By submitting an order and/or payment,
                                you
                                are acknowledging that you have read and understood these terms and conditions. You are
                                also
                                agreeing to be legally bound by these terms and conditions, which form the entire
                                agreement
                                between You and penguinbookwriters.com/.
                                By submitting an order and/or payment, you are acknowledging that you have read and
                                understood these terms and conditions. You are also agreeing to be legally bound by
                                these
                                terms and conditions, which form the entire agreement between You and
                                penguinbookwriters.com/.
                            </p>
                            <h2>DEFINITION OF TERMS</h2>
                            <p><span>"Website"</span>means all online content on penguinbookwriters.com/ website pages.
                            </p>
                            <p><span>Customer," "You," "Yours"</span>or means and refers to you and/ or any other person
                                submitting an order to penguinbookwriters.com/ on your behalf.</p>
                            <p><span>"Company," "We," "Our" </span>or means and refers to penguinbookwriters.com/, a
                                company
                                registered under the laws of the State.</p>
                            <p><span>"Product or Services"</span>means and refers to all services and products provided
                                by
                                penguinbookwriters.com/ to customer in accordance with his/ her order.</p>
                            <p><span>"Order" </span>means an order via phone or e-mail made by customer to purchase
                                services
                                or products provided by penguinbookwriters.com/. Orders are confirmed by down payments
                                via
                                check, credit card, cash receipt, bank wire transfer, Western Union, or PayPal transfer.
                            </p>
                            <h2>OUR SERVICES</h2>
                            <p>It is important that you carefully read and understand the refund policy in order to have
                                full knowledge of the privileges and limitations governed by the
                               {WEBSITE_NAME}
                                Policy. It is your obligation to read these Terms and Conditions before submitting any
                                Order
                                and/ or payment to this Website.</p>
                            <h2>RETURN</h2>
                            <p>We do not accept any returns on fulfilled orders due to the nature of the products we
                                produce.</p>
                            <p>If changes need to be made to ensure quality standards such as editing and proofreading
                                services are adhered to, alongside requirements being followed, we have a revision
                                process
                                in place that is there to help all customers as their satisfaction is the most important
                                thing to us.
                                If the fulfilled product does not meet the requirements, as specified at the placement
                                of
                                the order, we will begin an extensive internal review and either revise or recreate the
                                content at no additional cost.</p>
                            <h2>REVISIONS</h2>
                            <p>Each package has its own revision policy, per the service description of each individual
                                package. We provide revisions within 5 to 10 days of the order going into the Waiting
                                for
                                Customer Approval status in our Order Management Platform. The revision period will
                                depend
                                on the ordered package.</p>
                            <p>Revisions come into play at the end of the order process, once the product has been
                                presented
                                for review and the status has updated to Waiting for Customer Approval. At this time,
                                the
                                customer may request revisions directly by sending their assigned Project Manager an
                                email
                                to ensure that the original provided requirements have been met on the entire product.
                                The customer is always welcome to make changes personally to any content they receive
                                from
                                us after the order has been fulfilled and before publishing as it is delivered in a Word
                                format and they own the rights to it.</p>
                            <h2>REFUND POLICY</h2>
                            <p>We handle refunds on a case-by-case basis and do our best to stand behind the customer
                                and
                                take care of their needs.
                                Our team will always work with the customer to resolve any issues to the best of our
                                ability.</p>
                            <p>Circumstances in which a refund may be provided include, but are not limited to, should
                                there
                                be exceptional reason, orders which have not yet been allocated and/or started at the
                                time
                                of the refund request.</p>
                            <p>Refunds, if processed, take between 5 to 15 days to reflect on the customer’s end. A
                                processing fee of USD 250 or 5% of the order amount (whichever is higher) will be
                                charged in
                                all cases. The confirmation of approval for the refund request will be provided by the
                                support team and can only be made through the original payment method used at the
                                placement
                                of the order. You will also receive a notification of refund via the email used at the
                                original time of checkout.</p>
                            <p>No refund on change of mind. We offer refunds only in special cases and specified conditions, detailed as under:</p>
                            <h2>CHANGE OF MIND:</h2>
                            <p>a) Prior to Project Initiation: The customer is entitled to 100% refund before our
                                writers/
                                editors start working on the project.</p>
                            <p>b) If (for any reason) you change your mind and decide to discontinue your project with
                                us
                                after placing your order, you can ask for a refund within one hour of placing your
                                order.
                            </p>
                            <p>c) After Project Progress: All refund claims made after the project has been initiated
                                and at
                                least one round of revision is availed by the Client, we may provide a partial refund
                                (up to
                                5%) owing to the need to fairly compensate our internal teams.</p>
                            <h2>INCOMPETENT DELIVERY:</h2>
                            <p>Once the work is delivered, customers are only entitled to claim a refund once they have
                                exhausted all the options detailed as under;</p>
                            <ul>
                                <li>If it does not comply with project requirements (as requested/documented by the
                                    customer).</li>
                                <li>We are committed to provide our customers with 100% satisfaction and offer unlimited
                                    revisions to ensure that the delivery is up to the mark. We assign, re-assign and
                                    re-write your work to ensure complete satisfaction. Reserve FREE Pages for future,
                                    but
                                    of same value and you can avail them at any time. If we're still not able to deliver
                                    what you asked for, refund will be processed with a mutual agreement on a set
                                    percentage
                                    (but only in the cases where the delivery is completely off the mark)</li>
                            </ul>
                            <h2>LATE DELIVERY:</h2>
                            <p>We believe in "On Time Delivery" but if, for any reason we fail to deliver the asked
                                service
                                on time, after at least three attempts to contact us, your refund will be processed once
                                it
                                is established through documentary evidence that the late delivery was the company's
                                fault.
                            </p>
                            <h2>REFUND TIME FRAME:</h2>
                            <p>Refunds must be claimed within 10 days of first submission/order delivery subject to no
                                revisions availed. Refunds claimed after the set time frame will not be entertained.
                            </p>
                            <p>All the customers must note the deadline for claiming refund at the time of placing
                                order.
                            </p>
                            <h2>CASES WHERE REFUND WILL NOT BE ISSUED:</h2>
                            <ul>
                                <li>In case of late delivery due to some minor technical errors, such as grammatical,
                                    typing, word count, missing references etc., refunds will be processed with mutual
                                    agreement and company will only settle with partial refund or discounts reserved for
                                    future purchases.</li>
                                <li>Company will not be responsible for any delay from the client's end.</li>
                                <li>No refund will be issued on the basis of low word count.</li>
                            </ul>
                            <h2>MODIFICATIONS TO THE SERVICE AND PRICES</h2>
                            <p>Prices and discounts for our services are subject to change without notice</p>
                            <p>We reserve the right at any time to modify or discontinue the Service (or any part or
                                content
                                thereof) without notice at any time.</p>
                            <p>We shall not be liable to you or to any third-party for any modification, price change,
                                suspension or discontinuance of the Service.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
        </>
    );
}