// import React,{useState} from 'react';
// import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
// import { fictionimg1, fictionimg2, fictionimg3, fictionimg4, fictionimg5, fictionimg6, historyimg1, historyimg2, historyimg3, historyimg4, historyimg5, historyimg6, nonfictionimg1, nonfictionimg2, nonfictionimg3, nonfictionimg4, nonfictionimg5, nonfictionimg6, romanceimg1, romanceimg2, romanceimg3, romanceimg4, romanceimg5, romanceimg6, thrillerimg1, thrillerimg2, thrillerimg3, thrillerimg4, thrillerimg5, thrillerimg6 } from '../../assets/images'

// export default function PortfolioTab() {
    
//     const [activeTab, setActiveTab] = useState(0); // Control active tab

//     const handleTabClick = (index) => {
//         console.log("Clicked Tab Index:", index);
//         setActiveTab(index);
//         console.log("Current Active Tab:", activeTab);
//     };
//     const options = {
//         loop: false,
//         margin: 10,
//         responsive: {
//             0: {
//                 items: 1, // 1 item for mobile devices
//             },
//             // 600: {
//             //     items: 2, // 2 items for small tablets
//             // },
//             480 :{
//                 items : 2,
//             },
//             1000: {
//                 items: 3, // 3 items for larger devices
//             },
//         },
//     };
//     return (
//         <>
//             <div className='portfolio-tab' data-aos="zoom-in" data-aos-duration="1500">
//                 <div className='row'>
//                     <div className='col-md-3'>
//                         <ul class="nav nav-tabs" id="myTab" role="tablist">
//                             <li
//                                 className={`nav-item ${activeTab === 0 ? 'active' : ''}`}
//                                 onClick={() => handleTabClick(0)}
//                                 style={{ cursor: 'pointer' }}
//                             >
//                                 <a class="nav-link" data-toggle="tab" href="#fiction" role="tab" aria-controls="fiction">Fiction</a>
//                             </li>
//                             <li
//                                 className={`nav-item ${activeTab === 1 ? 'active' : ''}`}
//                                 onClick={() => handleTabClick(1)}
//                                 style={{ cursor: 'pointer' }}
//                             >
//                                 <a class="nav-link" data-toggle="tab" href="#non-fiction" role="tab" aria-controls="non-fiction">History</a>
//                             </li>
//                             <li
//                                 className={`nav-item ${activeTab === 2 ? 'active' : ''}`}
//                                 onClick={() => handleTabClick(2)}
//                                 style={{ cursor: 'pointer' }}
//                             >
//                                 <a class="nav-link" data-toggle="tab" href="#biography" role="tab" aria-controls="biography">Memoirs</a>
//                             </li>
//                             <li
//                                 className={`nav-item ${activeTab === 3 ? 'active' : ''}`}
//                                 onClick={() => handleTabClick(3)}
//                                 style={{ cursor: 'pointer' }}
//                             >
//                                 <a class="nav-link" data-toggle="tab" href="#informative" role="tab" aria-controls="informative">Thriller</a>
//                             </li>
//                             <li
//                                 className={`nav-item ${activeTab === 4 ? 'active' : ''}`}
//                                 onClick={() => handleTabClick(4)}
//                                 style={{ cursor: 'pointer' }}
//                             >
//                                 <a class="nav-link last-child" data-toggle="tab" href="#autobiography" role="tab" aria-controls="autobiography">Biographies</a>
//                             </li>
//                         </ul>
//                     </div>
//                     <div className='col-md-9'>
//                         <div class="tab-content">
//                             <div class="tab-pane active" id="fiction" role="tabpanel" style={{ display: activeTab === 0 ? 'block' : 'none' }}>
//                                 <div className="work-portfolio">
//                                     <OwlCarousel className="owl-theme" {...options}>
//                                         <div className="item">
//                                             <img src={fictionimg1} alt="" className='img-fluid' />
//                                         </div>
//                                         <div className="item">
//                                             <img src={fictionimg2} alt="" className='img-fluid' />
//                                         </div>
//                                         <div className="item">
//                                             <img src={fictionimg3} alt="" className='img-fluid' />
//                                         </div>
//                                         <div className="item">
//                                             <img src={fictionimg4} alt="" className='img-fluid' />
//                                         </div>
//                                         <div className="item">
//                                             <img src={fictionimg5} alt="" className='img-fluid' />
//                                         </div>
//                                         <div className="item">
//                                             <img src={fictionimg6} alt="" className='img-fluid' />
//                                         </div>
//                                     </OwlCarousel>
//                                 </div>
//                             </div>
//                             <div class="tab-pane" id="non-fiction" role="tabpanel" style={{ display: activeTab === 1 ? 'block' : 'none' }}>
//                                 <div class="work-portfolio">
//                                     <OwlCarousel className="owl-theme" {...options}>
//                                         <div class="item">
//                                             <img src={nonfictionimg1} alt="" className='img-fluid' />
//                                         </div>
//                                         <div class="item">
//                                             <img src={nonfictionimg2} alt="" className='img-fluid' />
//                                         </div>
//                                         <div class="item">
//                                             <img src={nonfictionimg3} alt="" className='img-fluid' />
//                                         </div>
//                                         <div class="item">
//                                             <img src={nonfictionimg4} alt="" className='img-fluid' />
//                                         </div>
//                                         <div class="item">
//                                             <img src={nonfictionimg5} alt="" className='img-fluid' />
//                                         </div>
//                                         <div class="item">
//                                             <img src={nonfictionimg6} alt="" className='img-fluid' />
//                                         </div>
//                                     </OwlCarousel>
//                                 </div>
//                             </div>
//                             <div class="tab-pane" id="biography" role="tabpanel" style={{ display: activeTab === 2 ? 'block' : 'none' }}>
//                                 <div class="work-portfolio">
//                                     <OwlCarousel className="owl-theme" {...options}>
//                                         <div class="item">
//                                             <img src={historyimg1} alt="" className='img-fluid' />
//                                         </div>
//                                         <div class="item">
//                                             <img src={historyimg2} alt="" className='img-fluid' />
//                                         </div>
//                                         <div class="item">
//                                             <img src={historyimg3} alt="" className='img-fluid' />
//                                         </div>
//                                         <div class="item">
//                                             <img src={historyimg4} alt="" className='img-fluid' />
//                                         </div>
//                                         <div class="item">
//                                             <img src={historyimg5} alt="" className='img-fluid' />
//                                         </div>
//                                         <div class="item">
//                                             <img src={historyimg6} alt="" className='img-fluid' />
//                                         </div>
//                                     </OwlCarousel>
//                                 </div>
//                             </div>
//                             <div class="tab-pane" id="informative" role="tabpanel" style={{ display: activeTab === 3 ? 'block' : 'none' }}>
//                                 <div class="work-portfolio">
//                                     <OwlCarousel className="owl-theme" {...options}>
//                                         <div class="item">
//                                             <img src={romanceimg1} alt="" className='img-fluid' />
//                                         </div>
//                                         <div class="item">
//                                             <img src={romanceimg2} alt="" className='img-fluid' />
//                                         </div>
//                                         <div class="item">
//                                             <img src={romanceimg3} alt="" className='img-fluid' />
//                                         </div>
//                                         <div class="item">
//                                             <img src={romanceimg4} alt="" className='img-fluid' />
//                                         </div>
//                                         <div class="item">
//                                             <img src={romanceimg5} alt="" className='img-fluid' />
//                                         </div>
//                                         <div class="item">
//                                             <img src={romanceimg6} alt="" className='img-fluid' />
//                                         </div>
//                                     </OwlCarousel>
//                                 </div>
//                             </div>
//                             <div class="tab-pane" id="autobiography" role="tabpanel" style={{ display: activeTab === 4 ? 'block' : 'none' }}>
//                                 <div class="work-portfolio">
//                                     <OwlCarousel className="owl-theme" {...options}>
//                                         <div class="item">
//                                             <img src={thrillerimg1} alt="" className='img-fluid' />
//                                         </div>
//                                         <div class="item">
//                                             <img src={thrillerimg2} alt="" className='img-fluid' />
//                                         </div>
//                                         <div class="item">
//                                             <img src={thrillerimg3} alt="" className='img-fluid' />
//                                         </div>
//                                         <div class="item">
//                                             <img src={thrillerimg4} alt="" className='img-fluid' />
//                                         </div>
//                                         <div class="item">
//                                             <img src={thrillerimg5} alt="" className='img-fluid' />
//                                         </div>
//                                         <div class="item">
//                                             <img src={thrillerimg6} alt="" className='img-fluid' />
//                                         </div>
//                                     </OwlCarousel>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     )
// }



// import React, { useState } from 'react';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import { fictionimg1, fictionimg2, fictionimg3, fictionimg4, fictionimg5, fictionimg6, historyimg1, historyimg2, historyimg3, historyimg4, historyimg5, historyimg6, nonfictionimg1, nonfictionimg2, nonfictionimg3, nonfictionimg4, nonfictionimg5, nonfictionimg6, romanceimg1, romanceimg2, romanceimg3, romanceimg4, romanceimg5, romanceimg6, thrillerimg1, thrillerimg2, thrillerimg3, thrillerimg4, thrillerimg5, thrillerimg6 } from '../../assets/images'

// export default function PortfolioTab() {
    
//     const [activeTab, setActiveTab] = useState(0); // Control active tab

//     const handleTabClick = (index) => {
//         console.log("Clicked Tab Index:", index);
//         setActiveTab(index);
//         console.log("Current Active Tab:", activeTab);
//     };
    
//     const settings = {
//         dots: true,
//         infinite: false,
//         speed: 500,
//         slidesToShow: 3,
//         slidesToScroll: 1,
//         responsive: [
//             {
//                 breakpoint: 1000,
//                 settings: {
//                     slidesToShow: 3,
//                 },
//             },
//             {
//                 breakpoint: 480,
//                 settings: {
//                     slidesToShow: 2,
//                 },
//             },
//             {
//                 breakpoint: 0,
//                 settings: {
//                     slidesToShow: 1,
//                 },
//             },
//         ],
//     };

//     return (
//         <>
//             <div className='portfolio-tab' data-aos="zoom-in" data-aos-duration="1500">
//                 <div className='row'>
//                     <div className='col-md-3'>
//                         <ul className="nav nav-tabs" id="myTab" role="tablist">
//                             <li
//                                 className={`nav-item ${activeTab === 0 ? 'active' : ''}`}
//                                 onClick={() => handleTabClick(0)}
//                                 style={{ cursor: 'pointer' }}
//                             >
//                                 <a className="nav-link active" data-toggle="tab" href="#fiction" role="tab" aria-controls="fiction">Fiction</a>
//                             </li>
//                             <li
//                                 className={`nav-item ${activeTab === 1 ? 'active' : ''}`}
//                                 onClick={() => handleTabClick(1)}
//                                 style={{ cursor: 'pointer' }}
//                             >
//                                 <a className="nav-link" data-toggle="tab" href="#non-fiction" role="tab" aria-controls="non-fiction">History</a>
//                             </li>
//                             <li
//                                 className={`nav-item ${activeTab === 2 ? 'active' : ''}`}
//                                 onClick={() => handleTabClick(2)}
//                                 style={{ cursor: 'pointer' }}
//                             >
//                                 <a className="nav-link" data-toggle="tab" href="#biography" role="tab" aria-controls="biography">Memoirs</a>
//                             </li>
//                             <li
//                                 className={`nav-item ${activeTab === 3 ? 'active' : ''}`}
//                                 onClick={() => handleTabClick(3)}
//                                 style={{ cursor: 'pointer' }}
//                             >
//                                 <a className="nav-link" data-toggle="tab" href="#informative" role="tab" aria-controls="informative">Thriller</a>
//                             </li>
//                             <li
//                                 className={`nav-item ${activeTab === 4 ? 'active' : ''}`}
//                                 onClick={() => handleTabClick(4)}
//                                 style={{ cursor: 'pointer' }}
//                             >
//                                 <a className="nav-link last-child" data-toggle="tab" href="#autobiography" role="tab" aria-controls="autobiography">Biographies</a>
//                             </li>
//                         </ul>
//                     </div>
//                     <div className='col-md-9'>
//                         <div className="tab-content">
//                             <div className="tab-pane active" id="fiction" role="tabpanel" style={{ display: activeTab === 0 ? 'block' : 'none' }}>
//                                 <div className="work-portfolio">
//                                     <Slider {...settings}>
//                                         <div className="item">
//                                             <img src={fictionimg1} alt="" className='img-fluid' />
//                                         </div>
//                                         <div className="item">
//                                             <img src={fictionimg2} alt="" className='img-fluid' />
//                                         </div>
//                                         <div className="item">
//                                             <img src={fictionimg3} alt="" className='img-fluid' />
//                                         </div>
//                                         <div className="item">
//                                             <img src={fictionimg4} alt="" className='img-fluid' />
//                                         </div>
//                                         <div className="item">
//                                             <img src={fictionimg5} alt="" className='img-fluid' />
//                                         </div>
//                                         <div className="item">
//                                             <img src={fictionimg6} alt="" className='img-fluid' />
//                                         </div>
//                                     </Slider>
//                                 </div>
//                             </div>
//                             <div className="tab-pane" id="non-fiction" role="tabpanel" style={{ display: activeTab === 1 ? 'block' : 'none' }}>
//                                 <div className="work-portfolio">
//                                     <Slider {...settings}>
//                                         <div className="item">
//                                             <img src={nonfictionimg1} alt="" className='img-fluid' />
//                                         </div>
//                                         <div className="item">
//                                             <img src={nonfictionimg2} alt="" className='img-fluid' />
//                                         </div>
//                                         <div className="item">
//                                             <img src={nonfictionimg3} alt="" className='img-fluid' />
//                                         </div>
//                                         <div className="item">
//                                             <img src={nonfictionimg4} alt="" className='img-fluid' />
//                                         </div>
//                                         <div className="item">
//                                             <img src={nonfictionimg5} alt="" className='img-fluid' />
//                                         </div>
//                                         <div className="item">
//                                             <img src={nonfictionimg6} alt="" className='img-fluid' />
//                                         </div>
//                                     </Slider>
//                                 </div>
//                             </div>
//                             <div className="tab-pane" id="biography" role="tabpanel" style={{ display: activeTab === 2 ? 'block' : 'none' }}>
//                                 <div className="work-portfolio">
//                                     <Slider {...settings}>
//                                         <div className="item">
//                                             <img src={historyimg1} alt="" className='img-fluid' />
//                                         </div>
//                                         <div className="item">
//                                             <img src={historyimg2} alt="" className='img-fluid' />
//                                         </div>
//                                         <div className="item">
//                                             <img src={historyimg3} alt="" className='img-fluid' />
//                                         </div>
//                                         <div className="item">
//                                             <img src={historyimg4} alt="" className='img-fluid' />
//                                         </div>
//                                         <div className="item">
//                                             <img src={historyimg5} alt="" className='img-fluid' />
//                                         </div>
//                                         <div className="item">
//                                             <img src={historyimg6} alt="" className='img-fluid' />
//                                         </div>
//                                     </Slider>
//                                 </div>
//                             </div>
//                             <div className="tab-pane" id="informative" role="tabpanel" style={{ display: activeTab === 3 ? 'block' : 'none' }}>
//                                 <div className="work-portfolio">
//                                     <Slider {...settings}>
//                                         <div className="item">
//                                             <img src={romanceimg1} alt="" className='img-fluid' />
//                                         </div>
//                                         <div className="item">
//                                             <img src={romanceimg2} alt="" className='img-fluid' />
//                                         </div>
//                                         <div className="item">
//                                             <img src={romanceimg3} alt="" className='img-fluid' />
//                                         </div>
//                                         <div className="item">
//                                             <img src={romanceimg4} alt="" className='img-fluid' />
//                                         </div>
//                                         <div className="item">
//                                             <img src={romanceimg5} alt="" className='img-fluid' />
//                                         </div>
//                                         <div className="item">
//                                             <img src={romanceimg6} alt="" className='img-fluid' />
//                                         </div>
//                                     </Slider>
//                                 </div>
//                             </div>
//                             <div className="tab-pane" id="autobiography" role="tabpanel" style={{ display: activeTab === 4 ? 'block' : 'none' }}>
//                                 <div className="work-portfolio">
//                                     <Slider {...settings}>
//                                         <div className="item">
//                                             <img src={thrillerimg1} alt="" className='img-fluid' />
//                                         </div>
//                                         <div className="item">
//                                             <img src={thrillerimg2} alt="" className='img-fluid' />
//                                         </div>
//                                         <div className="item">
//                                             <img src={thrillerimg3} alt="" className='img-fluid' />
//                                         </div>
//                                         <div className="item">
//                                             <img src={thrillerimg4} alt="" className='img-fluid' />
//                                         </div>
//                                         <div className="item">
//                                             <img src={thrillerimg5} alt="" className='img-fluid' />
//                                         </div>
//                                         <div className="item">
//                                             <img src={thrillerimg6} alt="" className='img-fluid' />
//                                         </div>
//                                     </Slider>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     )
// }


import React, { useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { fictionimg1, fictionimg2, fictionimg3, fictionimg4, fictionimg5, fictionimg6, historyimg1, historyimg2, historyimg3, historyimg4, historyimg5, historyimg6, nonfictionimg1, nonfictionimg2, nonfictionimg3, nonfictionimg4, nonfictionimg5, nonfictionimg6, romanceimg1, romanceimg2, romanceimg3, romanceimg4, romanceimg5, romanceimg6, thrillerimg1, thrillerimg2, thrillerimg3, thrillerimg4, thrillerimg5, thrillerimg6 } from '../../assets/images';

export default function PortfolioTab() {
    const [activeTab, setActiveTab] = useState(0); // Control active tab

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    const tabImages = [
        [fictionimg1, fictionimg2, fictionimg3, fictionimg4, fictionimg5, fictionimg6], // Fiction
        [historyimg1, historyimg2, historyimg3, historyimg4, historyimg5, historyimg6], // History
        [nonfictionimg1, nonfictionimg2, nonfictionimg3, nonfictionimg4, nonfictionimg5, nonfictionimg6], // Memoirs
        [romanceimg1, romanceimg2, romanceimg3, romanceimg4, romanceimg5, romanceimg6], // Thriller
        [thrillerimg1, thrillerimg2, thrillerimg3, thrillerimg4, thrillerimg5, thrillerimg6], // Biographies
    ];

    return (
        <div className='portfolio-tab' data-aos="zoom-in" data-aos-duration="1500">
            <div className='row'>
                <div className='col-md-3'>
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        {['Fiction', 'History', 'Memoirs', 'Thriller', 'Biographies'].map((tab, index) => (
                            <li
                                key={index}
                                className={`nav-item ${activeTab === index ? 'active' : ''}`}
                                onClick={() => handleTabClick(index)}
                                style={{ cursor: 'pointer' }}
                            >
                                <a className="nav-link" data-toggle="tab" href={`#${tab.toLowerCase()}`} role="tab" aria-controls={tab.toLowerCase()}>{tab}</a>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className='col-md-9'>
                    <div className="tab-content">
                        <div className="work-portfolio">
                            <Slider {...settings}>
                                {tabImages[activeTab].map((imgSrc, i) => (
                                    <div key={i}>
                                        <img src={imgSrc} alt="" className='img-fluid' />
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

