import React, { useState,useEffect } from 'react';
import { ModalSection } from '../../components/modalSection'
// import './index.css'

export default function SecondCta() {

    useEffect(() => {
        // Function to open chat window
        const openChatWindow = () => {
            if (window.$zopim) {
                window.$zopim.livechat.window.show();
            }
        };

        // Function to toggle chat window
        const toggleChat = () => {
            if (window.$zopim) {
                window.$zopim.livechat.window.toggle();
            }
        };

        // Initialize chat and open by default
        const initializeChat = () => {
            const intervalId = setInterval(() => {
                if (window.$zopim && window.$zopim.livechat) {
                    clearInterval(intervalId);
                    openChatWindow();
                    listenForNewMessages();
                }
            }, 100); // Check every 100ms if chat widget is available
        };

        // Listen for new messages
        const listenForNewMessages = () => {
            if (window.$zopim && window.$zopim.livechat) {
                window.$zopim.livechat.setOnUnreadMsgs((numberOfUnreadMessages) => {
                    if (numberOfUnreadMessages > 0) {
                        openChatWindow();
                    }
                });
            }
        };

        initializeChat();
        const chatButtons = document.querySelectorAll('.chat'); // Select all buttons with the class 'chat'

        chatButtons.forEach(button => {
            button.addEventListener('click', toggleChat); // Add click event to each button
        });

        // Cleanup: Remove event listeners when the component unmounts
        return () => {
            chatButtons.forEach(button => {
                button.removeEventListener('click', toggleChat); // Remove click event from each button
            });
        };
    }, []);
    const [open, setOpen] = useState(false);

    const onOpenModal = () => {
        setOpen(true);
    };

    const onCloseModal = () => {
        setOpen(false);
    };
    return (
        <>
            <section className='cta-sec second-cta'>
                <div className='container'>
                    <div className='cta-text'>
                        <h1 data-aos="fade-down" data-aos-duration="1500">Start Marketing Your Book Today!</h1>
                        <h5 data-aos="fade-up" data-aos-duration="1500">Take the Next Step Toward Your Book's Success!</h5>
                        {/* <p data-aos="fade-down" data-aos-duration="1500">Be one step closer to becoming the <strong> BEST SELLING AUTHOR</strong></p> */}
                        <div className='cta-button' data-aos="fade-up" data-aos-duration="1500">
                            <a href='Javascript:;' className='cta-btn' onClick={onOpenModal}>Let's Write Your Book</a>
                            <a href='Javascript:;' className='cta-chat chat'>Let's Talk Right Away</a>
                        </div>
                    </div>
                </div>
            </section>
            <ModalSection open={open} onCloseModal={onCloseModal} />
        </>
    )
}