
import PortfolioTab from '../../components/PortfolioTab'
// import './index.css'

export default function Portfolio() {

    return (
        <>
            <section className="portfolio-sec text-center">
                <div className="container">
                    <p data-aos="fade-up" data-aos-duration="1500">Our Portfolio</p>
                    <h2 data-aos="fade-up" data-aos-duration="1500">Explore Our Collection of Masterpieces!</h2>
                    {/* <div className='portfolio-tab'>
                        <div className='row'>
                            <div className='col-lg-3'>
                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                    <li
                                        className={`nav-item ${activeTab === 0 ? 'active' : ''}`}
                                        onClick={() => handleTabClick(0)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <a class="nav-link active" data-toggle="tab" href="#fiction" role="tab" aria-controls="fiction">Fiction</a>
                                    </li>
                                    <li
                                        className={`nav-item ${activeTab === 1 ? 'active' : ''}`}
                                        onClick={() => handleTabClick(1)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <a class="nav-link" data-toggle="tab" href="#non-fiction" role="tab" aria-controls="non-fiction">History</a>
                                    </li>
                                    <li
                                        className={`nav-item ${activeTab === 2 ? 'active' : ''}`}
                                        onClick={() => handleTabClick(2)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <a class="nav-link" data-toggle="tab" href="#biography" role="tab" aria-controls="biography">Memoirs</a>
                                    </li>
                                    <li
                                        className={`nav-item ${activeTab === 3 ? 'active' : ''}`}
                                        onClick={() => handleTabClick(3)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <a class="nav-link" data-toggle="tab" href="#informative" role="tab" aria-controls="informative">Thriller</a>
                                    </li>
                                    <li
                                        className={`nav-item ${activeTab === 4 ? 'active' : ''}`}
                                        onClick={() => handleTabClick(4)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <a class="nav-link last-child" data-toggle="tab" href="#autobiography" role="tab" aria-controls="autobiography">Biographies</a>
                                    </li>
                                </ul>
                            </div>
                            <div className='col-lg-9'>
                                <div class="tab-content">
                                    <div class="tab-pane active" id="fiction" role="tabpanel" style={{ display: activeTab === 0 ? 'block' : 'none' }}>
                                        <div className="work-portfolio">
                                            <OwlCarousel className="owl-theme" {...options}>
                                                <div className="item">
                                                    <img src={fictionimg1} alt="" className='img-fluid' />
                                                </div>
                                                <div className="item">
                                                    <img src={fictionimg2} alt="" className='img-fluid' />
                                                </div>
                                                <div className="item">
                                                    <img src={fictionimg3} alt="" className='img-fluid' />
                                                </div>
                                                <div className="item">
                                                    <img src={fictionimg4} alt="" className='img-fluid' />
                                                </div>
                                                <div className="item">
                                                    <img src={fictionimg5} alt="" className='img-fluid' />
                                                </div>
                                                <div className="item">
                                                    <img src={fictionimg6} alt="" className='img-fluid' />
                                                </div>
                                            </OwlCarousel>
                                        </div>
                                    </div>
                                    <div class="tab-pane" id="non-fiction" role="tabpanel" style={{ display: activeTab === 1 ? 'block' : 'none' }}>
                                        <div class="work-portfolio">
                                            <OwlCarousel className="owl-theme" {...options}>
                                                <div class="item">
                                                    <img src={nonfictionimg1} alt="" className='img-fluid' />
                                                </div>
                                                <div class="item">
                                                    <img src={nonfictionimg2} alt="" className='img-fluid' />
                                                </div>
                                                <div class="item">
                                                    <img src={nonfictionimg3} alt="" className='img-fluid' />
                                                </div>
                                                <div class="item">
                                                    <img src={nonfictionimg4} alt="" className='img-fluid' />
                                                </div>
                                                <div class="item">
                                                    <img src={nonfictionimg5} alt="" className='img-fluid' />
                                                </div>
                                                <div class="item">
                                                    <img src={nonfictionimg6} alt="" className='img-fluid' />
                                                </div>
                                            </OwlCarousel>
                                        </div>
                                    </div>
                                    <div class="tab-pane" id="biography" role="tabpanel" style={{ display: activeTab === 2 ? 'block' : 'none' }}>
                                        <div class="work-portfolio">
                                            <OwlCarousel className="owl-theme" {...options}>
                                                <div class="item">
                                                    <img src={historyimg1} alt="" className='img-fluid' />
                                                </div>
                                                <div class="item">
                                                    <img src={historyimg2} alt="" className='img-fluid' />
                                                </div>
                                                <div class="item">
                                                    <img src={historyimg3} alt="" className='img-fluid' />
                                                </div>
                                                <div class="item">
                                                    <img src={historyimg4} alt="" className='img-fluid' />
                                                </div>
                                                <div class="item">
                                                    <img src={historyimg5} alt="" className='img-fluid' />
                                                </div>
                                                <div class="item">
                                                    <img src={historyimg6} alt="" className='img-fluid' />
                                                </div>
                                            </OwlCarousel>
                                        </div>
                                    </div>
                                    <div class="tab-pane" id="informative" role="tabpanel" style={{ display: activeTab === 3 ? 'block' : 'none' }}>
                                        <div class="work-portfolio">
                                            <OwlCarousel className="owl-theme" {...options}>
                                                <div class="item">
                                                    <img src={romanceimg1} alt="" className='img-fluid' />
                                                </div>
                                                <div class="item">
                                                    <img src={romanceimg2} alt="" className='img-fluid' />
                                                </div>
                                                <div class="item">
                                                    <img src={romanceimg3} alt="" className='img-fluid' />
                                                </div>
                                                <div class="item">
                                                    <img src={romanceimg4} alt="" className='img-fluid' />
                                                </div>
                                                <div class="item">
                                                    <img src={romanceimg5} alt="" className='img-fluid' />
                                                </div>
                                                <div class="item">
                                                    <img src={romanceimg6} alt="" className='img-fluid' />
                                                </div>
                                            </OwlCarousel>
                                        </div>
                                    </div>
                                    <div class="tab-pane" id="autobiography" role="tabpanel" style={{ display: activeTab === 4 ? 'block' : 'none' }}>
                                        <div class="work-portfolio">
                                            <OwlCarousel className="owl-theme" {...options}>
                                                <div class="item">
                                                    <img src={thrillerimg1} alt="" className='img-fluid' />
                                                </div>
                                                <div class="item">
                                                    <img src={thrillerimg2} alt="" className='img-fluid' />
                                                </div>
                                                <div class="item">
                                                    <img src={thrillerimg3} alt="" className='img-fluid' />
                                                </div>
                                                <div class="item">
                                                    <img src={thrillerimg4} alt="" className='img-fluid' />
                                                </div>
                                                <div class="item">
                                                    <img src={thrillerimg5} alt="" className='img-fluid' />
                                                </div>
                                                <div class="item">
                                                    <img src={thrillerimg6} alt="" className='img-fluid' />
                                                </div>
                                            </OwlCarousel>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <PortfolioTab/>
                </div>
            </section>
        </>
    )
}