  import React from 'react';
  import Slider from "react-slick";
  import "slick-carousel/slick/slick.css";
  import "slick-carousel/slick/slick-theme.css";

  import {
    annaGray,
  jaredGreen,
  heraldWright,
  jerryChristian,
  nelsonBale,
  kevinAdams,
  dustinStevens,
  adrianaBanks,
  gregoryDiehl,
  anaHuang,
  anaBlakely,
  joshuaHood,
  beswick,
  danJones,
  robinJones,
  karenCasey,
  juliaZheng,
  angieThomas,
  andrewMorton,
  heatherBurch,
  kellyHarms,
  roberPeecher,
  traciWooden,
  ryanKurr
} from '../../assets/images';

  export default function ReviewBannerSlider() {
    const settings = {
        infinite:true,
        slidesToShow:3,
        centerMode:true,
        arrows: false,
        speed:8000,
        dots: false,
        slideDown:true,
        autoplay: true,
        autoplaySpeed: 0,
        cssEase: 'linear',
        vertical: true,
        verticalSwiping: true,
    };
    return (
        <Slider {...settings}>
            <div>
                <div className='review_slider_item'>
                    <img src={annaGray} alt='Author'/>
                    <img src={jaredGreen} alt='Author'/>
                    <img src={heraldWright} alt='Author'/>
                </div>
            </div>
            <div>
                <div className='review_slider_item'>
                    <img src={jerryChristian} alt='Author'/>
                    <img src={nelsonBale} alt='Author'/>
                    <img src={kevinAdams} alt='Author'/>
                </div>
            </div>
            <div>
                <div className='review_slider_item'>
                    <img src={dustinStevens} alt='Author'/>
                    <img src={adrianaBanks} alt='Author'/>
                    <img src={gregoryDiehl} alt='Author'/>
                </div>
            </div>
            <div>
                <div className='review_slider_item'>
                    <img src={anaHuang} alt='Author'/>
                    <img src={anaBlakely} alt='Author'/>
                    <img src={joshuaHood} alt='Author'/>
                </div>
            </div>
            <div>
                <div className='review_slider_item'>
                    <img src={beswick} alt='Author'/>
                    <img src={danJones} alt='Author'/>
                    <img src={robinJones} alt='Author'/>
                </div>
            </div>
            <div>
                <div className='review_slider_item'>
                    <img src={karenCasey} alt='Author'/>
                    <img src={juliaZheng} alt='Author'/>
                    <img src={angieThomas} alt='Author'/>
                </div>
            </div>
            <div>
                <div className='review_slider_item'>
                    <img src={andrewMorton} alt='Author'/>
                    <img src={heatherBurch} alt='Author'/>
                    <img src={kellyHarms} alt='Author'/>
                </div>
            </div>
            <div>
                <div className='review_slider_item'>
                    <img src={roberPeecher} alt='Author'/>
                    <img src={traciWooden} alt='Author'/>
                    <img src={ryanKurr} alt='Author'/>
                </div>
            </div>
        </Slider>
    );
  }
