import React,{useState} from 'react';
// import './index.css'
import { logo } from "../../assets/images";
import { ModalSection } from '../../components/modalSection';

export default function Header() {
    const [open, setOpen] = useState(false);

    const onOpenModal = () => {
        setOpen(true);
    };

    const onCloseModal = () => {
        setOpen(false);
    };
    return (
        <>
            <header>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="header-img">
                                <a href="Javascript:;">
                                    <img src={logo} alt="logo"  />
                                </a>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="header-btn">
                                <a href="Javascript:;" onClick={onOpenModal}>Let's Get Started</a>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <ModalSection open={open} onCloseModal={onCloseModal} />
        </>
    )
}