import React, { useState } from 'react';
import axios from 'axios';
import { WEBSITE_NAME } from '../../config';
import { Loader } from '../../assets/images';
import homeData from '../../data/home.json';
import './index.css';

const HomeBnrForm = () => {
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const [formData, setFormData] = useState({
    service: '',
    name: '',
    phone: '',
    email: '',
    message: ''
  });

  const handleNext = () => {
    if (formData.service) {
      setStep(2);
    } else {
      alert('Please select a service');
    }
  };

  const handlePrevious = () => {
    setStep(1);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handlePrivacyCheck = () => {
    const privacyCheckbox = document.getElementById('checkPrivacy');
    return privacyCheckbox && privacyCheckbox.checked;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!handlePrivacyCheck()) {
      alert('Please agree to the privacy policy before submitting.');
      return;
    }

    if (formData.phone.length < 9) {
      setErrors(true);
    } else {
      const currentUrl = window.location.href;
      const protocol = window.location.protocol;
      const hostname = window.location.hostname;
      const baseUrl = `${protocol}//${hostname}`;
      const queryStringFormData = new URLSearchParams(formData).toString();

      let finalReq = {
        ...formData,
        source: `${currentUrl}${queryStringFormData}`,
        domain: baseUrl,
        lead_url: currentUrl,
        url: `${currentUrl}${queryStringFormData}`
      };

      try {
        setLoading(true);
        const apiUrl = 'https://tgcrm.net/api/form_submission?brand_key=148453';
        const config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        };

        const formDataString = new URLSearchParams(finalReq).toString();
        const response = await axios.post(apiUrl, formDataString, config);

        if (response.status === 200) {
          const queryString = new URLSearchParams(response.data.data).toString();
          setLoading(false);
          setErrors(false);
          setFormData({
            service: '',
            name: '',
            phone: '',
            email: '',
            message: ''
          });
          window.location.href = `https://www.amazonbookwriters.com/thankyou?${queryString}`;
        }
      } catch (error) {
        console.error('Error during form submission:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="banner-form">
      {step === 1 && (
        <div className="step-1 services_fields">
          <h2>{homeData.banner.formStep1title}</h2>
          <p>{homeData.banner.formStep1subtitle}</p>
          <label>
            Book Writing
            <input
              type="radio"
              name="service"
              value="Book Writing"
              checked={formData.service === 'Book Writing'}
              onChange={handleChange}
            />
          </label>
          <label>
            Book Editing
            <input
              type="radio"
              name="service"
              value="Book Editing"
              checked={formData.service === 'Book Editing'}
              onChange={handleChange}
            />
          </label>
          <label>
            Book Publishing
            <input
              type="radio"
              name="service"
              value="Book Publishing"
              checked={formData.service === 'Book Publishing'}
              onChange={handleChange}
            />
          </label>
          <label>
            Book Marketing
            <input
              type="radio"
              name="service"
              value="Book Marketing"
              checked={formData.service === 'Book Marketing'}
              onChange={handleChange}
            />
          </label>
          <label>
            Other
            <input
              type="radio"
              name="service"
              value="Other"
              checked={formData.service === 'Other'}
              onChange={handleChange}
            />
          </label>
          <button onClick={handleNext} className='default_btn red_btn'>Next</button>
        </div>
      )}

      {step === 2 && (
        <div className="step-2">
          <p>{homeData.banner.formStep2subtitle}</p>
          <form className="form_submission" method="POST" onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Name"
              className="form-control"
              id="name"
              name="name"
              value={formData.name}
              required
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            />

            <input
              type="email"
              placeholder="Email"
              className="form-control"
              id="email"
              name="email"
              value={formData.email}
              required
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            />

            <input
              type="number"
              id="phone"
              name="phone"
              placeholder="Number"
              className="form-control"
              minLength="10"
              maxLength="12"
              value={formData.phone}
              required
              onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
            />

            <textarea
              placeholder="Message"
              className="form-control"
              id="message"
              name="message"
              value={formData.message}
              required
              onChange={(e) => setFormData({ ...formData, message: e.target.value })}
            />

            <div className="form-check-privacy">
              <input className="form-checks" type="checkbox" value="" id="checkPrivacy" />
              <label className="form-checks" htmlFor="checkPrivacy">
                <p>By Submitting you agree to our <a href="/terms-and-conditions">Terms of Service</a> and <a href="/privacy-policy">Privacy Policy</a></p>
              </label>
            </div>

            {loading ? (
              <div className="loader">
                <img alt="loader" src={Loader} />
              </div>
            ) : (
              <>
              
                <button type="button" className='default_btn white_btn' onClick={handlePrevious}>Previous</button>
                <button type="submit" name="saves2" className="default_btn red_btn">
                  <span title="Submit Now">Submit Now</span>
                </button>
              </>
            )}
          </form>
        </div>
      )}
      {errors && <p className="error">Please provide a valid phone number.</p>}
    </div>
  );
};

export default HomeBnrForm;