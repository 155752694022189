import React, { createContext, useEffect, useContext } from 'react';

const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  // Function to open the Zendesk chat window
  const openChatWindow = () => {
    if (window.$zopim) {
      window.$zopim.livechat.window.show();
    }
  };

  // Function to toggle the Zendesk chat window
  const toggleChat = () => {
    if (window.$zopim) {
      window.$zopim.livechat.window.toggle();
    }
  };

  useEffect(() => {
    // Initialize chat and open by default
    const initializeChat = () => {
      const intervalId = setInterval(() => {
        if (window.$zopim && window.$zopim.livechat) {
          clearInterval(intervalId);
          openChatWindow();
          listenForNewMessages();
        }
      }, 100); // Check every 100ms if chat widget is available
    };

    // Listen for new messages
    const listenForNewMessages = () => {
      if (window.$zopim && window.$zopim.livechat) {
        window.$zopim.livechat.setOnUnreadMsgs((numberOfUnreadMessages) => {
          if (numberOfUnreadMessages > 0) {
            openChatWindow();
          }
        });
      }
    };

    initializeChat();

    // Attach event listeners to chat buttons
    const chatButtons = document.querySelectorAll('.chat');
    chatButtons.forEach((button) => {
      if (button) {
        button.addEventListener('click', toggleChat);
      }
    });

    // Cleanup: Remove event listeners when the component unmounts
    return () => {
      chatButtons.forEach((button) => {
        if (button) {
          button.removeEventListener('click', toggleChat);
        }
      });
    };
  }, []);

  return (
    <ChatContext.Provider value={{ toggleChat }}>
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => useContext(ChatContext);
