import { logo } from "../../assets/images";
// import './index.css'

export default function Footer() {
    return (
        <>
            <footer >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <a href="#" className="logo">
                                <img src={logo} alt="logo" />
                            </a>
                            <p>
                                At Amazon Book Writers we take our role as proponents of the written word very seriously. We not only offer professional ghostwriting services for those that haven’t the time to write their books, but we also provide expert editing and proofreading services alongside publication and marketing. Whether you’re just starting out as a writer or are already established, we can cater to you with our range of services.
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
            <section className="copy"> 
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <p>Copyright @ Amazon Book Writers All Rights Reserved 2024</p>
                        </div>
                        <div className="col-md-6">
                            <ul>
                                <li>
                                    <a href="" >Privacy Policy</a>
                                </li>
                                <li className="text-white">|</li>
                                <li>
                                    <a href="" >Terms & Condition</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}