import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { m1, star, w1 } from '../../assets/images'
import { WEBSITE_NAME } from '../../config'

export default function TestimonialSlider() {
    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        //   autoplay: true,
        autoplaySpeed: 2000,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [{
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                arrows: false,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                arrows: false,
            }
        }
        ]
    }
    return (
        <>
            <Slider class="reviews"{...settings}>
                <div class="item">
                    <img src={w1} class="img-fluid lazyload" alt="" />
                    <div>
                        <img src={star} class="img-fluid lazyload w-25" alt="" />
                    </div>
                    <p class="testi-text">
                        I couldn't be happier with my experience at Amazon Book Writers! They helped me polish my manuscript and navigate the publishing process with ease. Their team is professional, attentive, and truly cares about your success. My book is now out in the world, and I owe it all to them!
                    </p>
                    <h6>Cornon sandy</h6>
                </div>
                <div class="item">
                    <img src={m1} class="img-fluid lazyload" alt="" />
                    <div>
                        <img src={star} class="img-fluid lazyload w-25" alt="" />
                    </div>
                    <p class="testi-text">
                        Working with Amazon Book Writers was a game-changer for me. The editing services transformed my draft into a compelling read. They took the time to understand my vision and provided valuable insights that improved my storytelling. I highly recommend them to any writer looking to publish!
                    </p>
                    <h6>Orlando Frank</h6>
                </div>
                <div class="item">
                    <img src={m1} class="img-fluid lazyload" alt="" />
                    <div>
                        <img src={star} class="img-fluid lazyload w-25" alt="" />
                    </div>
                    <p class="testi-text">
                        I had a fantastic experience with Amazon Book Writers! Their team made the daunting process of publishing feel manageable. The cover design and formatting were top-notch, and the audiobook version they created has received great feedback. I can't recommend them enough!
                    </p>
                    <h6>Mark Stews</h6>
                </div>
            </Slider>
        </>
    )
}