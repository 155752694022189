  import React, { useEffect, useState } from 'react';
  import {WEBSITE_NAME, PHONE, PHONE_HREF} from '../../config';
  import Slider from "react-slick";
  import "slick-carousel/slick/slick.css";
  import "slick-carousel/slick/slick-theme.css";
import { Carola, CharlesMartin, kellyHarms, Sherry } from '../../assets/images';


  export default function SmmReviewSlider() {
      const settings = {
          infinite: false,
          autoplay: false,
          arrows: false,
          dots: false,
          autoplaySpeed: 4000,
          speed: 1000,
          slidesToShow: 4,
          slidesToScroll: 1,
          responsive: [
              {
                  breakpoint: 1400,
                  settings: {
                      slidesToShow: 3,
                  }
              }, {
                  breakpoint: 991,
                  settings: {
                      slidesToShow: 2,
                  }
              }, {
                  breakpoint: 576,
                  settings: {
                      slidesToShow: 1,
                      dots: true,
                  }
              },
          ]
      };

      return (
        <Slider className="testi-sec-slider" {...settings}>
            <div class="test-sec-box">
                <div class="testi-box-image">
                    <img src={Carola} alt="img" class="img-fluid"/>
                </div>
                <p>{WEBSITE_NAME} has never let me down. They improved my draft and polished my
                    ideas to
                    make my book worthy of publishing.</p>
                <h3>Carola Lovering</h3>
                <div class="testi-box-social">
                    <i class="fa-brands fa-facebook"></i>
                </div>
            </div>
            <div class="test-sec-box">
                <div class="testi-box-image">
                    <img src={kellyHarms} alt="img" class="img-fluid"/>
                </div>
                <p>I have never been more satisfied with an online service company. Highly appreciate their
                    book
                    writing department.</p>
                <h3>Elly Griffiths</h3>
                <div class="testi-box-social">
                    <i class="fa-brands fa-twitter"></i>
                </div>
            </div>
            <div class="test-sec-box">
                <div class="testi-box-image">
                    <img src={Sherry} alt="img" class="img-fluid"/>
                </div>
                <p>I am pleased to announce that I have successfully published my own book, thanks to
                    Penguin
                    Book Writers.</p>
                <h3>Sherry Denboer</h3>
                <div class="testi-box-social">
                    <i class="fa-brands fa-facebook"></i>
                </div>
            </div>
            <div class="test-sec-box">
                <div class="testi-box-image">
                    <img src={CharlesMartin} alt="img" class="img-fluid"/>
                </div>
                <p>The affordability, uniqueness, accuracy, and timely deliverance are what make Penguin
                    Book Writers great.</p>
                <h3>Charles Martin</h3>
                <div class="testi-box-social">
                    <i class="fa-brands fa-twitter"></i>
                </div>
            </div>
        </Slider>
      );
  }
