import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { spon1, spon2, spon3, spon4 } from "../../assets/images";

export default function SponserSlider() {
    const settings = {
        loop: true,
        nav: false,
        dots: false,
        // margin: 80,
        responsiveClass: true,
        autoplay: true,
        autoplayHoverPause: true,
        autoplayTimeout: 8000,
        autoplaySpeed: 800,
        responsive: {
            0: {
                items: 2,
            },
            600: {
                items: 4,
            },
            1000: {
                items: 4,
            },
            360: {
                items: 2,
            }
        }
    }
    return (
        <>
            <OwlCarousel className="sponser-slider" {...settings}>
                <div className="item">
                    <img src={spon1} alt="sponser-img" />
                </div>
                <div className="item">
                    <img src={spon2} alt="sponser-img" className='img-fluid'/>
                </div>
                <div className="item">
                    <img src={spon3} alt="sponser-img" className='img-fluid'/>
                </div>
                <div className="item">
                    <img src={spon4} alt="sponser-img" className='img-fluid'/>
                </div>
            </OwlCarousel>
        </>
    )
}