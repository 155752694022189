import { ModalSection } from '../../components/modalSection';
// import './index.css'
import React,{useState} from 'react';

export default function AboutUs() {
    const [open, setOpen] = useState(false);

    const onOpenModal = () => {
        setOpen(true);
    };

    const onCloseModal = () => {
        setOpen(false);
    };
    return (
        <>
            <section className="about-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="about-content">
                                <h2>Your Book Deserves to Be Written!</h2>
                                <p>Do you have a fantastic idea for a book but find it hard to get started? You’re not alone. Many aspiring authors feel stuck or overwhelmed when it comes to turning their thoughts into words. At Amazon Book Writers, we understand these challenges and are here to help. Our team of skilled writers is ready to partner with you, guiding you through the writing process to bring your story to life.<br /><br />
                                We focus on capturing your unique voice and vision. Whether you're working on fiction or nonfiction, we’ll help organize your ideas and create a polished manuscript. Don’t let doubts hold you back any longer, let’s turn your ideas into a book that you can be proud of.
                                </p>
                                <div className='btn about-btn'>
                                    <a href='Javascript:;'onClick={onOpenModal}>let's Get Started</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <ul>
                                <li>Fiction</li>
                                <li>Non-Fiction</li>
                                <li>Biography</li>
                                <li>Informative</li>
                                <li>Autobiography</li>
                                <li>Memoirs</li>
                                <li>Action & Adventure</li>
                                <li>Western</li>
                                <li>Anthology</li>
                                <li>Romance</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <ModalSection open={open} onCloseModal={onCloseModal} />
        </>
    )
}