import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
// import './index.css'
import { loader, popupimg, popupsideimg } from '../../assets/images';
import { ModalBody } from 'react-bootstrap';


export const ModalSection = ({ open, onCloseModal }) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    phone: '',
  });

  const onSubmitFunc = async (e) => {
    e.preventDefault();

    console.log('formData', formData);
    if (formData.phone.length < 9) {
      setErrors(true);
    } else {
      const currentUrl = window.location.href;
      const protocol = window.location.protocol; // "https:"
      const hostname = window.location.hostname; // "creativeghostwriters.org"

      // Construct the base URL
      const baseUrl = `${protocol}//${hostname}`;
      const queryStringFormData = new URLSearchParams(formData).toString();

      let finalReq = {
        ...formData,
        source: `${currentUrl}${queryStringFormData}`,
        domain: baseUrl,
        lead_url: currentUrl,
        url: `${currentUrl}${queryStringFormData}`,
      };
      try {
        setLoading(true);
        const apiUrl = 'https://tgcrm.net/api/form_submission?brand_key=148453';
        const config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };

        const formDataString = new URLSearchParams(finalReq).toString();

        const response = await axios.post(apiUrl, formDataString, config);
        if (response.status == 200) {
          // toast.success("Thank you for filling out the information");
          // console.log(response.data.data.id);
          const queryString = new URLSearchParams(
            response.data.data
          ).toString();
          console.log('queryString', queryString);
          setLoading(false);
          setErrors(false);
          setFormData({
            name: '',
            email: '',
            message: '',
            phone: '',
          });
          // naviagte(`/thank-you?${queryString}`)
          window.location.href = `https://www.amazonbookwriters.com/thankyou?${queryString}`;
        }
        console.log('responseresponseresponse', response);
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  return (
    <Modal className='popup-wrapper'
      show={open} onHide={onCloseModal}
      size='lg'
      aria-labelledby="contained-modal-title-vcenter"
      centered>

      <Modal.Body>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={onCloseModal}><span aria-hidden="true">&times;</span></button>
        <div className='modal-sec'>
          <div className='popup'>
            <div className='row align-items-center'>
              <div className='col-lg-5'>
                <div className='popup-img'>
                  <img src={popupimg} alt='popup-img' />
                </div>
              </div>
              <div class="col-lg-7 pop-txt">
                <div class="popup-content">
                  <h2>Activate Your Coupon</h2>
                  <p>
                    You are just one step away from becoming a great author. Share your idea with us today!
                  </p>
                  <form method="POST" class="form_submission" onSubmit={onSubmitFunc}>
                    <div class="popup-bdy">
                      <div class="fields">
                        <input type="text" placeholder="Name" name="name" id="name" required="" onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
                      </div>
                      <div class="fields">
                        <input type="email" placeholder="Email" name="email" id="email" required="" onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
                      </div>
                      <div class="fields">
                        <input type="number" name="phone" minlength="10" maxlength="12" placeholder="Phone Number" onChange={(e) => setFormData({ ...formData, phone: e.target.value })} />
                      </div>
                      <div class="fields">
                        <textarea name="message" cols="30" rows="5" placeholder="Message" onChange={(e) => setFormData({ ...formData, message: e.target.value })}></textarea>
                      </div>
                      <div class="fields">
                        {loading == true ? (
                          <div class="loader">
                            <img alt="loader" src={loader} />
                          </div>
                        ) : (
                          <button type="submit" href="javascript:;" class="popup-btn" id="">
                            Get Started
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal >
  );
};
