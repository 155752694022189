import {WEBSITE_NAME, PHONE, PHONE_HREF, EMAIL, EMAIL_HREF, ADDRESS } from '../../config';
import axios from 'axios';
import {
    GiveStoryImg,
    WhyChooseBackImg
} from '../../assets/images';
import { useChat } from '../../ChatContext';
import { useModal } from '../../ModalContext';
import Breadcrumb from '../../components/Breadcrumb';
import CtaSection from '../../GlobalSections/Cta';
import FooterFormSec from '../../GlobalSections/FooterFormSec';
import contactData from '../../data/contact.json';
import InnerBanner from '../../GlobalSections/InnerBanner';

export default function ContactPage() {
    const { toggleChat } = useChat();
    const { openModal } = useModal();

    return (
        <>  
            <InnerBanner data={contactData}/>
            <Breadcrumb/>

            <FooterFormSec data={contactData}/>
        </>
    );
}