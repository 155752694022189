import Logo from './logo.webp';
import WhiteLogo from './white-logo.png';
import TrustPilotImg from './trust-pilot.webp';
import ClutchImg from './clutch.webp';
import GoodfirmImg from './good-firms.webp';
import Loader from './loader.gif';
import PartnerLogo01 from './company-brands/brand-1.webp';
import PartnerLogo02 from './company-brands/brand-2.webp';
import PartnerLogo03 from './company-brands/brand-3.webp';
import PartnerLogo04 from './company-brands/brand-4.webp';
import PartnerLogo05 from './company-brands/brand-5.webp';
import PartnerLogo06 from './company-brands/brand-6.webp';
import PartnerLogo07 from './company-brands/brand-7.webp';
import PartnerLogo08 from './company-brands/brand-8.webp';
import PartnerLogo09 from './company-brands/brand-9.webp';
import PartnerLogo10 from './company-brands/brand-10.webp';
import PartnerLogo11 from './company-brands/brand-11.webp';
import PartnerLogo12 from './company-brands/brand-12.webp';
import PartnerLogo13 from './company-brands/brand-13.webp';
import PartnerLogo14 from './company-brands/brand-14.webp';
import PartnerLogo15 from './company-brands/brand-15.webp';
import PartnerLogo16 from './company-brands/brand-16.webp';
import PartnerLogo17 from './company-brands/brand-17.webp';
import PartnerLogo18 from './company-brands/brand-18.webp';
import WhyChooseBackImg from './about-will.webp';
import WhyChooseBookImg from './will-book.webp';
import FeaturedBrandLogo01 from './featured-brands/features-brand-001.webp';
import FeaturedBrandLogo02 from './featured-brands/features-brand-010.webp';
import FeaturedBrandLogo03 from './featured-brands/features-brand-002.webp';
import FeaturedBrandLogo04 from './featured-brands/features-brand-003.webp';
import FeaturedBrandLogo05 from './featured-brands/features-brand-004.webp';
import FeaturedBrandLogo06 from './featured-brands/features-brand-005.webp';
import FeaturedBrandLogo07 from './featured-brands/features-brand-006.webp';
import FeaturedBrandLogo08 from './featured-brands/features-brand-007.webp';
import FeaturedBrandLogo09 from './featured-brands/features-brand-008.webp';
import FeaturedBrandLogo10 from './featured-brands/features-brand-009.webp';
import ServiceIcon01 from './s-1.webp';
import ServiceIcon02 from './s-7.webp';
import ServiceIcon03 from './s-3.webp';
import ServiceIcon04 from './s-6.webp';
import ServiceIcon05 from './s-10.webp';
import ProcessIcon01 from './research.webp';
import ProcessIcon02 from './concept.webp';
import ProcessIcon03 from './landing-page.webp';
import ProcessIcon04 from './browser.webp';
import AwardBadge01 from './award1.webp';
import AwardBadge02 from './award2.webp';
import AwardBadge03 from './award3.webp';
import AwardBadge04 from './award4.webp';
import AwardBadge05 from './award5.webp';
import AwardBadge06 from './award6.webp';
import PortfolioTop from './portfolio-top.webp';
import BookPortfolio01 from './portfolio-brand/book1.webp';
import BookPortfolio02 from './portfolio-brand/book2.webp';
import BookPortfolio03 from './portfolio-brand/book3.webp';
import BookPortfolio04 from './portfolio-brand/book4.webp';
import BookPortfolio05 from './portfolio-brand/book5.webp';
import BookPortfolio06 from './portfolio-brand/book6.webp';
import BookPortfolio07 from './portfolio-brand/book7.webp';
import BookPortfolio08 from './portfolio-brand/book8.webp';
import BookPortfolio09 from './portfolio-brand/book9.webp';
import BookPortfolio10 from './portfolio-brand/book10.webp';
import BookPortfolio11 from './portfolio-brand/book11.webp';
import BookPortfolio12 from './portfolio-brand/book12.webp';
import BookPortfolio13 from './portfolio-brand/book13.webp';
import BookPortfolio14 from './portfolio-brand/book14.webp';
import BookPortfolio15 from './portfolio-brand/book15.webp';
import BookPortfolio16 from './portfolio-brand/book16.webp';
import BookPortfolio17 from './portfolio-brand/book17.webp';
import BookPortfolio18 from './portfolio-brand/book18.webp';
import BookPortfolio19 from './portfolio-brand/book19.webp';
import BookPortfolio20 from './portfolio-brand/book20.webp';
import StIcon01 from './st-icons/st-icon-01.webp';
import StIcon02 from './st-icons/st-icon-02.webp';
import StIcon03 from './st-icons/st-icon-03.webp';
import StIcon04 from './st-icons/st-icon-04.webp';
import StIcon05 from './st-icons/st-icon-05.webp';
import StIcon06 from './st-icons/st-icon-06.webp';
import StIcon07 from './st-icons/st-icon-07.webp';
import StIcon08 from './st-icons/st-icon-08.webp';
import StIcon09 from './st-icons/st-icon-09.webp';
import CtaSide1 from './cta-side1.webp';
import googlereviews from './google-reviews.webp';
import trustpilot from './trust-pilot.webp';
import amazonkindle from './amazon-kindle.webp';
import dmc2 from './dmc-2.webp';
import dmc1 from './dmc-1.webp';
import ModalImg from './modal-img.webp';
import GiveStoryImg from './give-story-imgh.webp';
import DiscountCtaImg from './discount.webp';
import GhostwritingWillImg from './ghostwriting-will.webp';
import BrandTabImg from './brand-tab-img.webp';
import WikiCreationImg from './wiki-creation-img.webp';


import img1 from './portfolio-brand/biography/2.webp';
import img2 from './portfolio-brand/biography/3.webp';
import img3 from './portfolio-brand/biography/4.webp';
import img4 from './portfolio-brand/biography/5.webp';
import img5 from './portfolio-brand/biography/6.webp';
import img6 from './portfolio-brand/biography/7.webp';
import img7 from './portfolio-brand/biography/9.webp';
import img8 from './portfolio-brand/fiction/9.webp';
import img9 from './portfolio-brand/informative/1.webp';
import img10 from './portfolio-brand/informative/2.webp';
import img11 from './portfolio-brand/informative/3.webp';
import img12 from './portfolio-brand/informative/4.webp';
import img13 from './portfolio-brand/informative/5.webp';
import img14 from './portfolio-brand/informative/6.webp';
import img15 from './portfolio-brand/informative/7.webp';
import img16 from './portfolio-brand/informative/8.webp';
import img17 from './portfolio-brand/informative/9.webp';
import img18 from './portfolio-brand/informative/10.webp';
import img19 from './portfolio-brand/fiction/1.webp';
import img20 from './portfolio-brand/fiction/2.webp';
import img21 from './portfolio-brand/fiction/3.webp';
import img22 from './portfolio-brand/fiction/4.webp';
import img23 from './portfolio-brand/fiction/5.webp';
import img24 from './portfolio-brand/fiction/6.webp';
import img25 from './portfolio-brand/fiction/7.webp';
import img26 from './portfolio-brand/fiction/8.webp';
import img27 from './portfolio-brand/fiction/9.webp';
import img28 from './portfolio-brand/fiction/10.webp';
import img29 from './portfolio-brand/non-fiction/1.webp';
import img30 from './portfolio-brand/non-fiction/2.webp';
import img31 from './portfolio-brand/non-fiction/3.webp';
import img32 from './portfolio-brand/non-fiction/4.webp';
import img33 from './portfolio-brand/non-fiction/5.webp';
import img34 from './portfolio-brand/non-fiction/6.webp';
import img35 from './portfolio-brand/non-fiction/7.webp';
import img36 from './portfolio-brand/non-fiction/8.webp';
import img37 from './portfolio-brand/non-fiction/9.webp';
import img38 from './portfolio-brand/non-fiction/10.webp';
import img39 from './portfolio-brand/memoir/1.webp';
import img40 from './portfolio-brand/memoir/2.webp';
import img41 from './portfolio-brand/memoir/3.webp';
import img42 from './portfolio-brand/memoir/4.webp';
import img43 from './portfolio-brand/memoir/5.webp';
import img44 from './portfolio-brand/memoir/6.webp';
import img45 from './portfolio-brand/memoir/7.webp';
import img46 from './portfolio-brand/memoir/8.webp';
import img47 from './portfolio-brand/memoir/9.webp';
import img48 from './portfolio-brand/memoir/10.webp';
import annaGray from './avator-anna-gray.webp';
import jaredGreen from './avator-jared-green.webp';
import heraldWright from './avator-herald-wright.webp';
import jerryChristian from './avator-jerry-christian.webp';
import nelsonBale from './avator-nelson-bale.webp';
import kevinAdams from './avator-kevin-adams.webp';
import dustinStevens from './avator-dustin-stevens.webp';
import adrianaBanks from './avator-adriana-banks.webp';
import gregoryDiehl from './avator-gregory-diehl.webp';
import anaHuang from './avator-ana-huang.webp';
import anaBlakely from './avator-ana-blakely.webp';
import joshuaHood from './avator-joshua-hood.webp';
import beswick from './avator-beswick.webp';
import danJones from './avator-dan-jones.webp';
import robinJones from './avator-robin-jones.webp';
import karenCasey from './avator-karen-casey.webp';
import juliaZheng from './avator-julia-zheng.webp';
import angieThomas from './avator-angie-thomas.webp';
import andrewMorton from './avator-andrew-morton.webp';
import heatherBurch from './avator-heather-burch.webp';
import kellyHarms from './avator-kelly-harms.webp';
import roberPeecher from './avator-rober-peecher.webp';
import traciWooden from './avator-traci-wooden.webp';
import ryanKurr from './avator-ryan-kurr.webp';
import Carola from './avator-carola.webp';
import KellyHarms from './avator-kelly-harms.webp';
import Sherry from './avator-sherry.webp';
import CharlesMartin from './avator-charles-martin.webp';

export {
    Loader,
    Logo,
    WhiteLogo,
    TrustPilotImg,
    ClutchImg,
    GoodfirmImg,
    PartnerLogo01,
    PartnerLogo02,
    PartnerLogo03,
    PartnerLogo04,
    PartnerLogo05,
    PartnerLogo06,
    PartnerLogo07,
    PartnerLogo08,
    PartnerLogo09,
    PartnerLogo10,
    PartnerLogo11,
    PartnerLogo12,
    PartnerLogo13,
    PartnerLogo14,
    PartnerLogo15,
    PartnerLogo16,
    PartnerLogo17,
    PartnerLogo18,
    WhyChooseBackImg,
    WhyChooseBookImg,
    FeaturedBrandLogo01,
    FeaturedBrandLogo02,
    FeaturedBrandLogo03,
    FeaturedBrandLogo04,
    FeaturedBrandLogo05,
    FeaturedBrandLogo06,
    FeaturedBrandLogo07,
    FeaturedBrandLogo08,
    FeaturedBrandLogo09,
    FeaturedBrandLogo10,
    ServiceIcon01,
    ServiceIcon02,
    ServiceIcon03,
    ServiceIcon04,
    ServiceIcon05,
    ProcessIcon01,
    ProcessIcon02,
    ProcessIcon03,
    ProcessIcon04,
    AwardBadge01,
    AwardBadge02,
    AwardBadge03,
    AwardBadge04,
    AwardBadge05,
    AwardBadge06,
    PortfolioTop,
    BookPortfolio01,
    BookPortfolio02,
    BookPortfolio03,
    BookPortfolio04,
    BookPortfolio05,
    BookPortfolio06,
    BookPortfolio07,
    BookPortfolio08,
    BookPortfolio09,
    BookPortfolio10,
    BookPortfolio11,
    BookPortfolio12,
    BookPortfolio13,
    BookPortfolio14,
    BookPortfolio15,
    BookPortfolio16,
    BookPortfolio17,
    BookPortfolio18,
    BookPortfolio19,
    BookPortfolio20,
    CtaSide1,
    googlereviews,
    trustpilot,
    amazonkindle,
    dmc2,
    dmc1,
    ModalImg,
    GiveStoryImg,
    DiscountCtaImg,
    GhostwritingWillImg,
    StIcon01,
    StIcon02,
    StIcon03,
    StIcon04,
    StIcon05,
    StIcon06,
    StIcon07,
    StIcon08,
    StIcon09,
    BrandTabImg,
    WikiCreationImg,
    annaGray,
    jaredGreen,
    heraldWright,
    jerryChristian,
    nelsonBale,
    kevinAdams,
    dustinStevens,
    adrianaBanks,
    gregoryDiehl,
    anaHuang,
    anaBlakely,
    joshuaHood,
    beswick,
    danJones,
    robinJones,
    karenCasey,
    juliaZheng,
    angieThomas,
    andrewMorton,
    heatherBurch,
    kellyHarms,
    roberPeecher,
    traciWooden,
    ryanKurr,
    Carola,
KellyHarms,
Sherry,
CharlesMartin,
};


export const biographyImages = [
    img1, img2, img3, img4, img5, img6, img7, img8
  ];
  
export const informativeImages = [
    img9, img10, img11, img12, img13, img14, img15, img16, img17, img18
];
export const fictionImages = [
    img19, img20, img21, img22, img23, img24, img25, img26, img27, img28
];
export const nonFictionImages = [
    img29, img30, img31, img32, img33, img34, img35, img36, img37, img38
];
export const memoirImages = [
    img39, img40, img41, img42, img43, img44, img45, img46, img47, img48
];