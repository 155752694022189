  import React from 'react';
  import { WEBSITE_NAME } from '../../config';


  export default function ContactFlow({data}) {

    return (
        data?.contactSection?.steps ? (
            <div className="contact-flow">
              <h4 className="contact-flow__title">
              What You Can Expect from
                <span className="text_red_clr font-700"> {WEBSITE_NAME}:</span>
              </h4>
              <ol className="ordered-list pt-0">
                {data.contactSection.steps.map((step, index) => (
                  <li key={index} className="contact-flow__list-item">
                    {step}
                  </li>
                ))}
              </ol>
            </div>
          ) : null
    );
  }
