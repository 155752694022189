import { WEBSITE_NAME } from '../../config';
import { 
    TrustPilotImg,
    ClutchImg,
    GoodfirmImg,
 } from './../../assets/images';
import { useChat } from '../../ChatContext';
import { useModal } from '../../ModalContext';
import './index.css';
import InnerBnrForm from '../../components/InnerBnrForm';
import HomeBnrForm from '../../components/HomeBnrForm';

export default function InnerBanner({data}) {
    const { toggleChat } = useChat();
    const { openModal } = useModal();
    const replacePlaceholders = (text) => {
        if (!text || !text.includes('{{WEBSITE_NAME}}')) {
            // If text is undefined/null or does not contain the placeholder, return it as is
            return text;
        }
        // Replace {{WEBSITE_NAME}} if found in text
        return text.replace(/{{WEBSITE_NAME}}/g, WEBSITE_NAME);
    };
    return (
        <section className='inner_banner'>
            <div className='container'>
                <h1>{replacePlaceholders(data.Banner.title)}</h1>
            </div>
        </section>
    );
}
