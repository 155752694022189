import React, { useState } from 'react';
import axios from 'axios';
import {
    WEBSITE_NAME
} from '../../config/index'
import { loader } from '../../assets/images';

const BannerForm = () => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        phone: '',
    });

    const onSubmitFunc = async (e) => {
        e.preventDefault();

        console.log('formData', formData);
        if (formData.phone.length < 9) {
            setErrors(true);
        } else {
            const currentUrl = window.location.href;
            const protocol = window.location.protocol; // "https:"
            const hostname = window.location.hostname; // "creativeghostwriters.org"

            // Construct the base URL
            const baseUrl = `${protocol}//${hostname}`;
            const queryStringFormData = new URLSearchParams(formData).toString();

            let finalReq = {
                ...formData,
                source: `${currentUrl}${queryStringFormData}`,
                domain: baseUrl,
                lead_url: currentUrl,
                url: `${currentUrl}${queryStringFormData}`,
            };
            try {
                setLoading(true);
                const apiUrl = 'https://tgcrm.net/api/form_submission?brand_key=148453';
                const config = {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                };

                const formDataString = new URLSearchParams(finalReq).toString();

                const response = await axios.post(apiUrl, formDataString, config);
                if (response.status == 200) {
                    // toast.success("Thank you for filling out the information");
                    // console.log(response.data.data.id);
                    const queryString = new URLSearchParams(
                        response.data.data
                    ).toString();
                    console.log('queryString', queryString);
                    setLoading(false);
                    setErrors(false);
                    setFormData({
                        name: '',
                        email: '',
                        message: '',
                        phone: '',
                    });
                    // naviagte(`/thank-you?${queryString}`)
                    window.location.href = `https://www.amazonbookwriters.com/thankyou?${queryString}`;
                }
                console.log('responseresponseresponse', response);
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };


    return (
        <form className='form_submission' method='post' onSubmit={onSubmitFunc}>
            <h2>UP TO 30% OFF ON</h2>
            <p className='font-weight-bold'>BOOK EDITING PACKAGES</p>
            <div className='form-group mb-3'>
                <input type='name' placeholder='Enter Your Name *' className='form-control' required="" onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
            </div>
            <div className='form-group mb-3'>
                <input type='email' placeholder='Enter email here *' className='form-control' required="" onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
            </div>
            <div className='form-group mb-3'>
                <input type='number' placeholder='Enter phone Number *' minLength="10" maxLength="12" className='form-control' required="" onChange={(e) => setFormData({ ...formData, phone: e.target.value })} />
            </div>
            <div className='form-group mb-3'>
                <textarea type='name' placeholder='Enter brief description about your book' className='form-control' cols="20" rows="3" onChange={(e) => setFormData({ ...formData, message: e.target.value })} ></textarea>
            </div>
            <div>
                {loading == true ? (
                    <div class="loader">
                        <img alt="loader" src={loader} />
                    </div>
                ) : (
                    <button type='submit' value="sunmit Now">
                        SUBMIT NOW
                        <i class="fas fa-arrow-right" aria-hidden="true"></i>
                    </button>
                )}

            </div>
        </form>
    );
}

export default BannerForm;