// services.js
export const services = {
  ghostwriting: {
    name: "ghostwriting-services",
    path: "/ghostwriting-services",
    subServices: [
      {
        name: "non-fiction-writing",
        path: "/non-fiction-writing",
      },
      {
        name: "memoir-writing-services",
        path: "/memoir-writing-services",
      },
      {
        name: "manuscript-writing-service",
        path: "/manuscript-writing-service",
      },
      {
        name: "comic-book-writing-services",
        path: "/comic-book-writing-services",
      },
      {
        name: "action-adventure-writing",
        path: "/action-adventure-writing",
      },
      {
        name: "biography-writing",
        path: "/biography-writing",
      },
      {
        name: "military-fiction-writing",
        path: "/military-fiction-writing",
      },
      {
        name: "fantasy-writing",
        path: "/fantasy-writing",
      },
      {
        name: "horror-writing",
        path: "/horror-writing",
      },
      {
        name: "rhyme-writing",
        path: "/rhyme-writing",
      },
      {
        name: "screenplay-writing",
        path: "/screenplay-writing",
      },
      {
        name: "story-writing",
        path: "/story-writing",
      },
      {
        name: "autobiography-service",
        path: "/autobiography-service",
      },
      {
        name: "science-fiction-writing",
        path: "/science-fiction-writing",
      },
      {
        name: "childrens-book-writing",
        path: "/childrens-book-writing",
      },
    ],
  },
  bookediting: {
    name: "book-editing-services",
    path: "/book-editing-services",
  },
  proofreading: {
    name: "book-proofreading-services",
    path: "/book-proofreading-services",
  },
  bookcover: {
    name: "book-cover-design-services",
    path: "/book-cover-design-services",
  },
  authorwebsite: {
    name: "author-website-design",
    path: "/author-website-design",
  },
  bookillustration: {
    name: "book-illustration-design",
    path: "/book-illustration-design",
  },
  bookmarketing: {
    name: "book-marketing-services",
    path: "/book-marketing-services",
  },
  videotrailer: {
    name: "book-video-trailer",
    path: "/book-video-trailer",
  },
  bookpublishing: {
    name: "book-publishing",
    path: "/book-publishing",
  },
  bookprinting: {
    name: "book-printing-services",
    path: "/book-printing-services",
  },
  aboutus: {
    name: "about-us",
    path: "/about-us",
  },
  portfolio: {
    name: "portfolio",
    path: "/portfolio",
  },
  contact: {
    name: "contact-us",
    path: "/contact-us",
  }
};
