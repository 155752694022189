import { chooseicon } from '../../assets/images'
import WhyChooseUsSlider from '../../components/WhyChooseUsSlider'
// import './index.css'


export default function WhyChooseUs() {
    return (
        <>
            <section className="why-choose-us">
                <div className="container-fluid">
                    <div className="chooseus-text text-center" data-aos="fade-down" data-aos-duration="1500">
                        {/* <h2>Why Choose</h2> */}
                        <h1>Why Choose Amazon Book Writers</h1>
                        <p>Our comprehensive range of services is designed to help you achieve success quickly. With our expertise, you’ll gain visibility and establish a strong presence among your target audience. </p>
                    </div>
                    <WhyChooseUsSlider />
                </div>
            </section>
        </>
    )
}