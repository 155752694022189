import { btmlogo1, btmlogo2, btmlogo3, btmlogo4, btmlogo5, btmlogo6 } from "../../assets/images";
import BottomBrandLogoSlider from "../../components/BottomBrandLogoSlider";
// import './index.css'

export default function FooterBrandLogo() {
    return (
        <>
            <section className="brandlogos">
                <div className="container">
                    <BottomBrandLogoSlider />
                </div>
            </section>
        </>
    )
}