// import React from 'react';
// import { FaStar, FaStarHalfAlt } from 'react-icons/fa';
// import { WEBSITE_NAME } from '../../config';

// const TestimonialSlider = () => {
//     const reviews = [
//         {
//             name: "Julie Kirton",
//             rating: 5,
//             text: `Working with ${WEBSITE_NAME} was a game-changer for me. They turned my vision into reality, managing everything from the plot outline to the final publication. Their support and expertise made the entire process smooth and rewarding.`
//         },
//         {
//             name: "Kenny Mills",
//             rating: 4.5,
//             text: `I couldn’t be happier with my experience! The team at ${WEBSITE_NAME} took the time to understand my goals and crafted a marketing plan that truly resonated. Their dedication helped bring my book idea to life.`
//         },
//         {
//             name: "Trey Parker",
//             rating: 5,
//             text: `The professional insights I received were invaluable. The editorial critique helped refine my manuscript, and their marketing strategies significantly boosted my book’s visibility. I highly recommend their services!`
//         },
//         {
//             name: "Emma Tillmon",
//             rating: 4.5,
//             text: `${WEBSITE_NAME} exceeded my expectations. Their attention to detail and commitment to my success made all the difference. I felt supported every step of the way, and the results speak for themselves`
//         }
//     ];

//     return (
//         <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
//             <div className="row">
//                 <div className="col-lg-6">
//                     <div className="review-text">
//                         <div className="carousel-inner">
//                             {reviews.map((review, index) => (
//                                 <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index}>
//                                     <div className="review-box">
//                                         <h3>{review.name}</h3>
//                                         <div className="star">
//                                             {[...Array(Math.floor(review.rating))].map((_, i) => (
//                                                 <FaStar key={i} />
//                                             ))}
//                                             {review.rating % 1 !== 0 && <FaStarHalfAlt />}
//                                         </div>
//                                         <p>{review.text}</p>
//                                     </div>
//                                 </div>
//                             ))}
//                         </div>
//                     </div>
//                 </div>
//                 <div className="col-lg-6">
//                     <div className="review-img-wrap">
//                         <ol className="carousel-indicators">
//                             {reviews.map((_, index) => (
//                                 <li
//                                     key={index}
//                                     data-target="#carouselExampleIndicators"
//                                     data-slide-to={index}
//                                     className={index === 0 ? 'active' : ''}
//                                 ></li>
//                             ))}
//                         </ol>
//                     </div>
//                 </div>
//             </div>
//             <a className="cust-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
//                 <span className="carousel-control-prev-icon" aria-hidden="true"></span>
//                 <span className="sr-only">Previous</span>
//             </a>
//             <a className="cust-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
//                 <span className="carousel-control-next-icon" aria-hidden="true"></span>
//                 <span className="sr-only">Next</span>
//             </a>
//         </div>
//     );
// };

// export default TestimonialSlider;


import React from 'react';
import { FaStar, FaStarHalfAlt } from 'react-icons/fa';
import { WEBSITE_NAME } from '../../config';

const TestimonialSlider = () => {
    const reviews = [
        {
            name: "Julie Kirton",
            rating: 5,
            text: `Working with ${WEBSITE_NAME} was a game-changer for me. They turned my vision into reality, managing everything from the plot outline to the final publication. Their support and expertise made the entire process smooth and rewarding.`
        },
        {
            name: "Kenny Mills",
            rating: 4.5,
            text: `I couldn’t be happier with my experience! The team at ${WEBSITE_NAME} took the time to understand my goals and crafted a marketing plan that truly resonated. Their dedication helped bring my book idea to life.`
        },
        {
            name: "Trey Parker",
            rating: 5,
            text: `The professional insights I received were invaluable. The editorial critique helped refine my manuscript, and their marketing strategies significantly boosted my book’s visibility. I highly recommend their services!`
        },
        {
            name: "Emma Tillmon",
            rating: 4.5,
            text: `${WEBSITE_NAME} exceeded my expectations. Their attention to detail and commitment to my success made all the difference. I felt supported every step of the way, and the results speak for themselves`
        }
    ];

    return (
        <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
            <div className="row">
                <div className="col-lg-6">
                    <div className="review-text">
                        <div className="carousel-inner">
                            {reviews.map((review, index) => (
                                <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index}>
                                    <div className="review-box">
                                        <h3>{review.name}</h3>
                                        <div className="star">
                                            {[...Array(Math.floor(review.rating))].map((_, i) => (
                                                <FaStar key={i} />
                                            ))}
                                            {review.rating % 1 !== 0 && <FaStarHalfAlt />}
                                        </div>
                                        <p>{review.text}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="review-img-wrap">
                        <ol className="carousel-indicators">
                            {reviews.map((_, index) => (
                                <li
                                    type="button"
                                    key={index}
                                    data-bs-target="#carouselExampleIndicators"
                                    data-bs-slide-to={index}
                                    className={index === 0 ? 'active' : ''}
                                // aria-current={index === 0 ? 'true' : undefined}
                                // aria-label={`Slide ${index + 1}`}
                                ></li>
                            ))}
                        </ol>
                    </div>
                </div>
            </div>
            <div className='col-md-12 testi'>
                <button className="cust-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="cust-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </div>
    );
};

export default TestimonialSlider;
