import { clientlogo4, clientlogo6, image2021, imagespark } from "../../assets/images";
import BrandLogoSlider from "../../Components/BrandLogoSlider";
// import './index.css'

export default function BrandLogo() {
    return (
        <section className="brand-logo">
            <div className="brand-slider">
                <div className="container">
                    <BrandLogoSlider/>
                </div>
            </div>
        </section>
    )
}