import Portfoliotabs from '../../components/Portfoliotabs'
// import './index.css'

export default function Portfolio() {
    return (
        <>
            <section className="portfolio-sec">
                <div className="container">
                    <h2 className='portfolio-heading text-center'>
                    Our Portfolio: Showcasing Success Stories
                    </h2>
                    <p className='portfolio-text text-center'>Explore our diverse collection of published works, highlighting the exceptional talent and creativity of the writers we’ve empowered.</p>
                    <Portfoliotabs />
                </div>
            </section>
        </>
    )
}