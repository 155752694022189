import { svimg1, svimg2, svimg3, svimg4, svimg5, svimg6, svimg7, svimg8, svimg9 } from '../../assets/images'
import ServiceSlider from '../../components/ServiceSlider'
// import './index.css'

export default function ServiceSection() {
    return (
        <>
            <section className="service-sec">
                <div className="container">
                    <h2 className="text-center" data-aos="zoom-out" data-aos-duration="1500">Our Premium Book Marketing Services</h2>
                    <ServiceSlider/>
                </div>
            </section>
        </>
    )
}