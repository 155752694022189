import { best7 } from "../../assets/images";
import { PHONE, PHONE_HREF } from "../../config";
// import './index.css'
import { ModalSection } from "../../Components/modalSection";
import React, { useState } from "react";

export default function CtaSection() {
    const [open, setOpen] = useState(false);

    const onOpenModal = () => {
        setOpen(true);
    };

    const onCloseModal = () => {
        setOpen(false);
    };
    return (
        <>
            <section className="cta-section py-md-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4 col-md-6">
                            <div className="cta-image">
                                <img src={best7} alt="" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="cta-text">
                                <h2>
                                    Ready to Improve Your Manuscript?
                                </h2>
                                <p>
                                    Reach out now to find out how our editing services can help you refine your manuscript and prepare it for publication. Let’s achieve your publishing goals together!
                                </p>
                                <li className="cta-number">
                                    <i class="fas fa-phone-alt" aria-hidden="true"></i>
                                    Call Us
                                    <a href={PHONE_HREF} className="d-block text-white">{PHONE}</a>
                                </li>
                                <div className="mt-4">
                                    <a href="Javascript:;" className="cta-btn" onClick={onOpenModal}>
                                        GET A QUOTE
                                        <i class="fas fa-arrow-right" aria-hidden="true"></i>
                                    </a>
                                    <a href="Javascript:;" className="chat cta-btn">
                                        START LIVE CHAT
                                        <i class="fas fa-arrow-right" aria-hidden="true"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalSection open={open} onCloseModal={onCloseModal} />
            </section>
        </>
    )
}