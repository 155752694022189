import { intromainimg } from "../../assets/images";
// import './index.css'

export default function AboutUs() {
    return (
        <>
            <section className="about-sec">
                <div className="container">
                    <div className="about-heading text-center">
                        {/* <h5 data-aos="fade-down" data-aos-duration="1500">Amazon Book Writers Gives You The Clarity To Navigate The</h5> */}
                        <h1 data-aos="fade-down" data-aos-duration="1500">Master the Art of Book Marketing with Us</h1>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="about-text" data-aos="zoom-in-right" data-aos-duration="1500">
                                <p>In the fast-paced world of publishing, getting noticed is crucial. At Amazon Book Writers, we empower both self-published and traditionally published authors to shine in a competitive market. Our marketing strategies are not just plans; they are pathways to success.<br /><br />
                                When you partner with us, you’re investing in more than just marketing; you’re investing in your future as an author. We craft customized marketing campaigns tailored to your book’s unique voice and target audience. Let us help you transform your book into a best-seller. With our expert guidance, you can navigate the complexities of book promotion with confidence and clarity.</p>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="about-img" data-aos="zoom-in-left" data-aos-duration="1500">
                                <img src={intromainimg} alt="about-img" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}