import { chat } from '../../assets/images';
import { ModalSection } from '../modalSection';
// import './index.css';
import React, { useState } from 'react'

export default function MainButton({ position }) {
    const [open, setOpen] = useState(false);

    const onOpenModal = () => {
        setOpen(true);
    };

    const onCloseModal = () => {
        setOpen(false);
    };
    return (
        <>
            <div className={`block ${position}`}>
                <a href='Javascript:;' className='btn button' onClick={onOpenModal}>ACTIVATE YOUR COUPON</a>
                <a href='Javascript:;' className='chat chatbtn'><img src={chat} alt='Chat' />CHAT WITH US</a>
            </div>
            <ModalSection open={open} onCloseModal={onCloseModal} />
        </>
    );
}
